<template>
  <div>
    <v-select
      outlined
      v-model="dish"
      :items="dishes"
      item-text="name"
      item-value="name"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Plat"
    ></v-select>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";
import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

export default {
  name: "DishFilter",
  data() {
    return {
      dishes: [],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  async created() {
    const { data } = await this.axiosGet("/regie/marmiton/dish-types");
    this.dishes = data;

    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: "marmiton/updateDish",
      param: URL_PARAM_NAMES[this.$options.name],
      value: this.dish,
      // is_multiple: false,
      // is_integer: false,
      // is_boolean: false,
      // dependsOn: undefined,
    });
  },
  computed: {
    dish: {
      get() {
        return this.$store.getters["marmiton/getDish"];
      },
      set(e) {
        this.$store.dispatch("marmiton/updateDish", e);
      },
    },
  },
  watch: {
    dish(newValue) {
      addQueryStringParam({
        router: this.$router,
        route: this.$route,
        param: URL_PARAM_NAMES[this.$options.name],
        value: newValue,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
      });
    },
  },
};
</script>

<style></style>

<template>
  <div class="ma-6">
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">Public Terms of Service</p>
    </div>

    <div class="wpb_wrapper">
      <p>
        <strong style="text-decoration: underline">Website editor&nbsp;</strong>
      </p>
      <p><span style="font-weight: 400">REWORLD MEDIA</span></p>
      <p>
        <span style="font-weight: 400"
          >Société Anonyme à conseil d’administration</span
        >
      </p>
      <p><span style="font-weight: 400">RCS NANTERRE 439 546 011</span></p>
      <p>
        <span style="font-weight: 400"
          >Head office : 8, rue&nbsp;Barthélémy Danjou&nbsp;– 92100
          Boulogne-Billancourt</span
        >
      </p>
      <p>
        <span style="font-weight: 400">Share capital&nbsp;: 569.397,66 €</span>
      </p>
      <p>
        <span style="font-weight: 400">Editor in chief : Gautier Normand</span>
      </p>
      <p>
        <span style="font-weight: 400"
          >Contact : 01 45 19 58 00 –
          <a href="mailto:contact@reworldmedia.com"
            >contact@reworldmedia.com</a
          ></span
        >
      </p>
      <p>&nbsp;</p>
      <p>
        <span style="text-decoration: underline"
          ><strong>Website host</strong></span
        >
      </p>
      <p>Pilot Systems</p>
      <p>82 rue Pixérécourt 75 020 Paris</p>
      <p>+33 (0) 1 44 53 05 55</p>
      <p><a href="mailto:info@pilotsystems.net">info@pilotsystems.net</a></p>
      <p><strong>Site developed by</strong> : Reworld Media</p>
      <p><strong>Intellectual property</strong></p>
      <p>
        All the elements appearing on the site https://reworldmedia.com remain
        the exclusive property of the REWORLD MEDIA group and are protected by
        the Intellectual Property Code. You are not authorized to reproduce,
        redistribute in any form whatsoever and by whatever means or medium,
        these elements without the express consent of REWORLD MEDIA or its
        beneficiaries
      </p>
      <p>&nbsp;</p>
      <p><strong>Personal information and data</strong></p>
      <p>
        By personal information, we mean “information which allows, in any form
        whatsoever, directly or indirectly, the identification of the natural
        persons to whom it applies”.
      </p>
      <p>
        The information collected comes from the creation of your “Shareholder
        Club” account and is collected by Reworld Media. This information
        contains personal data within the meaning of the amended law n ° 78-17
        of 6 January 1978 relating to data processing, files and freedoms
        (hereinafter the “Data Protection Act”). They are intended for Reworld
        Media in order to be able to process the requests of the persons
        concerned and to interact with them.
      </p>
      <p>
        In accordance with the law “Informatique et Libertés” you have in
        particular the rights of access, rectification and deletion of data
        concerning you collected by one of the companies of the Reworld Media
        Group, as well as the right to oppose this data. subject to processing
        by contacting our data protection officer, the company Magellan
        Consulting:
      </p>
      <p>Or by post to the following address:</p>
      <p>Reworld Media Magazines – DPO c / o legal department</p>
      <p>8, rue Aristide Briand – 92220 BAGNEUX</p>
      <p>Or by email: dpd@reworldmedia.com</p>
      <p>
        Please specify your name, first name, postal address and subject of your
        request. You can lodge a complaint with the CNIL www.cnil.fr and consult
        our Privacy Policy.
      </p>
      <p>
        The information collected may be transmitted to subsidiaries of the
        Reworld Media Group or its partners for the purpose of processing your
        request.
      </p>
      <p>
        This information is confidential and kept by Reworld Media and / or its
        partners.
      </p>
      <p>&nbsp;</p>
      <p><strong>Cookies</strong></p>
      <p>
        www.reworldmedia.com informs you that a cookie is placed on your
        computer when you browse its site. a cookie does not allow us to
        identify you. The site configuration is linked to your computer and
        therefore everyone using your computer has an identically configured
        site.
      </p>
      <p>
        We use non-sensitive data relating to the navigation of your computer on
        our site, such as cookies or electronic identifiers to display
        personalized messages, measure the number of visitors or even adapt our
        content. You can change your preferences at any time by deleting the
        Reworld Media cookie and then returning to this site. This information
        is stored on your computer for a maximum of 13 months. For instructions
        on how to delete a cookie, please consult the help of your usual
        Internet browser.
      </p>
    </div>
  </div>
</template>
<script>
import { saveRmraView } from "@/utils/rmr-analytics";
export default {
  name: "PrivacyPolicy",
  created() {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {
    saveRmraView(this.$route);
  },
};
</script>

<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"locale":"fr-FR","page":_vm.options.page,"pageCount":_vm.numberOfPages,"headers":_vm.visibleHeaders,"items":_vm.items,"sort-by":'updated',"sort-desc":true,"options":_vm.options,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100],
      },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.change",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center","width":"100%"}},[_c('span',{class:_vm.getColorByRange(item.metric, item.yesterdayValue),staticStyle:{"flex":"1"}},[_vm._v(_vm._s(item.yesterdayValue))]),_c('v-icon',{staticStyle:{"flex":"1"},attrs:{"left":"","size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiArrowRight)+" ")]),_c('span',{class:_vm.getColorByRange(item.metric, item.value),staticStyle:{"flex":"1"}},[_vm._v(_vm._s(item.value))]),_c('v-icon',{class:item.evolution === 'Up' ? 'error--text' : 'success--text',staticStyle:{"flex":"1"},attrs:{"left":"","size":"22"}},[_vm._v(" "+_vm._s(item.evolution === "Up" ? _vm.icons.mdiTrendingDown : _vm.icons.mdiTrendingUp)+" ")])],1)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","target":"_blank","to":{
            name: 'cwv-daily-data',
            query: {
              dates: ((_vm.yesterdayDate(item.rawDate)) + "," + (item.rawDate)),
              sites: item.site_id,
              pageTypes: item.pageType,
              devices: _vm.deviceIds[item.device],
            },
          }}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiEarth)+" ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
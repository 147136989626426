export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    homePageURL: "/",
    userName: "",
    userEmail: "",
    snackBar: {
      show: false,
      text: "",
      color: "success",
      timeout: 2000,
    },
    dialog: {
      show: false,
      type: "simple", // or "confirm"
      title: "Title",
      text: "Content",
      code: "",
      component: null,
      componentProps: [],
      width: 1200,
      callbackFunction: () => {}, // function to be called when user click OK
    },
  },
  getters: {
    getHomePageURL: (state) => state.homePageURL,
    getUserName: (state) => state.userName,
    getUserEmail: (state) => state.userEmail,
    getSnackBar: (state) => state.snackBar,
    getDialog: (state) => state.dialog,
  },
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay =
        value !== undefined ? value : !state.shallContentShowOverlay;
    },
    setHomePageURL(state, value) {
      state.homePageURL = value;
    },
    setUserName(state, value) {
      state.userName = value;
    },
    setUserEmail(state, value) {
      state.userEmail = value;
    },
    setSnackBar(state, value) {
      state.snackBar = value;
    },
    setDialog(state, value) {
      state.dialog = value;
    },
  },
  actions: {
    setHomePageURL(state, value) {
      state.commit("setHomePageURL", value);
    },
    setUserName(state, value) {
      state.commit("setUserName", value);
    },
    setUserEmail(state, value) {
      state.commit("setUserEmail", value);
    },
    setSnackBar(state, value) {
      state.commit("setSnackBar", value);
    },
    setDialog(state, value) {
      state.commit("setDialog", value);
    },
  },
};

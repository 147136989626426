<template>
  <div ref="pdfContainer" class="pdfContainer">
    <p class="text-2xl mb-6">
      Performance par catégorie
      <dag-info
        :dags="[
          'adn_daily_posts_sites_infos_dag',
          'ga_get_and_store_site_daily_dag',
          'partner_get_and_store_daily_dag',
          'partner_get_and_store_daily_ifempty_dag',
          'adn_get_and_store_posts_ga_daily_dag',
        ]"
      />
    </p>
    <v-card class="mb-6 pa-2" outlined>
      <v-row class="ma-3">
        <v-col cols="12">
          <filters></filters>
        </v-col>
      </v-row>
    </v-card>

    <v-row>
      <v-col cols="12">
        <top-stats-card />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <category-ca-and-session-distribution-chart />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <category-rpm-chart />
      </v-col>
      <v-col cols="6">
        <category-avg-session-by-day-chart />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <most-performing-channels-chart />
      </v-col>
      <v-col cols="6">
        <channel-rpm-chart />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <perf-per-category-table />
      </v-col>
    </v-row>

    <dot-menu
      position="page-top-right"
      :isLoading="isLoading"
      :pdfButton="{
        getContainerRef: () => $refs.pdfContainer,
        filename: 'perf_par_categorie.pdf',
      }"
    />
  </div>
</template>

<script>
import DagInfo from "@/components/common/menus/DagInfo";
import DotMenu from "@/components/common/menus/DotMenu";
import Filters from "@/components/audience/perf-per-category/filters/Filters";
import CategoryAvgSessionByDayChart from "@/components/audience/perf-per-category/cards/CategoryAvgSessionByDayChart";
import CategoryCaAndSessionDistributionChart from "@/components/audience/perf-per-category/cards/CategoryCaAndSessionDistributionChart";
import CategoryRpmChart from "@/components/audience/perf-per-category/cards/CategoryRpmChart";
import ChannelRpmChart from "@/components/audience/perf-per-category/cards/ChannelRpmChart";
import MostPerformingChannelsChart from "@/components/audience/perf-per-category/cards/MostPerformingChannelsChart";
import PerfPerCategoryTable from "@/components/audience/perf-per-category/tables/PerfPerCategory";
import TopStatsCard from "@/components/audience/perf-per-article/cards/TopStatsCard";

import useAxios from "@/hooks/useAxios";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "PerfPerCategory",
  components: {
    DagInfo,
    DotMenu,
    Filters,
    CategoryCaAndSessionDistributionChart,
    CategoryAvgSessionByDayChart,
    CategoryRpmChart,
    ChannelRpmChart,
    MostPerformingChannelsChart,
    PerfPerCategoryTable,
    TopStatsCard,
  },
  data() {
    return {};
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  mounted() {
    saveRmraView(this.$route);
  },
};
</script>

<style scoped>
.pdfContainer {
  position: relative;
}
</style>

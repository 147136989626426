<template>
  <div>
    <v-menu
      v-model="menu"
      max-width="290px"
      min-width="auto"
      style="top: 228px"
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          v-model="dateText"
          v-bind="attrs"
          :prepend-inner-icon="icons.mdiCalendar"
          v-on="on"
          :disabled="disabled"
        ></v-text-field>
      </template>
      <v-row>
        <v-col>
          <v-date-picker
            v-model="dates"
            :range="isRange"
            :type="format"
            v-bind="{
              ...(dateMin ? { min: dateMin } : {}),
              ...(dateMax ? { max: dateMax } : {}),
            }"
            locale="FR-fr"
            first-day-of-week="1"
            selected-items-text="Durée selectionnée"
            :disabled="disabled"
          ></v-date-picker>
        </v-col>
      </v-row>
    </v-menu>
  </div>
</template>

<script>
import { mdiCalendar } from "@mdi/js";
import isEqual from "lodash/isEqual";

import { URL_PARAM_NAMES } from "@/utils/constants";
import { getDateStr, getMonthDateStr, ONE_DAY_MICROTIME } from "@/utils/dates";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

const TODAY = new Date();
const YESTERDAY = new Date(TODAY.getTime() - ONE_DAY_MICROTIME);
const TOMORROW = new Date(TODAY.getTime() + ONE_DAY_MICROTIME);

/**
 * Either call this component giving an `initialValue`
 *  and listening on `valueChanged` event,
 * Or call it specifying a `store`
 *  (and eventually `storeGetter`/`storeUpdater`)
 *
 * In case of `isRange` === true,
 *  `initialValue` (or storedValue) must be an array [startDate, endDate]
 * otherwise, it must be a string value.
 */
export default {
  name: "DateFilter",
  props: {
    periodType: {
      type: String,
      default: "",
      validator(value) {
        return ["past-and-today", "past-only", "future-only", ""].includes(
          value
        );
      },
    },
    initialValue: {
      type: String | Array,
      validator(value) {
        if (Array.isArray(value)) {
          return value.length === 2 && value[0] !== undefined;
        }
        return typeof value === "string";
      },
    },
    isRange: {
      type: Boolean,
      default: true,
    },
    range: {
      type: String | Number,
      default: "14",
      validator(value) {
        return parseInt(value, 10);
      },
    },
    min: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "",
    },
    format: {
      type: String,
      default: "date",
      validator(value) {
        return ["date", "month"].includes(value);
      },
    },
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return [
          "admin",
          "audience",
          "c2c",
          "marmiton",
          "premium",
          "regie",
          "gd",
          "rmra",
          "linkMessApps",
          "webPerf",
        ].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getDates",
    },
    storeUpdater: {
      type: String,
      default: "updateDates",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    initialized: false,
    menu: false,
    dates: [],
    icons: {
      mdiCalendar,
    },
  }),
  async created() {
    this.dates = await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: this.useStore ? `${this.store}/${this.storeUpdater}` : "",
      param: URL_PARAM_NAMES[this.$options.name],
      value: this.dates,
      is_multiple: this.isRange,
      // is_integer: false,
      // is_boolean: false,
      // dependsOn: undefined,
    });
    if (this.dates) {
      return;
    }

    this.initDates();
  },
  methods: {
    updateQueryString() {
      addQueryStringParam({
        router: this.$router,
        route: this.$route,
        param: URL_PARAM_NAMES[this.$options.name],
        value: this.dates,
        is_multiple: this.isRange,
        // is_integer: false,
        // is_boolean: false,
      });

      this.initialized = true;
    },
    initDates() {
      if (this.isRange) {
        this.initRangeDates();
      } else {
        this.initStartDate();
      }
    },
    initRangeDates() {
      if (
        this.storedDates?.length !== 2 ||
        Object.values(this.storedDates).some((x) => x === undefined)
      ) {
        if (this.periodType === "past-and-today") {
          if (this.dateMax !== "") {
            const endDate = new Date(this.dateMax);
            const startDate = new Date(
              endDate.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.dates = [this.getDateStr(startDate), this.getDateStr(endDate)];
          } else {
            const endDate = TODAY;
            const startDate = new Date(
              TODAY.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.dates = [this.getDateStr(startDate), this.getDateStr(endDate)];
          }
        } else if (this.periodType === "past-only") {
          if (this.dateMax !== "") {
            const endDate = new Date(this.dateMax);
            const startDate = new Date(
              endDate.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.dates = [this.getDateStr(startDate), this.getDateStr(endDate)];
          } else {
            const endDate = YESTERDAY;
            const startDate = new Date(
              YESTERDAY.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.dates = [this.getDateStr(startDate), this.getDateStr(endDate)];
          }
        } else if (this.periodType === "future-only") {
          if (this.dateMin !== "") {
            const startDate = new Date(this.dateMin);
            const endDate = new Date(
              startDate.getTime() + this.range * ONE_DAY_MICROTIME
            );
            this.dates = [this.getDateStr(startDate), this.getDateStr(endDate)];
          } else {
            const startDate = TOMORROW;
            const endDate = new Date(
              TOMORROW.getTime() + this.range * ONE_DAY_MICROTIME
            );
            this.dates = [this.getDateStr(startDate), this.getDateStr(endDate)];
          }
        } else {
          if (this.dateMin !== "" && this.dateMax !== "") {
            const startDate = new Date(this.dateMin);
            const endDate = new Date(this.dateMax);
            this.dates = [this.getDateStr(startDate), this.getDateStr(endDate)];
          } else if (this.dateMin !== "") {
            const startDate = new Date(this.dateMin);
            const endDate = new Date(
              startDate.getTime() + this.range * ONE_DAY_MICROTIME
            );
            this.dates = [this.getDateStr(startDate), this.getDateStr(endDate)];
          } else if (this.dateMax !== "") {
            const endDate = new Date(this.dateMax);
            const startDate = new Date(
              endDate.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.dates = [this.getDateStr(startDate), this.getDateStr(endDate)];
          } else {
            const endDate = TODAY;
            const startDate = new Date(
              TODAY.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.dates = [this.getDateStr(startDate), this.getDateStr(endDate)];
          }
        }
      } else if (!isEqual(this.dates, this.storedDates)) {
        this.dates = this.storedDates;
      }
    },
    initStartDate() {
      if (this.storedDates === undefined || this.storedDates.length < 7) {
        if (this.periodType === "past-only") {
          this.dates = this.dateMax;
        } else if (this.periodType === "future-only") {
          this.dates = this.dateMin;
        } else {
          this.dates = this.getDateStr(TODAY);
        }
      } else if (!isEqual(this.dates, this.storedDates)) {
        this.dates = this.storedDates;
      }
    },
    getDateStr(date) {
      if (this.format === "month") {
        return getMonthDateStr(date);
      }

      return getDateStr(date);
    },
  },
  computed: {
    useStore() {
      return this.store !== undefined;
    },
    storedDates() {
      return this.useStore
        ? this.$store.getters[`${this.store}/${this.storeGetter}`]
        : this.initialValue;
    },
    dateText() {
      return this.isRange ? this.dates.join(" => ") : this.dates;
    },
    dateMin() {
      if (this.min !== "") {
        return this.min;
      }
      if (this.periodType === "future-only") {
        return this.getDateStr(TOMORROW);
      }
      return "";
    },
    dateMax() {
      if (this.max !== "") {
        return this.max;
      }
      if (this.periodType === "past-only") {
        return this.getDateStr(YESTERDAY);
      }
      return "";
    },
  },
  watch: {
    storedDates() {
      this.initDates();
    },
    dates() {
      if (this.useStore === false) {
        if (this.dates !== this.initialValue) {
          this.updateQueryString();
          this.$emit("valueChanged", this.dates);
        }
        return;
      }

      if (this.isRange) {
        if (this.dates.length === 2 && this.dates[0] !== undefined) {
          this.$store.dispatch(
            `${this.store}/${this.storeUpdater}`,
            this.dates
          );
          this.updateQueryString();
        }
      } else if (this.dates !== undefined) {
        this.$store.dispatch(`${this.store}/${this.storeUpdater}`, this.dates);
        this.updateQueryString();
      }
    },
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 215px !important;
  right: 0px !important;
}
</style>

<template>
  <v-card outlined>
    <v-card-title class="align-start">
      <v-row>
        <v-col cols="12">
          Top articles
          <dag-info
            :dags="
              useMarmitonFilters
                ? [
                    'get_and_store_posts_marmiton_ga_daily_dag',
                    'adn_daily_posts_sites_infos_dag',
                  ]
                : [
                    'ga_get_and_store_site_daily_dag',
                    'adn_daily_posts_sites_infos_dag',
                  ]
            "
          />
        </v-col>
      </v-row>
      <dot-menu
        :isLoading="loading"
        @exportExcel="exportExcel"
        :excelButton="{
          excelData,
          filename: `top-articles-${this.startDate}_${this.endDate}.xlsx`,
          disabled: exportDisabled,
        }"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :disable-sort="true"
        :page="options.page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :server-items-length="total"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.title="{ item }">
          <div class="truncateCell" :title="item.title">
            {{ item.title }}
          </div>
        </template>
        <template v-slot:item.url="{ item }">
          <div class="truncateCell" :title="item.url_path_identifier">
            <a :href="item.url" target="_blank">{{
              item.url_path_identifier
            }}</a>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <progress-bar :download="download" />
  </v-card>
</template>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";
import { decodeHtmlEntities, formatNumber } from "@/utils/formatting";

import DagInfo from "@/components/common/menus/DagInfo";
import DotMenu from "@/components/common/menus/DotMenu";
import ProgressBar, {
  download_initial_state,
} from "@/components/common/ProgressBar";
import { ingredientListFromAutoCompleteValues } from "@/components/regie/common/utils/ingredientGroup.js";

const defaultOptions = {
  page: 1,
  itemsPerPage: 10,
};

export default {
  name: "TopArticle",
  components: {
    DagInfo,
    DotMenu,
    ProgressBar,
  },
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  data() {
    return {
      forceCall: false,
      optionsInitialized: false,
      numberOfPages: 0,
      total: 0,
      items: [],
      options: { ...defaultOptions },
      loading: false,
      excelData: new Blob([""], { type: "text/plain" }),
      download: download_initial_state(),
      headers: [
        {
          text: "Site",
          value: "site_name",
        },
        {
          text: "Titre",
          value: "title",
        },
        {
          text: "URL",
          value: "url",
        },
        {
          text: "Vues",
          value: "views",
        },
      ],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  created() {
    this.debouncedGetData();
  },
  methods: {
    resetData() {
      this.items = [];
      this.total = 0;
      this.numberOfPages = 0;
      this.options = { ...defaultOptions };
    },
    async formatResult(data) {
      const siteList = await this.siteList;

      return (data ?? []).map((item) => {
        const site = siteList.find((_site) => _site.id === item.site_id);

        return {
          ...item,
          views: formatNumber(item.views),
          site_name: site?.name ?? "",
          title: decodeHtmlEntities(item.title),
        };
      });
    },
    mostPerformingUrlParams(forExport = false) {
      return {
        start_date: this.startDate,
        end_date: this.endDate,
        sites_id: this.sites.join(","),
        search_text: this.searchText,
        column_name: "views",
        ...(this.useMarmitonFilters
          ? {
              ...(this.ingredientsToInclude
                ? { ingredients: this.ingredientsToInclude }
                : {}),
              ...(this.flavour ? { flavour: this.flavour } : {}),
              ...(this.region ? { region: this.region } : {}),
              ...(this.ageSlot ? { ageSlot: this.ageSlot } : {}),
              ...(this.dish ? { dish: this.dish } : {}),
              ...(this.cost ? { cost: this.cost } : {}),
              ...(this.duration ? { duration: this.duration } : {}),
              ...(this.rating ? { rating: this.rating } : {}),
              ...(this.rates ? { rates: this.rates } : {}),
            }
          : {}),
        ...(forExport
          ? {}
          : {
              limit: this.options.itemsPerPage,
              page: this.options.page,
            }),
      };
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    checkFilters() {
      if ((this.sites?.length ?? 0) === 0) {
        alert("Sélectionnez au moins 1 site !");

        return false;
      }

      if (
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10
      ) {
        alert("Sélectionnez une plage de date !");
        return false;
      }

      return true;
    },
    async getData() {
      if ((this.applyFilters || this.forceCall) && !this.loading) {
        if (!this.checkFilters()) {
          return;
        }
        this.loading = true;
        try {
          const {
            data: { items, total },
          } = await this.axiosGet(
            this.useMarmitonFilters
              ? "regie/marmiton/most-performing"
              : "/adn-post/most-performing",
            this.mostPerformingUrlParams()
          );

          this.items = await this.formatResult(items);
          this.total = total;
          this.numberOfPages = total / this.options.itemsPerPage;
          this.loading = false;
        } catch (err) {
          this.loading = false;
        }

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }

      this.forceCall = false;
    },
    async exportExcel() {
      if (!this.checkFilters()) {
        return;
      }

      this.loading = true;
      this.download.running = true;
      const { data } = await this.axiosGet(
        this.useMarmitonFilters
          ? "regie/marmiton/most-performing/export_xlsx"
          : "/adn-post/most-performing/export_xlsx",
        this.mostPerformingUrlParams(true),
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            this.download.percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );

      this.excelData = data;
      this.loading = false;
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters[`${this.department}/getSites`];
    },
    startDate() {
      return this.$store.getters[`${this.department}/getDates`][0];
    },
    endDate() {
      return this.$store.getters[`${this.department}/getDates`][1];
    },
    searchText() {
      return this.$store.getters[`${this.department}/getContainsText`];
    },
    flavour() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getFlavour"]
        : "";
    },
    region() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRegion"]
        : "";
    },
    ageSlot() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getAgeSlot"]
        : "";
    },
    ingredientsToInclude() {
      const ingredients = this.useMarmitonFilters
        ? this.$store.getters["marmiton/getMarmitonIngredientsToInclude"]
        : [];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
    useMarmitonFilters() {
      return ["marmiton"].includes(this.department);
    },
    async siteList() {
      return await this.$store.dispatch("common/getSiteList");
    },
    exportDisabled() {
      return this.loading || this.items.length === 0;
    },
    dish() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getDish"]
        : "";
    },
    cost() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getCost"]
        : "";
    },
    duration() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getDuration"]
        : 0;
    },
    rating() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRating"]
        : 0;
    },
    rates() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRates"]
        : 0;
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    options() {
      if (this.optionsInitialized) {
        // Not on initialization : wait for a real user change
        this.forceCall = true;
      }

      this.optionsInitialized = true;
      this.debouncedGetData();
    },
    department() {
      this.resetData();
    },
    ["download.percentCompleted"]() {
      if (this.download.percentCompleted >= 100) {
        setTimeout(() => {
          this.download = download_initial_state();
        }, 500);
      }
    },
  },
};
</script>

<style scoped>
.truncateCell {
  max-height: 80px;
  overflow: hidden;
}
</style>

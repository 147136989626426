<template>
  <v-btn
    @click="onClick"
    :class="classList"
    :style="styles"
    :outlined="outlined"
    :disabled="disabled"
    :color="color"
  >
    {{ title }}
  </v-btn>
</template>

<script>
import { saveRmraCustomEvent } from "@/utils/rmr-analytics";

export default {
  name: "ApplyFilters",
  props: {
    title: {
      type: String,
      default: "Rechercher",
    },
    classList: {
      type: String,
      default: "pt-7 pb-6 mt-0 my-1",
    },
    styles: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    executeOnLoad: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {
    if (this.executeOnLoad) {
      // Be sure filters are initialized with dataFromUrl:
      setTimeout(() => {
        this.setApplyFilters();
      }, 500);
    }
  },
  methods: {
    setApplyFilters() {
      this.$store.dispatch("common/updateApplyFilters", true);
    },
    onClick() {
      this.setApplyFilters();
      saveRmraCustomEvent(this.$route, "apply-filters");
    },
  },
};
</script>

<style></style>

<template>
  <v-card>
    <v-card-text>
      <v-data-table
        locale="fr-FR"
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="visibleHeaders"
        :items="items"
        :sort-by="'updated'"
        :sort-desc="true"
        :options.sync="options"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.lcp="{ item }">
          <span :class="getColorByRange('LCP', item.lcp)">{{ item.lcp }}</span>
        </template>
        <template v-slot:item.fcp="{ item }">
          <span :class="getColorByRange('FCP', item.fcp)">{{ item.fcp }}</span>
        </template>
        <template v-slot:item.cls="{ item }">
          <span :class="getColorByRange('CLS', item.cls)">{{
            formatNumber(item.cls)
          }}</span>
        </template>
        <template v-slot:item.ttfb="{ item }">
          <span :class="getColorByRange('TTFB', item.ttfb)">{{
            item.ttfb
          }}</span>
        </template>
        <template v-slot:item.inp="{ item }">
          <span :class="getColorByRange('INP', item.inp)">{{ item.inp }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            color="primary"
            :target="`_blank`"
            :to="{
              name: 'cwv-raw-data',
              query: {
                date: item.date,
                site: item.site_id,
                'page-type': item.pageType,
                device: item.device,
              },
            }"
          >
            <v-icon>
              {{ icons.mdiCubeScan }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<style scoped></style>
<script>
import useAxios from "@/hooks/useAxios";
import { mdiCubeScan } from "@mdi/js";
import debounce from "lodash/debounce";
import { formatNumber } from "@/utils/formatting";
export default {
  name: "CwvDailyDebugTable",
  props: {
    devFilterVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  created() {
    this.debouncedGetData();
  },
  data() {
    return {
      search: "",
      numberOfPages: 0,
      items: [],
      deviceMapping: {
        15: "Mobile",
        16: "Desktop",
        17: "Tablette",
      },
      loading: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      icons: {
        mdiCubeScan,
      },
      ranges: {
        LCP: [2500, 4000],
        INP: [200, 500],
        CLS: [0.1, 0.25],
        FCP: [1800, 3000],
        TTFB: [800, 1800],
      },
      headers: [
        {
          text: "Date",
          value: "date",
          align: "start",
          visible: true,
        },
        {
          text: "Site",
          value: "site_name",
          visible: true,
        },
        {
          text: "Page type",
          value: "pageType",
          visible: true,
        },
        {
          text: "Device",
          value: "device",
          visible: true,
        },
        {
          text: "LCP",
          value: "lcp",
          visible: true,
        },
        {
          text: "FCP",
          value: "fcp",
          visible: true,
        },
        {
          text: "CLS",
          value: "cls",
          visible: true,
        },
        {
          text: "TTFB",
          value: "ttfb",
          visible: true,
        },
        {
          text: "INP",
          value: "inp",
          visible: true,
        },
        {
          text: "Total des Events",
          value: "total_count",
          visible: false,
        },
        {
          text: "Total des Posts",
          value: "posts_count",
          visible: false,
        },
        {
          text: "",
          value: "actions",
          visible: true,
        },
      ],
    };
  },
  methods: {
    formatNumber,
    getColorByRange(metric, value) {
      const range = this.ranges[metric];
      if (value <= range[0]) {
        return "success--text";
      } else if (value <= range[1]) {
        return "warning--text";
      } else {
        return "error--text";
      }
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const params = {};
      if (this.dates && this.dates.length > 0) {
        params.dates = this.dates.join(",");
      }
      if (this.sites && this.sites.length > 0) {
        params.sites = this.sites.join(",");
      }
      if (this.pageTypes && this.pageTypes.length > 0) {
        params["page_types"] = this.pageTypes.join(",");
      }
      if (this.devices && this.devices.length > 0) {
        params.devices = this.devices.join(",");
      }
      if (this.totalEventsJ1) {
        params["total_events"] = this.totalEventsJ1;
      }
      if (this.totalPostsJ1) {
        params["total_posts"] = this.totalPostsJ1;
      }
      this.loading = true;

      const { data } = await this.axiosGet("/web-perf/cwv-daily-data", params);
      this.items = data.items;

      for (const item of this.items) {
        item.rawDate = item.date;
        item.date = new Date(item.date).toLocaleDateString("fr-FR");
        item.device = this.deviceMapping[item.device];
      }

      this.loading = false;
      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["webPerf/getSites"];
    },
    pageTypes() {
      return this.$store.getters["webPerf/getPageTypes"];
    },
    devices() {
      return this.$store.getters["webPerf/getDevices"];
    },
    metrics() {
      return this.$store.getters["webPerf/getMetrics"];
    },
    totalEventsJ1() {
      return this.$store.getters["webPerf/getTotalEvents"];
    },
    totalPostsJ1() {
      return this.$store.getters["webPerf/getTotalPosts"];
    },
    dates() {
      return this.$store.getters["webPerf/getDates"];
    },
    visibleHeaders() {
      return this.headers.filter(
        (header) => this.devFilterVisible || header.visible
      );
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>
<style lang="scss" scoped></style>

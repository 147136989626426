const LinkMessagingAppsRoute = [
  {
    path: "/link-messaging-app/tracking",
    name: "link-messaging-apps",
    component: () => import("@/views/link-messaging-app/Index"),
    meta: {
      layout: "content",
      resource: "link_messapps",
      action: "manage",
      //   rmrAnalytics: {
      //     track: true,
      //     eventType: "programmatique-dashboard",
      //     eventCategory: "page-view",
      //   },
    },
  },
  {
    path: "/link-messaging-app/oneshot",
    name: "one-shot-link",
    component: () => import("@/views/link-messaging-app/OneShotLink"),
    meta: {
      layout: "content",
      resource: "link_messapps",
      action: "manage",
      //   rmrAnalytics: {
      //     track: true,
      //     eventType: "programmatique-dashboard",
      //     eventCategory: "page-view",
      //   },
    },
  },
];

export default LinkMessagingAppsRoute;

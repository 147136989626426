<template>
  <v-card style="position: relative !important">
    <v-card-title>
      Top Sites par CA
      <dag-info
        :dags="[
          'partner_get_and_store_daily_dag',
          'partner_get_and_store_daily_ifempty_dag',
        ]"
      />
    </v-card-title>
    <v-card-subtitle> et leurs meilleurs partenaires </v-card-subtitle>
    <loader v-if="loading" />
    <v-card-text v-else>
      <div id="container">
        <vue-apex-charts :options="options" :series="series" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { cacheTime } from "@axios";

import DagInfo from "@/components/common/menus/DagInfo";
import Loader from "@/components/common/Loader";
import useAxios from "@/hooks/useAxios";
import { formatCurrencyCompact } from "@/utils/formatting";

export default {
  name: "TopSitesTopPartnersChart",
  components: {
    DagInfo,
    Loader,
    VueApexCharts,
  },
  data() {
    return {
      loading: false,
      series: [],
      options: {
        chart: {
          animation: {
            // enabled: false,
          },
          height: 350,
          id: "apexchart-treemap",
          type: "treemap",
          stacked: true,
          zoom: {
            enabled: false,
          },
          events: {
            click: function (chart, w, e) {
              // change value of the displayed text
              // console.log(chart, w, e);
            },
            mouseMove: function (chart, w, e) {
              // console.log(chart, w, e);
            },
          },
        },
        legend: {
          show: true,
        },
        tooltip: {
          enabled: true,
          custom: undefined,
          fillSeriesColor: false,
          theme: false,
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
          onDatasetHover: {
            highlightDataSeries: true,
          },
          x: {
            show: true,
            formatter: undefined,
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName,
            },
          },

          marker: {
            show: true,
          },
          fixed: {
            enabled: true,
            position: "topRight",
            offsetX: 0,
            offsetY: 0,
          },
        },
        // tooltip: {
        //   custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //     return (
        //       '<div class="arrow_box">' +
        //       "<span>" +
        //       w.globals.labels[dataPointIndex] +
        //       ": " +
        //       series[seriesIndex][dataPointIndex] +
        //       "</span>" +
        //       "</div>"
        //     );
        //   },
        // },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.month &&
        this.month.length > 5
      ) {
        const queryParams = {
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnerViews && this.partnerViews.length > 0
            ? { partner_views: this.partnerViews.join(",") }
            : {}),
        };

        this.loading = true;
        const { data } = await this.axiosGet(
          "/programmatic/top-sites-ca-partners",
          queryParams,
          {
            cache: {
              maxAge: cacheTime,
            },
          }
        );
        this.series = [];

        for (const key of data) {
          const partners = [];
          for (const datum of key.data) {
            // format number
            partners.push({
              x: datum.partner + " (" + formatCurrencyCompact(datum.ca) + ")",
              y: datum.ca,
            });
          }
          this.series.push({
            name: key.name,
            data: partners,
          });
        }
        this.chartOptions = {
          ...this.chartOptions,
          series: this.series,
        };

        this.loading = false;
      }

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    month() {
      return this.$store.getters["premium/getMonthDate"];
    },
    partnerViews() {
      return this.$store.getters["premium/getPartnerViews"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
  },
};
</script>

<style>
/*.apexcharts-canvas {*/
/*  position: relative;*/
/*}*/

/*.apexchartsqh2itgok {*/
/*  position: relative;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  user-select: none;*/
/*  -webkit-tap-highlight-color: transparent;*/
/*}*/

/*.apexcharts-tooltip,*/
/*.apexcharts-xaxistooltip {*/
/*  background-color: #fff !important;*/
/*  position: fixed;*/
/*  top: 0;*/
/*  left: 0;*/
/*  color: #ec0c32;*/
/*  z-index: 2222 !important;*/
/*}*/
/*.apexcharts-tooltip.apexcharts-active,*/
/*.apexcharts-xaxistooltip.apexcharts-active {*/
/*  display: block;*/
/*}*/

#container {
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
#apexchartsapexchartxtreemap {
  height: 50%;
  width: 50%;
  display: block;
  position: relative;
}
/*.apexcharts-tooltip {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  color: #ec0c32;*/
/*  z-index: 2222 !important;*/
/*  background: rebeccapurple;*/
/*}*/
</style>

import axios from "@axios";
import { update } from "lodash";
export default {
  namespaced: true,
  state: {
    dates: [],
    sites: [],
    devices: [],
    metrics: [],
    pageTypes: [],
    pageTypeList: [],
    evolutions: [],
    siteGroups: [],
    totalEvents: 0,
    totalPosts: 0,
    totalEventsJ2: 0,
    totalPostsJ2: 0,
    data: [],
  },
  getters: {
    getDates: (state) => {
      // always return the dates ordered
      return state.dates[0] < state.dates[1]
        ? [state.dates[0], state.dates[1]]
        : [state.dates[1], state.dates[0]];
    },
    getSites: (state) => state.sites,
    getMetrics: (state) => state.metrics,
    getPageTypes: (state) => state.pageTypes,
    getPageTypeList: (state) => state.pageTypeList,
    getEvolutions: (state) => state.evolutions,
    getSiteGroups: (state) => state.siteGroups,
    getDevices: (state) => state.devices,
    getTotalEvents: (state) => state.totalEvents,
    getTotalPosts: (state) => state.totalPosts,
    getTotalEventsJ2: (state) => state.totalEventsJ2,
    getTotalPostsJ2: (state) => state.totalPostsJ2,
    getData: (state) => state.data,
  },
  mutations: {
    updateDates(state, payload) {
      state.dates = payload;
    },
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateMetrics(state, payload) {
      state.metrics = payload;
    },
    updatePageTypes(state, payload) {
      state.pageTypes = payload;
    },
    updatePageTypeList(state, payload) {
      state.pageTypeList = payload;
    },
    updateEvolutions(state, payload) {
      state.evolutions = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },
    updateTotalEvents(state, payload) {
      state.totalEvents = payload;
    },
    updateTotalPosts(state, payload) {
      state.totalPosts = payload;
    },
    updateTotalEventsJ2(state, payload) {
      state.totalEventsJ2 = payload;
    },
    updateTotalPostsJ2(state, payload) {
      state.totalPostsJ2 = payload;
    },
    updateData(state, payload) {
      state.data = payload;
    },
    updateDevices(state, payload) {
      state.devices = payload;
    },
  },
  actions: {
    updateDates({ commit }, payload) {
      if (payload.length >= 2) {
        commit("updateDates", payload);
      }
    },
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    updateMetrics({ commit }, payload) {
      commit("updateMetrics", payload);
    },
    updatePageTypes({ commit }, payload) {
      commit("updatePageTypes", payload);
    },
    updateEvolutions({ commit }, payload) {
      commit("updateEvolutions", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);
      const { data } = await axios.get("/site-groups/sites", {
        params: { group_ids: payload.join(",") },
      });
      // get site_ids only and remove duplicates
      const site_ids = data
        .map((x) => x["site_id"])
        .sort()
        .filter((item, pos, ary) => {
          return !pos || item !== ary[pos - 1];
        });

      commit("updateSites", site_ids);

      return site_ids;
    },
    updateTotalEvents({ commit }, payload) {
      commit("updateTotalEvents", payload);
    },
    updateTotalPosts({ commit }, payload) {
      commit("updateTotalPosts", payload);
    },
    updateTotalEventsJ2({ commit }, payload) {
      commit("updateTotalEventsJ2", payload);
    },
    updateTotalPostsJ2({ commit }, payload) {
      commit("updateTotalPostsJ2", payload);
    },
    updateData({ commit }, payload) {
      commit("updateData", payload);
    },
    updateDevices({ commit }, payload) {
      commit("updateDevices", payload);
    },
    async getPageTypeList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/web-perf/content-types", {});
      // console.log(data);
      commit("updatePageTypeList", items);
      return items;
    },
  },
};

import { mdiSpeedometer } from "@mdi/js";

const Nav = [
  {
    title: "Performances Web",
    icon: mdiSpeedometer,
    children: [
      {
        title: "CWV",
        resource: "web_perf",
        action: "manage",
        children: [
          {
            title: "Changements de Pallier",
            to: "cwv-threshold-changes",
            resource: "admin",
            action: "manage",
          },
          {
            title: "Données consolidées par jour",
            to: "cwv-daily-data",
            resource: "admin",
            action: "manage",
          },
        ],
      },
    ],
  },
];

export default Nav;

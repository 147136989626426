<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <v-snackbar
      v-model="snackBar['show']"
      :timeout="snackBar['timeout']"
      :color="snackBar['color']"
      :multi-line="true"
      top
    >
      <div v-html="snackBar.text"></div>
    </v-snackbar>
    <v-snackbar
      v-model="isMepAlertVisible"
      :timeout="120000"
      color="warning"
      outlined
      center
      top
    >
      <b>
        Une
        <a target="_blank" href="/changelog">mise en prod</a>
        a été lancée. Des ralentissements sont possibles au chargement des pages
        pour les minutes à venir.
      </b>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="warning"
          text
          v-bind="attrs"
          @click="closeMepAlert"
          clickold="$store.dispatch('common/updateLastMepTime', 'closed')"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog['show']" :width="dialog['width']">
      <template v-slot:activator="{ on, attrs }"></template>
      <v-card>
        <v-card-title
          class="text-h5 grey lighten-2"
          v-html="dialog['title']"
        ></v-card-title>
        <div v-if="dialog['component']">
          <component
            :is="dialog['component']"
            v-bind="dialog['componentProps']"
            :key="dialogKey"
          />
        </div>
        <div v-else>
          <v-card-text>
            <div v-html="dialog['text']" class="mb-4"></div>
            <pre
              v-show="dialog['code'] != ''"
              style="width: 100%; overflow-x: auto"
              v-html="dialog['code']"
            ></pre>
          </v-card-text>
        </div>
        <v-divider></v-divider>
        <v-card-actions v-if="dialog['type'] == 'confirm'">
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            @click="
              dialog['callbackFunction']();
              dialog['show'] = false;
            "
          >
            Confirmer
          </v-btn>
          <v-btn color="green darken-1" @click="dialog['show'] = false">
            Abandonner
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog['show'] = false">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <slot></slot>

    <!-- Slot: Navbar -->
    <template
      #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }"
    >
      <div
        class="navbar-content-container"
        :class="{ 'expanded-search': shallShowFullSearch }"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="
              handleShallShowFullSearchUpdate(
                isVerticalNavMenuActive,
                toggleVerticalNavMenuActive
              )
            "
          ></app-bar-search>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <v-btn
            @click="openDialogBugReporting"
            class="mr-5"
            color="primary"
            text
            outlined
            rounded
            >Un problème sur la page ?</v-btn
          >
          <app-bar-user-notifications></app-bar-user-notifications>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="footer-links">
        <span
          >COPYRIGHT &copy; {{ new Date().getFullYear() }}
          <a
            href="https://www.reworldmedia.com/"
            target="_blank"
            class="text-decoration-none"
            >Reworld Media</a
          ><span class="d-none d-md-inline">, All rights Reserved</span></span
        >
        |
        <a class="text-decoration-none" href="/changelog">Changelog</a>
        |
        <a class="text-decoration-none" href="/privacy-policy"
          >Privacy Policy</a
        >
        |
        <a class="text-decoration-none" href="/public-terms-of-service"
          >Public Terms of Service</a
        >
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from "@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue";
import navMenuItems from "@/navigation/vertical";

// App Bar Components
import AppBarSearch from "@core/layouts/components/app-bar/AppBarSearch.vue";
import AppBarUserMenu from "@/components/AppBarUserMenu.vue";
import AppBarUserNotifications from "@/components/AppBarUserNotifications.vue";

import { mdiMenu, mdiHeartOutline } from "@mdi/js";

import { getVuetify } from "@core/utils";

// Search Data
import appBarSearchData from "@/assets/app-bar-search-data";

import { computed, ref, watch, onMounted } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils";

// custom dev
import useAxios from "@/hooks/useAxios";
import CreateBugReporting from "@/components/common/modals/CreateBugReporting";
import { log } from "@/utils/formatting";

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarSearch,
    AppBarUserMenu,
    AppBarUserNotifications,

    // custom dev
    CreateBugReporting,
  },
  setup() {
    const $vuetify = getVuetify();

    const snackBar = computed(() => store.getters["app/getSnackBar"]);
    const dialog = computed(() => store.getters["app/getDialog"]);
    const dialogKey = ref(0);

    const isMepAlertVisible = ref(false);
    watch(
      () => store.getters["common/getLastMepTime"],
      (newValue, oldValue) => {
        isMepAlertVisible.value = newValue && newValue !== oldValue;
      }
    );

    const closeMepAlert = () => {
      localStorage.setItem(
        "lastMepClosedTime",
        store.getters["common/getLastMepTime"]
      );
      store.dispatch("common/updateLastMepTime", null);
    };

    const { axiosGet } = useAxios();
    onMounted(async () => {
      try {
        const response = await axiosGet("/meps/last-mep-time", { limit: 1 });
        log("LayoutContentVerticalNav.vue /meps/last-mep-time", response);

        const lastMepTime = new Date(response.data.time); // Convert response time to a Date object
        log("Converted response time to Date:", lastMepTime);

        const now = new Date(); // Current date and time
        log("Current time:", now);

        const mepDiffInSeconds = (now.getTime() - lastMepTime.getTime()) / 1000;
        log("Difference in seconds:", mepDiffInSeconds);

        const lastMepClosedTime = localStorage.getItem("lastMepClosedTime");
        log(
          "lastMepClosedTime",
          lastMepClosedTime,
          new Date(lastMepClosedTime)
        );
        const mepClosedDiffInSeconds = lastMepClosedTime
          ? (now.getTime() - new Date(lastMepClosedTime).getTime()) / 1000
          : 999999;
        log("mepClosedDiffInSeconds", mepClosedDiffInSeconds);
        const delay = 120;
        if (mepDiffInSeconds < delay) {
          log(
            `Une MEP a été faite il y a moins de ${delay}s`,
            mepDiffInSeconds
          );
          if (mepClosedDiffInSeconds < delay) {
            log(`On a fermé l'alerte de MEP il y a moins de ${delay}s`);
            return;
          }
          log("la snackbar d'alerte de MEP");
          store.dispatch("common/updateLastMepTime", lastMepTime);
        } else {
          log(`Une MEP a été faite il y a plus de ${delay}s`, mepDiffInSeconds);
        }
      } catch (error) {
        console.error("Error fetching last mep time:", error);
      }
    });

    const openDialogBugReporting = async () => {
      dialogKey.value++;
      await store.dispatch("app/setDialog", {
        show: true,
        title: "Signaler un problème sur la page",
        //text: document.location.href,
        component: CreateBugReporting,
        componentProps: {
          // ... any props for CreateBugReporting component
        },
        code: null,
        width: 800,
      });
    };

    // Search
    const appBarSearchQuery = ref("");
    const shallShowFullSearch = ref(false);
    const maxItemsInGroup = 5;
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    });
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      };
    });

    // NOTE: Update search function according to your usage
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true;

      const itemGroup = (() => {
        if (item.to !== undefined) return "pages";
        if (item.size !== undefined) return "files";
        if (item.email !== undefined) return "contacts";

        return null;
      })();

      const isMatched =
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1;

      if (isMatched) {
        if (itemGroup === "pages") totalItemsInGroup.value.pages += 1;
        else if (itemGroup === "files") totalItemsInGroup.value.files += 1;
        else if (itemGroup === "contacts")
          totalItemsInGroup.value.contacts += 1;
      }

      return (
        appBarSearchQuery.value &&
        isMatched &&
        totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
      );
    };

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (
      isVerticalNavMenuActive,
      toggleVerticalNavMenuActive
    ) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive();
      }
    };

    return {
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      // custom dev
      snackBar,
      dialog,
      dialogKey,
      openDialogBugReporting,
      isMepAlertVisible,
      closeMepAlert,

      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role="combobox"] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
.footer-links > :first-child {
  margin-right: 60px;
}
.footer-links > *:not(:first-child) {
  margin: 60px;
}
</style>

<template>
  <bar-chart
    title="Répartition par région"
    :series="series"
    :labels="labels"
    :loading="loading"
    :datalabels="false"
    :yAxis="yAxis"
    :formatter="formatter"
    :outlined="true"
  />
</template>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";
import { formatPercent, round2Dec } from "@/utils/formatting";

import BarChart from "@/components/common/charts/BarChart";
import { ingredientListFromAutoCompleteValues } from "@/components/regie/common/utils/ingredientGroup.js";

const emptySeries = [
  {
    name: "Vues",
    data: [],
  },
];

export default {
  name: "RepartitionByRegionsChart",
  components: {
    BarChart,
  },
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  data() {
    return {
      loading: false,
      series: [...emptySeries],
      labels: [],
      formatter: (val) => formatPercent(val),
      yAxis: [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatPercent(val),
          },
        },
      ],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    resetData() {
      this.series = [...emptySeries];
      this.labels = [];
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.sites &&
        this.sites.length > 0 &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        const params = {
          start_date: this.startDate,
          end_date: this.endDate,
          sites_id: this.sites.join(","),
          keyword: this.searchText,
          ...(this.useMarmitonFilters
            ? {
                ...(this.ingredientsToInclude
                  ? { ingredients: this.ingredientsToInclude }
                  : {}),
                ...(this.flavour ? { flavour: this.flavour } : {}),
                ...(this.region ? { region: this.region } : {}),
                ...(this.ageSlot ? { ageSlot: this.ageSlot } : {}),
                ...(this.dish ? { dish: this.dish } : {}),
                ...(this.cost ? { cost: this.cost } : {}),
                ...(this.duration ? { duration: this.duration } : {}),
                ...(this.rating ? { rating: this.rating } : {}),
                ...(this.rates ? { rates: this.rates } : {}),
              }
            : {}),
        };

        try {
          this.loading = true;
          const { data } = await this.axiosGet(
            "/regie/visitor-info/by-countries",
            params
          );

          const labels = Object.keys(data);
          const series = Object.values(data).map(round2Dec);

          //labels.push("Autres");
          //series.push(round2Dec(1 - series.reduce((acc, val) => acc + val, 0)));

          this.labels = labels;
          this.series = [
            {
              name: "Vues",
              data: series,
            },
          ];

          this.loading = false;
        } catch (err) {
          this.loading = false;
        }

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters[`${this.department}/getSites`];
    },
    startDate() {
      return this.$store.getters[`${this.department}/getDates`][0];
    },
    endDate() {
      return this.$store.getters[`${this.department}/getDates`][1];
    },
    searchText() {
      return this.$store.getters[`${this.department}/getContainsText`];
    },
    flavour() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getFlavour"]
        : "";
    },
    region() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRegion"]
        : "";
    },
    ageSlot() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getAgeSlot"]
        : "";
    },
    ingredientsToInclude() {
      const ingredients = this.useMarmitonFilters
        ? this.$store.getters["marmiton/getMarmitonIngredientsToInclude"]
        : [];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
    useMarmitonFilters() {
      return ["marmiton"].includes(this.department);
    },
    dish() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getDish"]
        : "";
    },
    cost() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getCost"]
        : "";
    },
    duration() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getDuration"]
        : 0;
    },
    rating() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRating"]
        : 0;
    },
    rates() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRates"]
        : 0;
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    department() {
      this.resetData();
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"locale":"fr-FR","page":_vm.options.page,"pageCount":_vm.numberOfPages,"headers":_vm.headers,"items":_vm.items,"sort-by":'updated',"sort-desc":true,"options":_vm.options,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100],
      },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.lcp",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:_vm.getColorByRange('LCP', item.lcp)},[_vm._v(_vm._s(item.lcp))])]}},{key:"item.fcp",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:_vm.getColorByRange('FCP', item.fcp)},[_vm._v(_vm._s(item.fcp))])]}},{key:"item.cls",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:_vm.getColorByRange('CLS', item.cls)},[_vm._v(_vm._s(_vm.formatNumber(item.cls)))])]}},{key:"item.ttfb",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:_vm.getColorByRange('TTFB', item.ttfb)},[_vm._v(_vm._s(item.ttfb))])]}},{key:"item.inp",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:_vm.getColorByRange('INP', item.inp)},[_vm._v(_vm._s(item.inp))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.rawDate)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <h1 class="text-2xl mb-6">Publication ponctuelle</h1>
    <OneshotForm
      @update:url="handleUrlUpdate"
      @update:utm="handleUtmUpdate"
    ></OneshotForm>
    <v-card class="mb-6 pa-2">
      <v-card-title>Aperçu</v-card-title>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-text-field
            v-model="fullUrl"
            label="URL complète"
            outlined
            readonly
          ></v-text-field>
          <!-- <v-textarea v-model="preview" label="preview" outlined></v-textarea> -->
        </v-col>
      </v-row>
      <v-row class="ma-3">
        <!-- <v-col cols="6">
          <v-btn color="primary" @click="generatePreview"
            >Générer l'aperçu</v-btn
          >
        </v-col> -->
        <v-col cols="6">
          <v-btn color="primary" @click="sendLink" :disabled="isLoading"
            >Envoyer le lien</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import axios from "@axios";
import useAxios from "@/hooks/useAxios";
import OneshotForm from "@/components/link-mess-apps/forms/oneshotForm.vue";
export default {
  name: "OneShotLink",
  components: {
    OneshotForm,
  },
  data() {
    return {
      url: "",
      utm: "utm_campaign=DarkSocial&utm_medium=Social&utm_source=Whatsapp",
      fullUrl: "",
      preview: "",
    };
  },
  setup() {
    const { axiosGet, axiosPost, isLoading } = useAxios();

    return {
      axiosGet,
      isLoading,
      axiosPost,
    };
  },
  methods: {
    handleUrlUpdate(newUrl) {
      console.log("Received full URL:", newUrl); // Debugging log
      this.url = newUrl;
      this.fullUrl = `${this.url}?${this.utm}`;
    },
    handleUtmUpdate(newUtm) {
      console.log("Received UTM:", newUtm); // Debugging log
      this.utm = newUtm;
      this.fullUrl = `${this.url}?${this.utm}`;
    },
    // async generatePreview() {
    //   try {
    //     const response = await axios.post("/api/generate-preview", {
    //       url: this.fullUrl,
    //     });
    //     this.preview = response.data.preview;
    //   } catch (error) {
    //     console.error("Error generating preview:", error);
    //   }
    // },
    async sendLink() {
      console.log("Sending link"); // Debugging log
      console.log(this.isLoading);
      const response = await this.axiosPost("/link_messapps/one_shot", {
        url: this.url,
      });
      console.log(response);
      console.log(this.isLoading);
      // disabled send link while is_loading is true
    },
  },
};
</script>
<style></style>

<template>
  <dashboard-chart
    v-if="series[0].data.length"
    title="Top audience par article"
    type="bar"
    :series="series"
    :categories="[]"
    :chartOptions="dashboardMainChartOptions"
    outlined
    :loading="loading"
  ></dashboard-chart>
</template>

<script>
import debounce from "lodash/debounce";

import DashboardChart from "@/components/common/charts/DashboardChart";
import useAxios from "@/hooks/useAxios";
import { chartColors } from "@/utils/constants";
import { formatCurrency, truncate } from "@/utils/formatting";

export default {
  name: "TopArticlesChart",
  components: {
    DashboardChart,
  },
  data() {
    return {
      loading: false,
      series: [
        {
          name: "Revenus",
          data: [],
        },
      ],
      dashboardMainChartOptions: {
        chart: {
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        colors: chartColors,
        legend: {
          show: false,
        },
        dataLabels: {
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            return w.config.series[seriesIndex].data[dataPointIndex].label;
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        yaxis: [
          {
            title: {
              text: "Revenus",
            },
            seriesName: "Revenus",
            min: 0,
            forceNiceScale: true,
            labels: {
              formatter: (value) => {
                return formatCurrency(value, 0);
              },
              style: {
                fontSize: "12px",
                fontWeight: 400,
              },
            },
          },
        ],
        xaxis: {
          show: false,
          labels: {
            formatter: (value) => {
              return truncate(value, 15);
            },
          },
          categories: [],
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        tooltip: {
          x: {
            formatter: (value) => value,
          },
        },
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        this.loading = true;
        const {
          data: { items },
        } = await this.axiosGet("/adn-post/most-performing", {
          start_date: this.startDate,
          end_date: this.endDate,
          period_type: this.periodType,
          categories: this.categories.join(","),
          sites_id: this.sites.join(","),
          with_count: false,
        });

        this.series = [
          {
            name: "Revenus",
            data: items.map((source) => {
              const site = this.getAllSites.find(
                (_site) => _site.id === source.site_id
              );

              return {
                x: source.title,
                y: source.ca,
                label: site?.name ?? "",
              };
            }),
          },
        ];

        this.defineSiteColors(items);

        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
    defineSiteColors(items) {
      const colors = [];
      const siteColors = {};
      items.forEach((source) => {
        if (siteColors[source.site_id] === undefined) {
          const nextColorIndex = Object.keys(siteColors).length;
          siteColors[source.site_id] = chartColors[nextColorIndex];
        }
        colors.push(siteColors[source.site_id]);
      });

      this.dashboardMainChartOptions = {
        ...this.dashboardMainChartOptions,
        colors,
      };
    },
  },
  computed: {
    getAllSites() {
      return this.$store.getters["common/getSiteList"];
    },
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    categories() {
      return this.$store.getters["audience/getCategories"];
    },
    sites() {
      return this.$store.getters["audience/getSites"];
    },
    startDate() {
      return this.$store.getters["audience/getDates"][0];
    },
    endDate() {
      return this.$store.getters["audience/getDates"][1];
    },
    periodType() {
      return this.$store.getters["audience/getPeriodType"];
    },
  },
  watch: {
    startDate() {
      this.debouncedGetData();
    },
    endDate() {
      this.debouncedGetData();
    },
    periodType() {
      this.debouncedGetData();
    },
    categories() {
      this.debouncedGetData();
    },
    sites() {
      this.debouncedGetData();
    },
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>

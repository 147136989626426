const programmatiqueRoutes = [
  {
    path: "/premium/general",
    name: "premium-general",
    component: () => import("@/views/pages/premium/GeneralDecoupled"),
    meta: {
      layout: "content",
      resource: "programmatique",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "programmatique-dashboard",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/premium/ca-by-partner-view",
    name: "premium-ca-by-partner-view",
    component: () => import("@/views/pages/premium/CaByPartnerView"),
    meta: {
      layout: "content",
      resource: "general",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "ca-by-partner-view",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/premium/ca-and-audience-by-site",
    name: "premium-ca-and-audience-by-site",
    component: () => import("@/views/pages/premium/CaAndAudienceBySite"),
    meta: {
      layout: "content",
      resource: "general",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "ca-and-audience-by-site",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/premium/objectives/list",
    name: "premium-objectives-list",
    component: () => import("@/views/pages/premium/ObjectivesList"),
    meta: {
      layout: "content",
      resource: "programmatique",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "programmatique-objectives-list",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/premium/objectives/edit",
    name: "premium-objectives-edit",
    component: () => import("@/views/pages/premium/ObjectivesEdit"),
    meta: {
      layout: "content",
      resource: "programmatique",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "programmatique-objectives-edit",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/premium/objectives/bulk-edit",
    name: "premium-objectives-bulk-edit",
    component: () => import("@/views/pages/premium/ObjectivesBulkEdit"),
    meta: {
      layout: "content",
      resource: "programmatique",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "programmatique-objectives-bulk-edit",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/premium/gss",
    name: "premium-gss",
    component: () => import("@/views/pages/premium/Gss"),
    meta: {
      layout: "content",
      resource: "programmatique",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "programmatique-daily-ca",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/premium/partner-ca-daily/partner-views-selection",
    name: "premium-partner-ca-daily/partner-views-selection",
    component: () =>
      import("@/views/premium/partner-ca-daily/PartnerViewsSelection"),
    meta: {
      layout: "content",
      resource: "programmatique",
      action: "manage",
    },
  },
  {
    path: "/premium/inventaire",
    name: "premium-inventaire",
    component: () => import("@/views/premium/Inventory"),
    meta: {
      layout: "content",
      resource: "programmatique",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "programmatique-inventory",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/premium/gam/prebid-by-pubstack",
    name: "premium-gam-prebid-pubstack",
    component: () => import("@/views/premium/gam/GamPrebidPubstack"),
    meta: {
      layout: "content",
      resource: "programmatique",
      action: "manage",
    },
  },
  {
    path: "/premium/sites-matching",
    name: "premium-sites-matching",
    component: () => import("@/views/pages/premium/SitesMatching"),
    meta: {
      layout: "content",
      resource: "programmatique",
      action: "manage",
    },
  },
  {
    path: "/premium/sites-matched-list",
    name: "premium-sites-matched-list",
    component: () => import("@/views/pages/premium/SitesMatchedList.vue"),
    meta: {
      layout: "content",
      resource: "programmatique",
      action: "manage",
    },
  },
  {
    path: "/premium/pepsia-mg",
    name: "premium-pepsia-mg-manage",
    component: () => import("@/views/pages/premium/PepsiaMGEdit.vue"),
    meta: {
      layout: "content",
      resource: "programmatique",
      action: "manage",
    },
  },
  {
    path: "/premium/achievement-report",
    name: "premium-achievement-report",
    component: () => import("@/views/premium/MonthlyAchievements.vue"),
    meta: {
      layout: "content",
      resource: "programmatique",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "programmatique-achievement-report",
        eventCategory: "page-view",
      },
    },
  },
];

export default programmatiqueRoutes;

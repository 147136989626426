// import moment from "moment/moment";

export const log = (...args) => {
  if (process.env.NODE_ENV !== "production") {
    console.log(...args);
  }
};

export const truncate = (value, stop, clamp) => {
  if (!value) return "";
  value = value.toString();
  clamp = stop < value.length && clamp !== "" ? clamp || "..." : "";
  return value.slice(0, stop) + clamp;
};

export const mtruncate = (value, stop, clamp = "") => {
  if (!value) return "";
  if (value.length <= stop) {
    return value;
  }
  const clampLen = clamp.length;
  const remainingChars = stop - clampLen;
  const firstHalfLen = Math.ceil(remainingChars / 2);
  const secondHalfLen = Math.floor(remainingChars / 2);
  const firstHalf = value.substr(0, firstHalfLen);
  const secondHalf = value.substr(value.length - secondHalfLen);
  return firstHalf + clamp + secondHalf;
};

export const ltruncate = (value, start, clamp = "") => {
  if (!value) return "";
  value = value.toString();
  return clamp + value.slice(start, value.length);
};

export const ucfirst = (s) => s && s[0].toUpperCase() + s.slice(1);

export const round2Dec = (value) => {
  if (!value) return 0;
  value = Math.round(value * 100) / 100;
  value = value.toLocaleString("en", {
    useGrouping: false,
    minimumFractionDigits: 2,
  });
  return parseFloat(value);
};

export const prettyNbs = (x, sep = " ") => {
  if (!x) return x;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
};

export const formatTime = (timeInSeconds) => {
  let hours = Math.floor(timeInSeconds / 3600);
  let minutes = Math.floor((timeInSeconds % 3600) / 60);
  minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  let seconds = parseInt(timeInSeconds % 60);
  seconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  if (hours >= 24) {
    let days = Math.floor(hours / 24);
    days = prettyNbs(days);
    hours = hours % 24;
    hours = hours < 10 ? `0${hours}` : `${hours}`;
    return `${days} days ${hours}h ${minutes}m ${seconds}s`;
  } else if (hours >= 1 && minutes >= 1) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else if (hours >= 1) {
    return `${hours}h ${minutes}m`;
  } else if (minutes >= 1) {
    return `${minutes}m ${seconds}s`;
  }
  return `${seconds}s`;
};

export const formatDaysToTime = (
  daysValue,
  showMinutes = true,
  showSeconds = true
) => {
  const days = Math.floor(daysValue);
  const totalHours = daysValue * 24;
  const hours = Math.floor(totalHours % 24);
  const totalMinutes = (totalHours - Math.floor(totalHours)) * 60;
  const minutes = Math.floor(totalMinutes);
  const seconds = Math.round((totalMinutes - minutes) * 60);

  const formattedDays = String(days).padStart(2, "0");
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = showMinutes
    ? ` ${String(minutes).padStart(2, "0")}m`
    : "";
  const formattedSeconds = showSeconds
    ? ` ${String(seconds).padStart(2, "0")}s`
    : "";

  return `${formattedDays}d ${formattedHours}h${formattedMinutes}${formattedSeconds}`;
};

const formatters = {};
const DEFAULT_NB_DECIMALS = 2;

export const formatNumber = (
  number,
  {
    compact = false,
    compactLong = false,
    currency = false,
    percent = false,
    nbDecimals = DEFAULT_NB_DECIMALS,
  } = {}
) => {
  const index = [
    Number(compact), // K(ilo), M(ega), G(iga)...
    Number(compactLong),
    Number(currency),
    Number(percent),
    Number(nbDecimals),
  ].join("_");
  if (formatters[index] === undefined) {
    formatters[index] = new Intl.NumberFormat("fr-FR", {
      ...(currency === true ? { style: "currency", currency: "EUR" } : {}),
      maximumFractionDigits: Math.max(nbDecimals, 0),
      ...(compact === true
        ? {
            notation: "compact",
            ...(compactLong ? { compactDisplay: "long" } : {}),
          }
        : {}),
      ...(percent === true ? { style: "percent" } : {}),
    });
  }

  return formatters[index].format(parseFloat(number));
};

export const formatNumberCompact = (number, nbDecimals = DEFAULT_NB_DECIMALS) =>
  formatNumber(number, { compact: true, nbDecimals });

export const formatCurrency = (number, nbDecimals = DEFAULT_NB_DECIMALS) =>
  formatNumber(number, { currency: true, nbDecimals });

export const formatCurrencyCompact = (
  number,
  nbDecimals = DEFAULT_NB_DECIMALS
) => formatNumber(number, { currency: true, compact: true, nbDecimals });

export const formatCurrencyCompactLong = (
  number,
  nbDecimals = DEFAULT_NB_DECIMALS
) =>
  formatNumber(number, {
    currency: true,
    compact: true,
    compactLong: true,
    nbDecimals,
  });

export const formatPercent = (number, nbDecimals = DEFAULT_NB_DECIMALS) =>
  formatNumber(number, { percent: true, nbDecimals });

export const decodeHtmlEntities = (encoded) => {
  const decoder = decoder || document.createElement("div");
  decoder.innerHTML = encoded;

  return decoder.textContent;
};

export const downloadCsvFile = (csvData, filename) => {
  if (csvData.length === 0) {
    console.error("exportCsvData: empty data");
    return;
  }
  const downloadLink = document.createElement("a");
  const blob = new Blob(["\ufeff", csvData]);
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

<template>
  <div>
    <v-autocomplete
      class="pt-2"
      v-model="storedAdUnits"
      :items="adUnits"
      item-text="name"
      item-value="id"
      :search-input.sync="searchText"
      v-on:keyup="debouncedGetData"
      clearable
      dense
      hide-details
      multiple
      outlined
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="GAM Ad Units"
    ></v-autocomplete>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";

import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

export default {
  name: "AdUnitFilter",
  data() {
    return {
      adUnits: [],
      searchText: "",
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  async created() {
    await this.getData();

    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: "regie/updateGamAdUnits",
      param: URL_PARAM_NAMES[this.$options.name],
      value: this.storedAdUnits,
      is_multiple: true,
      // is_integer: false,
      // is_boolean: false,
      // dependsOn: undefined,
    });
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const { data } = await this.axiosGet("/gam/adUnits", {
        search_value: this.searchText,
      });

      this.adUnits = data;
    },
  },
  computed: {
    storedAdUnits: {
      get() {
        return this.$store.getters["regie/getGamAdUnits"];
      },
      set(e) {
        this.$store.dispatch("regie/updateGamAdUnits", e);
      },
    },
  },
  watch: {
    storedAdUnits(newValue) {
      addQueryStringParam({
        router: this.$router,
        route: this.$route,
        param: URL_PARAM_NAMES[this.$options.name],
        value: newValue,
        is_multiple: true,
        // is_integer: false,
        // is_boolean: false,
      });
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <v-select
      outlined
      v-model="cost"
      :items="costs"
      item-text="name"
      item-value="name"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Coût"
    ></v-select>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";
import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

export default {
  name: "CostFilter",
  data() {
    return {
      costs: [],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  async created() {
    const { data } = await this.axiosGet("/regie/marmiton/costs");
    this.costs = data;

    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: "marmiton/updateCost",
      param: URL_PARAM_NAMES[this.$options.name],
      value: this.cost,
      // is_multiple: false,
      // is_integer: false,
      // is_boolean: false,
      // dependsOn: undefined,
    });
  },
  computed: {
    cost: {
      get() {
        return this.$store.getters["marmiton/getCost"];
      },
      set(e) {
        this.$store.dispatch("marmiton/updateCost", e);
      },
    },
  },
  watch: {
    cost(newValue) {
      addQueryStringParam({
        router: this.$router,
        route: this.$route,
        param: URL_PARAM_NAMES[this.$options.name],
        value: newValue,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
      });
    },
  },
};
</script>

<style></style>

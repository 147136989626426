<template>
  <line-chart
    title="RPMV vs Objectif"
    :loading="loading"
    :colors="colors"
    :series="series"
    :yAxis="yAxis"
    :xAxis="xAxis"
    :dags="[
      'ga_get_and_store_site_daily_dag',
      'partner_get_and_store_daily_dag',
      'partner_get_and_store_daily_ifempty_dag',
    ]"
  ></line-chart>
</template>

<script>
import { cacheTime } from "@axios";
import moment from "moment/moment";

import useAxios from "@/hooks/useAxios";
import { colors } from "@/utils/constants";
import { getDate, getDaysArray, monthRange } from "@/utils/dates";
import { formatNumber } from "@/utils/formatting";

import LineChart from "@/components/common/charts/LineChart";

export default {
  name: "RpmvSessionsChart",
  components: {
    LineChart,
  },
  data() {
    return {
      loading: false,
      series: [
        {
          name: "RPMV",
          data: [],
        },
        {
          name: "Objectif",
          data: [],
        },
      ],
      colors: [colors.rpm, colors.objective], // "#F44336", "#00d4bd", "#9C27B0",
      yAxis: [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
      ],
      xAxis: {
        categories: [],
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.month &&
        this.month.length > 5
      ) {
        const queryParams = {
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnerViews && this.partnerViews.length > 0
            ? { partner_views: this.partnerViews.join(",") }
            : {}),
        };
        const range = monthRange(this.month);
        const categories = getDaysArray(range[0], range[1]);
        const mainData = {};
        const yesterday = moment(getDate(-1));
        for (const x of categories) {
          const defaultValue = moment(new Date(x)) >= yesterday ? null : 0;
          mainData[x] = {
            ca: defaultValue,
            objRevenue: defaultValue,
            sessions: defaultValue,
            views: defaultValue,
            rpms: defaultValue,
            rpmv: defaultValue,
          };
        }
        // store dispatch  getdetailedRevenue
        const detailedRevenueData = await this.$store.dispatch(
          "premium/getDetailedRevenue",
          queryParams
        );

        this.loading = true;
        const { data: detailedAudienceData } = await this.axiosGet(
          "/programmatic/detailed-audience-monthly-report",
          queryParams,
          {
            cache: {
              maxAge: cacheTime,
            },
          }
        );
        delete queryParams["partner_views"];

        this.xAxis = {
          ...this.xAxis,
          categories,
        };

        detailedRevenueData.map((data) => {
          mainData[data["date"]]["ca"] = data["ca"];
        });
        detailedAudienceData.map((data) => {
          mainData[data["date"]]["sessions"] = data["sessions"];
          mainData[data["date"]]["views"] = data["views"];
        });
        detailedRevenueData.map((data) => {
          mainData[data["date"]]["ca"] = data["ca"];
        });
        const mainDataWithRpm = this.calculateRPM(categories, mainData);

        this.series = [
          {
            name: "RPMV",
            data: categories.map(
              (category) => mainDataWithRpm[category]["rpmv"]
            ),
          },
        ];

        this.loading = false;
      }

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
    calculateRPM(categories, mainData) {
      for (const x of categories) {
        if (mainData[x]["ca"] > 0 && mainData[x]["sessions"] > 0)
          mainData[x]["rpms"] = (
            (mainData[x]["ca"] / mainData[x]["sessions"]) *
            1000
          ).toFixed(3);

        if (mainData[x]["ca"] > 0 && mainData[x]["views"] > 0)
          mainData[x]["rpmv"] = (
            (mainData[x]["ca"] / mainData[x]["views"]) *
            1000
          ).toFixed(3);
      }

      return mainData;
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    month() {
      return this.$store.getters["premium/getMonthDate"];
    },
    partnerViews() {
      return this.$store.getters["premium/getPartnerViews"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
  },
};
</script>

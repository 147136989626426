<template>
  <div>
    <v-card class="mb-6 pa-2">
      <v-card-title> Formulaire</v-card-title>

      <v-row class="ma-3">
        <v-col cols="12">
          <v-text-field
            v-model="url"
            label="Entrer une URL"
            outlined
            type="url"
            required
            @input="emitUrl"
          ></v-text-field>
          <v-text-field
            v-model="utm"
            label="Entrer l'UTM"
            outlined
            type="utm"
            required
            readonly
            @input="emitUtm"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: "", // Variable pour stocker l'URL
      utm: "utm_campaign=DarkSocial&utm_medium=Social&utm_source=Whatsapp", // Default value for UTM
    };
  },
  methods: {
    emitUrl() {
      // const fullUrl = `${this.url}?${this.utm}`;
      console.log("Emitting URL:", this.url);
      this.$emit("update:url", this.url);
    },
    emitUtm() {
      const fullUrl = `${this.url}?${this.utm}`;
      console.log("Emitting UTM:", this.utm); // Debugging log
      this.$emit("update:utm", this.utm);
    },
  },
};
</script>
<style scoped></style>

const generalRoutes = [
  {
    path: "/",
    redirect: "/general/dashboard",
    resource: "general",
    action: "manage",
  },
  {
    path: "/general/dashboard",
    name: "general-dashboard",
    component: () => import("@/views/pages/general/dashboard"),
    meta: {
      layout: "content",
      resource: "general",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "general-dashboard",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/rwm-data",
    name: "rwm-data",
    component: () => import("@/views/pages/general/RWMData"),
    meta: {
      layout: "content",
      resource: "general",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "rwm-data",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/general/dashboard/report",
    name: "general-dashboard-report",
    component: () => import("@/views/pages/general/dashboard"),
    meta: {
      layout: "blank",
      resource: "general",
      action: "manage",
    },
  },
  {
    path: "/sites-ga-view-id/:departmentShortname",
    name: "sites-ga-view-id",
    component: () => import("@/views/pages/SitesGaViewId.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    // path: "/ga-sites/:departmentShortname?",
    path: "/ga-sites/main",
    name: "ga-sites-main",
    component: () => import("@/views/pages/GaSites.vue"),
    props: { departmentShortnameSelected: "main" },
    meta: {
      layout: "content",
      resource: "general",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "general-ga-site",
        eventCategory: "page-view",
      },
    },
  },
];

export default generalRoutes;

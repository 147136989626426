<template>
  <v-card>
    <v-card-title class="align-start">
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherche (par : Site, Redac, Catégorie, Titre, URL, ...)"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <table-stats :data="stats"></table-stats>
        </v-col>
      </v-row>

      <dot-menu
        :isLoading="loading"
        @exportExcel="exportExcel"
        :excelButton="{
          excelData,
          filename: `affiliations-${this.startDate}_${this.endDate}.xlsx`,
          disabled: exportDisabled,
        }"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :sort-by="'nb_clicks'"
        :sort-desc="true"
        class="elevation-1"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100, 200, 500],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.title="{ item }">
          <div class="truncateCell" :title="item.title">
            {{ item.title }}
          </div>
        </template>
        <template v-slot:item.url_path_identifier="{ item }">
          <div class="truncateCell" :title="item.url_path_identifier">
            <a :href="item.url" target="_blank">{{
              item.url_path_identifier
            }}</a>
          </div>
        </template>
        <template v-slot:item.meta_description="{ item }">
          <div class="truncateCell" :title="item.meta_description">
            {{ item.meta_description }}
          </div>
        </template>
        <template v-slot:item.shop_name_details="{ item }">
          <div class="truncateCell" :title="item.shop_name_details">
            {{ item.shop_name }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <progress-bar :download="download" />
  </v-card>
</template>

<style scoped>
.truncateCell {
  max-height: 80px;
  overflow: hidden;
}
</style>

<script>
import debounce from "lodash/debounce";

import DotMenu from "@/components/common/menus/DotMenu";
import ProgressBar, {
  download_initial_state,
} from "@/components/common/ProgressBar";
import TableStats from "@/components/c2c/dashboard/cards/TableStatsCard";

import useAxios from "@/hooks/useAxios";
import {
  decodeHtmlEntities,
  formatCurrency,
  formatNumber,
  formatPercent,
} from "@/utils/formatting";

export default {
  name: "Affiliations",
  components: {
    DotMenu,
    ProgressBar,
    TableStats,
  },
  data() {
    return {
      stats: {},
      search: "",
      numberOfPages: 0,
      total: 0,
      items: [],
      forceCall: false,
      optionsInitialized: false,
      loading: false,
      download: download_initial_state(),
      options: {
        page: 1,
        itemsPerPage: 500,
      },
      excelData: new Blob([""], { type: "text/plain" }),
      headers: [
        {
          text: "Site",
          value: "site_name",
        },
        {
          text: "Date création",
          align: "start",
          value: "created",
        },
        {
          text: "Dernière MàJ",
          align: "start",
          value: "updated",
        },
        {
          text: "Rédac",
          align: "start",
          value: "redac_name",
        },
        {
          text: "Titre",
          value: "title",
          filterable: false,
        },
        {
          text: "URL",
          value: "url_path_identifier",
          filterable: false,
        },
        {
          text: "Méta descr",
          value: "meta_description",
          filterable: false,
        },
        {
          text: "Keywords",
          value: "keywords",
          filterable: false,
        },
        {
          text: "Nb chars",
          value: "chars_count",
          filterable: false,
        },
        {
          text: "Catégorie",
          value: "category",
          filterable: false,
        },
        {
          text: "New",
          value: "is_new",
          filterable: false,
        },
        {
          text: "Diapo",
          value: "is_diapo",
          filterable: false,
        },
        {
          text: "Typologie",
          value: "ga_typology",
          filterable: false,
        },
        {
          text: "Type",
          value: "content_type",
          filterable: false,
        },
        {
          text: "Vues",
          value: "views",
        },
        {
          text: "Sessions",
          value: "sessions",
        },
        {
          text: "Durée sess. moy.",
          value: "avg_session_duration",
        },
        /* Not collected yet
        {
          text: "Visiteurs",
          value: "unique_users",
        }, */
        {
          text: "Nb. clics",
          value: "nb_clicks",
        },
        {
          text: "Affiliation",
          value: "publication_name",
        },
        {
          text: "Enseigne(s)",
          value: "shop_name_details",
        },
        {
          text: "Ventes en cours",
          value: "sale_pending",
        },
        {
          text: "Comm. en cours",
          value: "commission_pending",
        },
        {
          text: "Transacs en cours",
          value: "transac_pending",
        },
        {
          text: "Ventes réalisées",
          value: "sale_confirmed",
        },
        {
          text: "Comm. réalisées",
          value: "commission_confirmed",
        },
        {
          text: "Transacs réalisées",
          value: "transac_confirmed",
        },
        {
          text: "Ventes annulées",
          value: "sale_canceled",
        },
        {
          text: "Comm. annulées",
          value: "commission_canceled",
        },
        {
          text: "Transacs annulées",
          value: "transac_canceled",
        },
        {
          text: "Ventes totales",
          value: "sale_total",
        },
        {
          text: "Comm. totales",
          value: "commission_total",
        },
        {
          text: "Transacs totales",
          value: "transac_total",
        },
        {
          text: "Clics / vue",
          value: "clicks_per_view",
        },
        {
          text: "Clics / session",
          value: "clicks_per_session",
        },
        {
          text: "Transfo",
          value: "transformation",
        },
        {
          text: "Panier",
          value: "panier",
        },
        {
          text: "Tx comm.",
          value: "commission_rate",
        },
        {
          text: "CPV",
          value: "cpv",
        },
        {
          text: "CPM",
          value: "cpm",
        },
        {
          text: "CPC",
          value: "cpc",
        },
      ],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    performances_url_params(forExport = false) {
      return {
        start_date: this.startDate,
        end_date: this.endDate,
        sites_id: this.sites.join(","),
        search_text: this.search,
        page: Math.max(this.options.page - 1, 0),
        ...(forExport ? {} : { limit: this.options.itemsPerPage }),
        sort_by: this.options.sortBy?.[0] ?? "clicks",
        sort_desc: this.options.sortDesc?.[0] ?? true,
      };
    },
    formatResult(data) {
      return data.items.map((item) => {
        const site = this.getAllSites.find(
          (_site) => _site.id === item.site_id
        );

        const nb_shop_names = item.shop_name.split(", ").length;
        if (nb_shop_names === 1) {
          item.shop_name_details = item.shop_name;
        }

        return {
          ...item,
          site_name: site?.name ?? "",
          // numbers:
          views: formatNumber(item.views),
          sessions: formatNumber(item.sessions),
          chars_count: formatNumber(item.chars_count),
          avg_session_duration: formatNumber(item.avg_session_duration),
          unique_users: formatNumber(item.unique_users),
          nb_clicks: formatNumber(item.nb_clicks),
          transac_pending: formatNumber(item.transac_pending),
          transac_confirmed: formatNumber(item.transac_confirmed),
          transac_canceled: formatNumber(item.transac_canceled),
          transac_total: formatNumber(item.transac_total),
          // currency:
          sale_pending: formatCurrency(item.sale_pending),
          commission_pending: formatCurrency(item.commission_pending),
          sale_confirmed: formatCurrency(item.sale_confirmed),
          commission_confirmed: formatCurrency(item.commission_confirmed),
          sale_canceled: formatCurrency(item.sale_canceled),
          commission_canceled: formatCurrency(item.commission_canceled),
          sale_total: formatCurrency(item.sale_total),
          commission_total: formatCurrency(item.commission_total),
          panier: formatCurrency(
            item.sale_total /
              (item.transac_total === 0 ? 1 : item.transac_total)
          ),
          cpv: formatCurrency(
            (item.commission_total /
              (item.sessions === 0 ? 1 : item.sessions)) *
              1000
          ),
          cpm: formatCurrency(
            (item.commission_total / (item.views === 0 ? 1 : item.views)) * 1000
          ),
          cpc: formatCurrency(
            item.commission_total / (item.nb_clicks === 0 ? 1 : item.nb_clicks)
          ),
          // percent:
          clicks_per_view: formatPercent(
            item.nb_clicks / (item.views === 0 ? item.nb_clicks : item.views)
          ),
          clicks_per_view: formatPercent(
            item.nb_clicks /
              (item.sessions === 0 ? item.nb_clicks : item.sessions)
          ),
          transformation: formatPercent(
            item.transac_total / (item.nb_clicks === 0 ? 1 : item.nb_clicks)
          ),
          commission_rate: formatPercent(
            item.commission_total /
              (item.sale_total === 0 ? item.sale_total : item.commission_total)
          ),
          // specialChars:
          title: decodeHtmlEntities(item.title),
          meta_description: decodeHtmlEntities(item.meta_description),
        };
      });
    },
    async exportExcel() {
      if (
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10
      ) {
        alert("Sélectionnez une plage de date !");
        return false;
      }
      this.download.running = true;
      const { data } = await this.axiosGet(
        "/c2c/affiliations/export_xlsx",
        this.performances_url_params(true),
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            this.download.percentCompleted = Math.ceil(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );

      this.excelData = data;
    },
    debouncedSearch: debounce(async function () {
      if (this.options.page !== 1) {
        this.options.page = 1;
        // updateData automatically call through watcher
      } else {
        this.getData();
      }
    }, 500),
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        (this.applyFilters || this.forceCall) &&
        !this.loading &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        this.loading = true;
        const { data } = await this.axiosGet(
          "/c2c/affiliations",
          this.performances_url_params()
        );

        this.items = this.formatResult(data);
        this.loading = false;
        this.total = data.total;
        this.numberOfPages = data.total / this.options.itemsPerPage;
        this.stats = data;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }

      this.forceCall = false;
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    getAllSites() {
      return this.$store.getters["common/getSiteList"];
    },
    sites() {
      return this.$store.getters["c2c/getSites"];
    },
    startDate() {
      return this.$store.getters["c2c/getDates"][0];
    },
    endDate() {
      return this.$store.getters["c2c/getDates"][1];
    },
    exportDisabled() {
      return this.loading || this.items.length === 0;
    },
  },
  watch: {
    ["download.percentCompleted"]() {
      if (this.download.percentCompleted >= 100) {
        setTimeout(() => {
          this.download = download_initial_state();
        }, 500);
      }
    },
    options() {
      if (this.optionsInitialized) {
        // Not on initialization : wait for a real user change
        this.forceCall = true;
      }

      this.optionsInitialized = true;
      this.debouncedGetData();
    },
    search() {
      this.forceCall = true;
      this.debouncedSearch();
    },
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>

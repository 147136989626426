<template>
  <v-card>
    <v-card-title>
      <h3 class="text-h6">Historique des événements</h3>
    </v-card-title>
    <v-card-text>
      <vue-apex-charts
        height="540"
        :options="chartOptions"
        :series="series"
        v-if="series"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import "apexcharts/dist/locales/fr.json";

import { getDateStr } from "@/utils/dates";

export default {
  name: "HistoricalEventsGraph",
  components: {
    VueApexCharts,
  },
  created() {
    // this.updateChart();
  },
  data() {
    return {
      k: 0,
      series: null,
      chartOptions: null,
    };
  },
  methods: {
    generateDateRange() {
      const [startDateStr, endDateStr] = this.dates;
      const startDate = new Date(this.dateToTimestamp(startDateStr));
      const endDate = new Date(this.dateToTimestamp(endDateStr));

      const range = [];
      const currentDate = new Date(startDate.getTime());
      while (currentDate <= endDate) {
        range.push(new Date(currentDate).getTime()); // Store as timestamp
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return range;
    },
    dateToTimestamp(dateStr) {
      // return new Date(dateStr.slice(0, 10)).getTime();
      const date = new Date(dateStr);
      date.setHours(0, 0, 0, 0);

      return date.getTime();
    },
    formatData(data) {
      // Parse the data and group by type, tracking event counts per day
      const groupedData = data.reduce((acc, entry) => {
        const date = this.dateToTimestamp(entry.date);
        const type = entry.type;
        if (!acc[type]) {
          acc[type] = {};
        }
        acc[type][date] = (acc[type][date] || 0) + 1;
        return acc;
      }, {});

      // Determine the full date range
      const dateRange = this.generateDateRange();
      // Fill in missing dates with 0 and structure data for ApexCharts
      this.series = Object.entries(groupedData).map(([type, dateCounts]) => {
        const dataPoints = dateRange.map((timestamp) => [
          timestamp,
          dateCounts[timestamp] || 0, // Fill missing days with 0
        ]);

        return {
          name: type,
          data: dataPoints,
        };
      });
    },
    updateChart() {
      this.formatData(this.events);

      this.chartOptions = {
        chart: {
          // height: 350,
          type: "bar",
          stacked: true,
          // stackType: '100%',
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: true,
          },
          events: {
            zoomed: this.onZoomed,
          },
        },
        tooltip: {
          x: {
            formatter: function (value) {
              return new Date(value).toLocaleDateString("fr-FR");
            },
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "Nombre d'événements : ";
              },
            },
            formatter: function (
              value,
              { series, seriesIndex, dataPointIndex, w }
            ) {
              let total = 0;
              for (let serie of series) {
                total += serie[dataPointIndex];
              }
              return total;
            },
          },
          marker: {
            show: false,
          },
          // we can also use custom
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          padding: {
            right: 30,
            left: 20,
          },
        },
        xaxis: {
          type: "datetime",
        },
      };

      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 500);
    },
    async onZoomed(chartContext, { xaxis }) {
      const { min, max } = xaxis;
      const startDate = new Date(min);
      const endDate = new Date(max);
      const startDateStr = getDateStr(startDate);
      const endDateStr = getDateStr(endDate);
      const reapplyFilters =
        startDate < new Date(this.dates[0]) ||
        endDate > new Date(this.dates[1]);

      await this.$store.dispatch("rmra/updateDates", [
        startDateStr,
        endDateStr,
      ]);
      await this.filterEvents(min, max);
      if (reapplyFilters) {
        await this.$store.dispatch("common/updateApplyFilters", true);
      }
    },
    async filterEvents(min, max) {
      const filteredEvents = this.events.filter((event) => {
        const date = new Date(event.date.substring(0, 10));
        const timestamp = date.getTime();

        return timestamp >= min && timestamp <= max;
      });
      await this.$store.dispatch("rmra/updateFilteredEvents", filteredEvents);
    },
  },
  computed: {
    dates() {
      return this.$store.getters["rmra/getDates"];
    },
    events() {
      return this.$store.getters["rmra/getEvents"];
    },
  },
  watch: {
    // dates() {
    //   this.updateChart();
    //   this.k += 1;
    // },
    events() {
      this.updateChart();
    },
  },
};
</script>

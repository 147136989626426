var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"align-start"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Performances par catégorie")])],1),_c('dot-menu',{attrs:{"isLoading":_vm.loading,"excelButton":{
        excelData: _vm.excelData,
        filename: ("perf-par-category-" + (this.startDate) + "_" + (this.endDate) + ".xlsx"),
        disabled: _vm.exportDisabled,
      }},on:{"exportExcel":_vm.exportExcel}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"page":_vm.options.page,"pageCount":_vm.numberOfPages,"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":true,"footer-props":{
        'items-per-page-options': [10, 25, 50],
      },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":""},nativeOn:{"click":function($event){_vm.dialogModel = {
              dialog: !_vm.dialogModel.dialog,
              category: item.id,
            }}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiChartLine))])],1)]}},{key:"item.category",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.category}},[_vm._v(" "+_vm._s(item.category)+" ")])]}},{key:"item.ca",fn:function(ref){
            var item = ref.item;
return [_c('div',{attrs:{"title":item.caByChannel}},[_vm._v(" "+_vm._s(item.ca)+" ")])]}},{key:"item.sessions",fn:function(ref){
            var item = ref.item;
return [_c('div',{attrs:{"title":item.sessionsByChannel}},[_vm._v(" "+_vm._s(item.sessions)+" ")])]}}])})],1),_c('progress-bar',{attrs:{"download":_vm.download}}),_c('v-dialog',{attrs:{"width":"70vw"},model:{value:(_vm.dialogModel.dialog),callback:function ($$v) {_vm.$set(_vm.dialogModel, "dialog", $$v)},expression:"dialogModel.dialog"}},[_c('category-performances-modal',{attrs:{"dialogOpen":_vm.dialogModel.dialog,"category":_vm.dialogModel.category,"startDate":this.startDate,"endDate":this.endDate,"periodType":this.periodType}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
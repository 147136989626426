var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"pdfContainer",staticClass:"pdfContainer"},[_c('p',{staticClass:"text-2xl mb-6"},[_vm._v(" Stats mensuelles "),_c('dag-info',{attrs:{"dags":[
        'ga_get_and_store_site_daily_dag',
        'partner_get_and_store_daily_dag',
        'partner_get_and_store_daily_ifempty_dag' ]}})],1),_c('v-card',{staticClass:"mb-6 pa-2",attrs:{"outlined":""}},[_c('v-row',{staticClass:"ma-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('filters')],1)],1)],1),_vm._l((_vm.sites),function(site,index){return [(site)?_c('v-card',{key:site.name,staticClass:"mb-6 pa-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(_vm._s(site.name))]),_c('v-card-subtitle',[_vm._v("CA")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"locale":"fr-FR","headers":_vm.headersCa,"items":_vm.itemsCa[_vm.site_id_key(site.id)],"loading":_vm.loading,"hide-default-footer":true,"disable-pagination":true,"disable-filtering":true,"disable-sort":true},scopedSlots:_vm._u([{key:"item.month",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{
                boldCell: item.month === 'Total',
              }},[_vm._v(" "+_vm._s(item.month)+" ")])]}},{key:"item.objective",fn:function(ref){
              var item = ref.item;
return [_c('div',{class:{
                boldCell: item.month === 'Total',
              }},[_vm._v(" "+_vm._s(item.objective)+" ")])]}},{key:"item.value",fn:function(ref){
              var item = ref.item;
return [_c('div',{class:{
                boldCell: item.month === 'Total',
              }},[_vm._v(" "+_vm._s(item.value)+" ")])]}},{key:"item.estimated",fn:function(ref){
              var _obj;

              var item = ref.item;return [_c('div',{class:( _obj = {
                boldCell: item.month === 'Total',
                coloredCell: true,
                disabledCell: item.estimated === ''
              }, _obj['d-flex'] = true, _obj['justify-end'] = true, _obj )},[_vm._v(" "+_vm._s(item.estimated)+" ")])]}},{key:"item.delta",fn:function(ref){
              var _obj;

              var item = ref.item;return [_c('div',{class:( _obj = {
                boldCell: item.month === 'Total',
                coloredCell: true,
                greenCell: item.raw_delta >= 0,
                redCell: item.raw_delta < 0
              }, _obj['d-flex'] = true, _obj['justify-end'] = true, _obj )},[_vm._v(" "+_vm._s(item.delta)+" ")])]}},{key:"item.previous",fn:function(ref){
              var item = ref.item;
return [_c('div',{class:{
                boldCell: item.month === 'Total',
              }},[_vm._v(" "+_vm._s(item.previous)+" ")])]}}],null,true)})],1),_c('v-card-subtitle',[_vm._v("Sessions")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"locale":"fr-FR","headers":_vm.headersSessions,"items":_vm.itemsSessions[_vm.site_id_key(site.id)],"loading":_vm.loading,"hide-default-footer":true,"disable-pagination":true,"disable-filtering":true,"disable-sort":true},scopedSlots:_vm._u([{key:"item.month",fn:function(ref){
              var item = ref.item;
return [_c('div',{class:{
                boldCell: item.month === 'Total',
              }},[_vm._v(" "+_vm._s(item.month)+" ")])]}},{key:"item.objective",fn:function(ref){
              var item = ref.item;
return [_c('div',{class:{
                boldCell: item.month === 'Total',
              }},[_vm._v(" "+_vm._s(item.objective)+" ")])]}},{key:"item.value",fn:function(ref){
              var item = ref.item;
return [_c('div',{class:{
                boldCell: item.month === 'Total',
              }},[_vm._v(" "+_vm._s(item.value)+" ")])]}},{key:"item.estimated",fn:function(ref){
              var _obj;

              var item = ref.item;return [_c('div',{class:( _obj = {
                boldCell: item.month === 'Total',
                coloredCell: true,
                disabledCell: item.estimated === ''
              }, _obj['d-flex'] = true, _obj['justify-end'] = true, _obj )},[_vm._v(" "+_vm._s(item.estimated)+" ")])]}},{key:"item.percent",fn:function(ref){
              var _obj;

              var item = ref.item;return [_c('div',{class:( _obj = {
                boldCell: item.month === 'Total',
                coloredCell: true,
                greenCell: item.raw_percent >= 1,
                redCell: item.raw_percent < 1
              }, _obj['d-flex'] = true, _obj['justify-end'] = true, _obj )},[_vm._v(" "+_vm._s(item.percent)+" ")])]}},{key:"item.previous",fn:function(ref){
              var item = ref.item;
return [_c('div',{class:{
                boldCell: item.month === 'Total',
              }},[_vm._v(" "+_vm._s(item.previous)+" ")])]}}],null,true)})],1),_c('v-card-subtitle',[_vm._v("RPM")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"locale":"fr-FR","headers":_vm.headersRpm,"items":_vm.itemsRpm[_vm.site_id_key(site.id)],"loading":_vm.loading,"hide-default-footer":true,"disable-pagination":true,"disable-filtering":true,"disable-sort":true},scopedSlots:_vm._u([{key:"item.percent",fn:function(ref){
              var _obj;

              var item = ref.item;return [_c('div',{class:( _obj = {
                coloredCell: true,
                greenCell: item.raw_percent >= 1,
                redCell: item.raw_percent < 1
              }, _obj['d-flex'] = true, _obj['justify-end'] = true, _obj )},[_vm._v(" "+_vm._s(item.percent)+" ")])]}}],null,true)})],1),_c('progress-bar',{attrs:{"download":_vm.download}})],1):_vm._e()]}),_c('dot-menu',{attrs:{"position":"page-top-right","isLoading":_vm.isLoading,"pdfButton":{
      getContainerRef: function () { return _vm.$refs.pdfContainer; },
      filename: 'monthly_achievements.pdf',
      disabled: _vm.loading,
    },"excelButton":{
      excelData: _vm.excelData,
      filename: 'monthly_achievements.xlsx',
      disabled: _vm.loading,
    }},on:{"exportExcel":_vm.exportExcel}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <h1 class="text-2xl mb-6">SEO Suivi P1</h1>
    <!-- Filtre date et domain -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-row class="ma-3">
        <v-select
          class="ml-3 mr-3"
          v-model="domainSelected"
          :items="domains"
          label="Domain"
          outlined
        ></v-select>
        <date-filter @valueChanged="updateRangeDate"></date-filter>
      </v-row>
    </v-card>
    <!-- tablo -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 mb-3 font-weight-bold">
        Suivi P1 / {{ this.domainSelected }} / From {{ this.startDate }} to
        {{ this.endDate }}
      </v-card-title>
      <v-data-table class="ml-3 mr-3" :headers="headers" :items="items">
        <template v-slot:item.pos_conc1_diff="{ item }">
          <span :class="item.pos_conc1_diff < 0 ? 'green--text' : 'red--text'">
            {{ item.pos_conc1_diff }}
          </span>
        </template>
        <template v-slot:item.pos_conc2_diff="{ item }">
          <span :class="item.pos_conc2_diff < 0 ? 'green--text' : 'red--text'">
            {{ item.pos_conc2_diff }}
          </span>
        </template>
        <template v-slot:item.pos_conc3_diff="{ item }">
          <span :class="item.pos_conc3_diff < 0 ? 'green--text' : 'red--text'">
            {{ item.pos_conc3_diff }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";

import DateFilter from "@/components/common/filters/DateFilter";

export default {
  name: "seo-suivi-p1",
  components: {
    DateFilter,
  },
  data() {
    return {
      icons: {},
      headers: [],
      items: [],
      domains: ["www.closermag.fr", "www.gala.fr"],
      competitors: ["a", "b", "c"],
      domainSelected: "No domain selected",
      startDate: "",
      endDate: "",
    };
  },
  created() {},
  methods: {
    updateRangeDate(value) {
      this.startDate = value[0];
      this.endDate = value[1];
    },
    getHeaders() {
      this.headers = [
        { text: "keyword", value: "keyword" },
        { text: "diff", value: "pos_conc1_diff" },
        { text: this.competitors[0], value: "pos_conc1" },
        { text: "diff", value: "pos_conc2_diff" },
        { text: this.competitors[1], value: "pos_conc2" },
        { text: "diff", value: "pos_conc3_diff" },
        { text: this.competitors[2], value: "pos_conc3" },
        { text: "search volume", value: "search_volume" },
        { text: "traffic", value: "traffic" },
      ];
    },
    async getInfosDaily() {
      if (this.endDate === undefined) {
        return;
      }
      if (this.domainSelected === "No domain selected") {
        return;
      }
      const params = {
        date: this.endDate,
        domain: this.domainSelected,
      };
      const params2 = {
        date: this.startDate,
        domain: this.domainSelected,
      };
      const response = await axios.get("/seo/suivi-p1/get-infos-daily", {
        params,
      });
      const response2 = await axios.get("/seo/suivi-p1/get-infos-daily", {
        params: params2,
      });
      this.items = response.data;
      this.items2 = response2.data;

      this.items.forEach((item) => {
        const matchingItem = this.items2.find(
          (item2) => item2.keyword === item.keyword
        );
        if (item.pos_conc1 === null) {
          item.pos_conc1_diff = null;
        } else if (matchingItem) {
          item.pos_conc1_diff = item.pos_conc1 - matchingItem.pos_conc1;
        }
        if (item.pos_conc2 === null) {
          item.pos_conc2_diff = null;
        } else if (matchingItem) {
          item.pos_conc2_diff = item.pos_conc2 - matchingItem.pos_conc2;
        }
        if (item.pos_conc3 === null) {
          item.pos_conc3_diff = null;
        } else if (matchingItem) {
          item.pos_conc3_diff = item.pos_conc3 - matchingItem.pos_conc3;
        }
      });
    },
    async getCompetitors() {
      if (this.domainSelected === "No domain selected") {
        return;
      }
      const params = {
        domain: this.domainSelected,
      };
      const response = await axios.get("/seo/suivi-p1/get-competitors", {
        params,
      });
      this.competitors = response.data;
    },
  },
  watch: {
    async domainSelected() {
      await this.getCompetitors();
      this.getHeaders();
      this.getInfosDaily();
    },
    endDate() {
      this.getInfosDaily();
    },
  },
};
</script>

<style scoped>
.green--text {
  color: green;
}

.red--text {
  color: red;
}
</style>

import isEqual from "lodash/isEqual";

/** Since I've been unable to send uploaded file via traditional method
 * const formData = new formData();
 * formData.append("file", this.file);
 *
 * I've centralized method used by Thibault and consisting in parsing the file
 * and sending its content
 *
 * @param file      being the v-model of v-input-file's component.
 */
export const getUploadedFileContent = async (file) => {
  return new Promise((resolve, reject) => {
    if (file === null) {
      resolve(null);
      return;
    }
    const reader = new FileReader();

    reader.onload = (event) => {
      const content = event.target.result;
      resolve(content);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsText(file);
  });
};

export const addQueryStringParam = (params, anchor = undefined) => {
  const {
    router,
    route,
    param,
    value,
    is_multiple = false,
    is_integer = false,
    is_boolean = false,
  } = params;
  if (isEqual(getUrlValue(params), value)) {
    return;
  }

  if (anchor && anchor.charAt(0) !== "#") {
    anchor = `#${anchor}`;
  }

  router.replace({
    ...route,
    query: {
      ...route.query,
      [param]: Array.isArray(value) ? value.join(",") : value,
    },
    hash: anchor ?? "",
  });
};

const waitUntilInitializedFromUrl = async (
  store,
  param,
  maxRetries = 5,
  checkInterval = 100
) => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      const condition =
        (await store.dispatch("common/isInitializedFromUrl", param)) === true;

      if (!condition) {
        return;
      }

      clearInterval(interval);
      resolve();
    }, checkInterval);

    setTimeout(() => {
      clearInterval(interval);
      reject();
    }, checkInterval * maxRetries);
  });
};

const setParamAsInitializing = async (store, param) =>
  await store.dispatch("common/setInitializedFromUrl", {
    param,
    status: false, // "on-going"
  });

const setParamAsInitialized = async (store, param) =>
  await store.dispatch("common/setInitializedFromUrl", {
    param,
    status: true, // "done"
  });

const setStoreFromUrlParam = async (
  store,
  dispatcher,
  param,
  value,
  dependsOn = undefined
) => {
  const callback = async () => {
    if (dispatcher) {
      await setParamAsInitializing(store, param);

      await store.dispatch(dispatcher, value);
    }

    await setParamAsInitialized(store, param);
  };

  if (dependsOn) {
    await waitUntilInitializedFromUrl(store, dependsOn, 15)
      .then(async () => await callback())
      .catch((error) => {
        console.log(
          `Can't initialize ${param}: ${dependsOn} has never initialized!`,
          error
        );
      });
  } else {
    await callback();
  }
};

const getBooleanValue = (val) => {
  return ["true", "1"].includes(val);
};

export const getUrlValue = (params) => {
  const {
    route,
    param,
    is_multiple = false,
    is_integer = false,
    is_boolean = false,
  } = params;

  let urlValue = null;
  if (param in route.query) {
    urlValue = route.query[param];
    if (is_multiple) {
      urlValue = urlValue ? urlValue.split(",") : [];
      if (urlValue.length > 0) {
        if (is_integer) {
          urlValue = urlValue.map((id) => parseInt(id));
        } else if (is_boolean) {
          urlValue = urlValue.map((val) => getBooleanValue(val));
        }
      }
    } else if (urlValue) {
      if (is_integer) {
        urlValue = parseInt(urlValue);
      } else if (is_boolean) {
        urlValue = getBooleanValue(urlValue);
      }
    }
  }

  return urlValue;
};

export const synchronizeFilterWithQueryString = async (params) => {
  const {
    // router, // used in getUrlValue()
    // route,
    store,
    dispatcher,
    param,
    value,
    // is_multiple = false,
    // is_integer = false,
    // is_boolean = false,
    dependsOn = undefined,
  } = params;
  const urlValue = getUrlValue(params);
  if (urlValue !== null) {
    await setStoreFromUrlParam(store, dispatcher, param, urlValue, dependsOn);
  } else {
    await setParamAsInitialized(store, param);
    addQueryStringParam(params);
  }
  return urlValue;
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"align-start"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Top articles "),_c('dag-info',{attrs:{"dags":_vm.useMarmitonFilters
              ? [
                  'get_and_store_posts_marmiton_ga_daily_dag',
                  'adn_daily_posts_sites_infos_dag' ]
              : [
                  'ga_get_and_store_site_daily_dag',
                  'adn_daily_posts_sites_infos_dag' ]}})],1)],1),_c('dot-menu',{attrs:{"isLoading":_vm.loading,"excelButton":{
        excelData: _vm.excelData,
        filename: ("top-articles-" + (this.startDate) + "_" + (this.endDate) + ".xlsx"),
        disabled: _vm.exportDisabled,
      }},on:{"exportExcel":_vm.exportExcel}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"disable-sort":true,"page":_vm.options.page,"pageCount":_vm.numberOfPages,"options":_vm.options,"server-items-length":_vm.total,"footer-props":{
        'items-per-page-options': [10, 25, 50],
      },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.title}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.url",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.url_path_identifier}},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url_path_identifier))])])]}}])})],1),_c('progress-bar',{attrs:{"download":_vm.download}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
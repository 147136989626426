<template>
  <div>
    <v-card class="pt-3 pb-3 mb-3">
      <v-row>
        <v-card-title>
          <h2 class="text-xl">Suivi des Tendances</h2>
        </v-card-title>
      </v-row>
      <v-row>
        <v-card-subtitle>
          <p>
            Actualisation toutes les 30 min -- Dernière actualisation :
            {{ this.updated_at }}
          </p>
        </v-card-subtitle>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            class="ml-6 mr-6"
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherche (par Tendance, Composition)"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          class="ml-6 mr-6"
          :items="trendItems"
          :headers="trendHeaders"
          :options.sync="options"
          :sort-by="options.sortBy"
          :sort-desc="options.sortDesc"
          :server-items-length="options.total"
          :page="options.page"
          :pageCount="numberOfPages"
        >
          <template
            v-for="header in trendHeaders"
            v-slot:[`header.${header.value}`]="{ header }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ header.text }}</span>
              </template>
              <span>{{ header.tooltip }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.trend_url="{ item }">
            <v-icon
              small
              @click="openUrl(item.trend_url)"
              class="cursor-pointer"
            >
              {{ icons.mdiOpenInNew }}
            </v-icon>
          </template>
        </v-data-table>
      </v-row>
      <v-row>
        <v-card-title>
          <h3 class="text-xl mb-6">Sujets à Traiter</h3>
        </v-card-title>
      </v-row>
      <v-row>
        <!-- Fenetre warning pour trends sans related post -->
      </v-row>
    </v-card>
  </div>
</template>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>

<script>
import useAxios from "@/hooks/useAxios";
import { mdiOpenInNew } from "@mdi/js";
export default {
  name: "trendsTable",
  components: {},

  data() {
    return {
      updated_at: "Nan",
      icons: {
        mdiOpenInNew,
      },
      trendItems: [],
      trendHeaders: [
        {
          text: "Tendance",
          value: "trend",
          sortable: true,
          tooltip: "Tendance sur google",
        },
        {
          text: "Départ",
          value: "started",
          sortable: true,
          tooltip: "Heure du début de la tendance",
        },
        {
          text: "Composition",
          value: "trend_composition",
          sortable: false,
          tooltip: "Composition de la tendance",
        },
        {
          text: "SV",
          value: "search_volume",
          sortable: true,
          tooltip: "Search Volume",
        },
        {
          text: "Articles correspondants",
          value: "related_posts",
          sortable: false,
          tooltip: "Articles en liens dans la base de données",
        },
        {
          text: "Exploration",
          value: "trend_url",
          sortable: false,
          tooltip: "Lien vers la tendance sur google",
        },
      ],

      options: {
        page: 1,
        itemsPerPage: 10,
        total: 10,
        sortBy: [],
        sortDesc: [],
      },
      numberOfPages: 0,
      search: "",
      search_column: "",
    };
  },

  setup() {
    const { axiosGet, axiosPost, isLoading } = useAxios();

    return {
      axiosGet,
      isLoading,
      axiosPost,
    };
  },

  methods: {
    openUrl(url) {
      window.open(url, "_blank");
    },
    async getData() {
      const params = {
        last_trends: 5,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort_by: this.options.sortBy,
        sort_desc: this.options.sortDesc,
      };
      console.log(params);
      const response = await this.axiosGet("/gtrends/get_trends", params);
      console.log(response);
      this.trendItems = response.data.data;

      this.options.total = response.data.total;
      this.postItems = response.data.data;
      this.numberOfPages = Math.ceil(
        response.data.total / this.options.itemsPerPage
      );
      this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  created() {},
  computed: {
    filtersApplied() {
      return this.$store.getters["common/getApplyFilters"];
    },
  },
  watch: {
    filtersApplied() {
      this.getData();
    },
    options() {
      this.getData();
    },
  },
};
</script>

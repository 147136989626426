<template>
  <v-card outlined>
    <v-card-title class="align-start">
      <v-row>
        <v-col cols="12">Performances par catégorie</v-col>
      </v-row>
      <dot-menu
        :isLoading="loading"
        @exportExcel="exportExcel"
        :excelButton="{
          excelData,
          filename: `perf-par-category-${this.startDate}_${this.endDate}.xlsx`,
          disabled: exportDisabled,
        }"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :sort-by="sortBy"
        :sort-desc="true"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.id="{ item }">
          <v-btn
            class="ma-2"
            text
            icon
            @click.native="
              dialogModel = {
                dialog: !dialogModel.dialog,
                category: item.id,
              }
            "
            ><v-icon>{{ icons.mdiChartLine }}</v-icon></v-btn
          >
        </template>
        <template v-slot:item.category="{ item }">
          <div class="truncateCell" :title="item.category">
            {{ item.category }}
          </div>
        </template>
        <template v-slot:item.ca="{ item }">
          <div :title="item.caByChannel">
            {{ item.ca }}
          </div>
        </template>
        <template v-slot:item.sessions="{ item }">
          <div :title="item.sessionsByChannel">
            {{ item.sessions }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <progress-bar :download="download" />
    <v-dialog v-model="dialogModel.dialog" width="70vw">
      <category-performances-modal
        :dialogOpen="dialogModel.dialog"
        :category="dialogModel.category"
        :startDate="this.startDate"
        :endDate="this.endDate"
        :periodType="this.periodType"
      />
    </v-dialog>
  </v-card>
</template>

<style scoped>
.truncateCell {
  max-height: 80px;
  overflow: hidden;
}
</style>

<script>
import debounce from "lodash/debounce";
import { mdiChartLine } from "@mdi/js";

import DotMenu from "@/components/common/menus/DotMenu";
import ProgressBar, {
  download_initial_state,
} from "@/components/common/ProgressBar";
import CategoryPerformancesModal from "@/components/audience/perf-per-category/modals/CategoryPerformances";

import useAxios from "@/hooks/useAxios";
import { runParallelAsyncs } from "@/utils/async";
import { formatCurrency, formatNumber } from "@/utils/formatting";

export default {
  name: "PerfPerCategory",
  components: {
    CategoryPerformancesModal,
    DotMenu,
    ProgressBar,
  },
  data() {
    return {
      optionsInitialized: false,
      forceCall: false,
      icons: {
        mdiChartLine,
      },
      numberOfPages: 0,
      total: 0,
      items: [],
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      dialogModel: {
        dialog: false,
        category: "",
      },
      excelData: new Blob([""], { type: "text/plain" }),
      download: download_initial_state(),
      sortBy: "ca",
      headers: [
        {
          text: "",
          value: "id",
          sortable: false,
        },
        {
          text: "Catégorie",
          value: "category",
        },
        {
          text: "CA",
          value: "ca",
        },
        {
          text: "Sessions",
          value: "sessions",
        },
        {
          text: "RPM",
          value: "rpm",
        },
        {
          text: "Sess. / j",
          value: "sessions_per_day",
        },
      ],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    performances_url_params(forExport = false) {
      return {
        start_date: this.startDate,
        end_date: this.endDate,
        period_type: this.periodType,
        sites: this.sites.join(","),
        categories: this.categories.join(","),
        to_exclude: this.excludeCategories,
        channels: this.channels.join(","),
        ...(forExport
          ? {}
          : {
              page: Math.max(this.options.page, 1),
              limit: this.options.itemsPerPage,
            }),
        sort_by: this.options.sortBy?.[0] ?? "ca",
        sort_desc: this.options.sortDesc?.[0] ?? true,
      };
    },
    formatResult(data) {
      return data.map((item) => {
        const channelDetails = JSON.parse(item.channel_details);
        const caByChannel = Object.entries(channelDetails)
          .map(([key, value]) => `${key} : ${formatCurrency(value.ca)}`)
          .join(", ");
        const sessionsByChannel = Object.entries(channelDetails)
          .map(([key, value]) => `${key} : ${formatNumber(value.sessions)}`)
          .join(", ");

        return {
          ...item,
          id: item.category,
          ca: formatCurrency(item.ca),
          rpm: formatCurrency(item.rpm),
          sessions: formatNumber(item.sessions),
          sessions_per_day: formatNumber(item.sessions_per_day),
          caByChannel,
          sessionsByChannel,
        };
      });
    },
    checkDates() {
      if (
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10
      ) {
        alert("Sélectionnez une plage de date !");
        return false;
      }

      return true;
    },
    async exportExcel() {
      if (!this.checkDates()) {
        return;
      }

      this.loading = true;
      this.download.running = true;
      const { data } = await this.axiosGet(
        "/adn-post/categories/performances/export_xlsx",
        this.performances_url_params(true),
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            this.download.percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );

      this.excelData = data;
      this.loading = false;
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if ((this.applyFilters || this.forceCall) && !this.loading) {
        if (!this.checkDates()) {
          return;
        }

        this.loading = true;
        this.$store.dispatch("audience/updateStatsForPeriod", {});
        const {
          performancesResponse: {
            data: { total, items },
          },
          statsResponse: { data: statsData },
        } = await runParallelAsyncs({
          performancesResponse: this.axiosGet(
            "/adn-post/categories/performances",
            this.performances_url_params()
          ),
          statsResponse: this.axiosGet("/audience/stats", {
            start_date: this.startDate,
            end_date: this.endDate,
            sites: this.sites.join(","),
            period_type: this.periodType,
            categories: this.categories.join(","),
            to_exclude: this.excludeCategories,
            channels: this.channels.join(","),
          }),
        });

        this.$store.dispatch("audience/updateStatsForPeriod", statsData);

        this.items = this.formatResult(items);
        this.total = total;
        this.numberOfPages = this.total / this.options.itemsPerPage;

        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }

      this.forceCall = false;
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    categories() {
      return this.$store.getters["audience/getCategories"];
    },
    excludeCategories() {
      return this.$store.getters["audience/getExcludeCategories"];
    },
    channels() {
      return this.$store.getters["audience/getChannels"];
    },
    sites() {
      return this.$store.getters["audience/getSites"];
    },
    startDate() {
      return this.$store.getters["audience/getDates"][0];
    },
    endDate() {
      return this.$store.getters["audience/getDates"][1];
    },
    periodType() {
      return this.$store.getters["audience/getPeriodType"];
    },
    siteList() {
      return this.$store.getters["common/getSiteList"];
    },
    exportDisabled() {
      return this.loading || this.items.length === 0;
    },
  },
  watch: {
    options() {
      if (this.optionsInitialized) {
        // Not on initialization : wait for a real user change
        this.forceCall = true;
      }

      this.optionsInitialized = true;
      this.debouncedGetData();
    },
    applyFilters() {
      this.debouncedGetData();
    },
    ["download.percentCompleted"]() {
      if (this.download.percentCompleted >= 100) {
        setTimeout(() => {
          this.download = download_initial_state();
        }, 500);
      }
    },
  },
};
</script>

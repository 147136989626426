var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pt-3 pb-3 mb-3"},[_c('v-row',[_c('v-card-title',[_c('h2',{staticClass:"text-xl"},[_vm._v("Suivi des Tendances")])])],1),_c('v-row',[_c('v-card-subtitle',[_c('p',[_vm._v(" Actualisation toutes les 30 min -- Dernière actualisation : "+_vm._s(this.updated_at)+" ")])])],1),_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"ml-6 mr-6",attrs:{"append-icon":"mdi-magnify","label":"Recherche (par Tendance, Composition)","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"ml-6 mr-6",attrs:{"items":_vm.trendItems,"headers":_vm.trendHeaders,"options":_vm.options,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"server-items-length":_vm.options.total,"page":_vm.options.page,"pageCount":_vm.numberOfPages},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.trendHeaders),function(header){return {key:("header." + (header.value)),fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.tooltip))])])]}}}),{key:"item.trend_url",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"cursor-pointer",attrs:{"small":""},on:{"click":function($event){return _vm.openUrl(item.trend_url)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiOpenInNew)+" ")])]}}],null,true)})],1),_c('v-row',[_c('v-card-title',[_c('h3',{staticClass:"text-xl mb-6"},[_vm._v("Sujets à Traiter")])])],1),_c('v-row')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
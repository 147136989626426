<template>
  <v-card class="mb-6">
    <v-card-title class="align-start">
      <span class="font-weight-semibold text-base">Statistiques</span>
    </v-card-title>
    <loader v-if="loading" />
    <v-card-text v-else>
      <v-row>
        <v-col cols="6" lg="2" md="4">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiCurrencyEur }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">CA est.</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ estimatedRevenues.value }}</span
                  >
                </template>
                <span>{{ estimatedRevenues.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="6" lg="2" md="4">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiSpeedometer }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Total Links</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ totalLinks.value }}</span
                  >
                </template>
                <span>{{ totalLinks.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="6" lg="2" md="4">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiMonitorEye }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Screen page vue</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ screenPageViews.value }}</span
                  >
                </template>
                <span>{{ screenPageViews.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="6" lg="2" md="4">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiFormatRotate90 }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Sessions</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ totalSessions.value }}</span
                  >
                </template>
                <span>{{ totalSessions.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiSpeedometer,
  mdiMonitorCellphone,
  mdiMonitorEye,
  mdiCurrencyEur,
  mdiFormatRotate90,
} from "@mdi/js";
import { formatNumberCompact, formatCurrencyCompact } from "@/utils/formatting";

import Loader from "@/components/common/Loader";

export default {
  name: "TopStatsCard",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Loader,
  },
  data() {
    return {
      icons: {
        mdiSpeedometer,
        mdiMonitorCellphone,
        mdiMonitorEye,
        mdiCurrencyEur,
        mdiFormatRotate90,
      },
      estimatedRevenues: {
        value: 0,
        raw: 0,
      },
      screenPageViews: {
        value: 0,
        raw: 0,
      },
      totalSessions: {
        value: 0,
        raw: 0,
      },
      totalLinks: {
        value: 0,
        raw: 0,
      },
    };
  },
  computed: {
    statsForPeriod() {
      return this.$store.getters["linkMessApps/getGlobalStats"];
    },
  },
  watch: {
    statsForPeriod() {
      this.totalLinks.raw = this.statsForPeriod.total_articles;
      this.totalLinks.value = formatNumberCompact(this.totalLinks.raw);
      this.totalSessions.raw = this.statsForPeriod.total_sessions;
      this.totalSessions.value = formatNumberCompact(this.totalSessions.raw);
      this.screenPageViews.raw = this.statsForPeriod.total_screen_page_views;
      this.screenPageViews.value = formatNumberCompact(
        this.screenPageViews.raw
      );
      this.estimatedRevenues.raw = this.statsForPeriod.total_estimated_revenue;
      this.estimatedRevenues.value = formatCurrencyCompact(
        this.estimatedRevenues.raw
      );
    },
  },
};
</script>

<style scoped></style>

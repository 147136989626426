<template>
  <v-card>
    <v-card-title class="align-start">
      <v-spacer />
      <dot-menu
        :isLoading="loading"
        @exportExcel="exportExcel"
        :excelButton="{
          excelData,
          filename: `${this.config.export_file_name}`,
          disabled: exportDisabled,
        }"
      >
        <template v-slot:extraButtons>
          <v-list-item link @click.native="exportConfig">
            <v-list-item-title>Export config</v-list-item-title>
          </v-list-item>
        </template>
      </dot-menu>
    </v-card-title>
    <v-card-text v-if="errorMessage" class="text-center">
      <div class="error--text">
        {{ errorMessage }}
      </div>
      <span>Plus de détails dans la console de développement</span>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        disable-sort
        hide-default-footer
        disable-pagination
        disable-filtering
        :loading="loading"
        class="elevation-1"
      >
      </v-data-table>
    </v-card-text>
    <progress-bar :download="download" />
  </v-card>
</template>

<style scoped></style>

<script>
import debounce from "lodash/debounce";

import DotMenu from "@/components/common/menus/DotMenu";
import ProgressBar, {
  download_initial_state,
} from "@/components/common/ProgressBar";

import useAxios from "@/hooks/useAxios";
import { formatNumber, ucfirst } from "@/utils/formatting";

export default {
  name: "Consolidation",
  components: {
    DotMenu,
    ProgressBar,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    applyFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      errorMessage: "",
      excelData: new Blob([""], { type: "text/plain" }),
      download: download_initial_state(),
      headers: [
        {
          text: "Mois",
          value: "Mois",
        },
        {
          text: "Zone",
          value: "Zone",
        },
        {
          text: "Site",
          value: "Site",
        },
        {
          text: "Marque",
          value: "Marque",
        },
        {
          text: "Partenaire",
          value: "Partenaire_opérationnel",
        },
        {
          text: "Nature",
          value: "Nature",
        },
        {
          text: "Partner (CDG)",
          value: "Partner (CDG)",
        },
        {
          text: "Entité qui encaisse",
          value: "Entité qui encaisse",
        },
        {
          text: "CA",
          value: "CA",
        },
      ],
    };
  },
  setup() {
    const { axiosPost } = useAxios();

    return {
      axiosPost,
    };
  },
  methods: {
    formatResult(data) {
      return data.map((item) => ({
        ...item,
        CA: formatNumber(item.CA),
      }));
    },
    async exportExcel() {
      this.download.running = true;
      const { data } = await this.axiosPost(
        "/finance/consolidation/export",
        {
          config_json: JSON.stringify(this.config),
        },
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            this.download.percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );

      this.excelData = data;
    },
    exportConfig() {
      const downloadLink = document.createElement("a");
      const blob = new Blob([JSON.stringify(this.config)], {
        type: "application/json",
      });
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "config_conso_financiere.json";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      this.errorMessage = "";

      if (this.applyFilters && !this.loading) {
        this.loading = true;
        try {
          const { data } = await this.axiosPost("/finance/consolidation", {
            config_json: JSON.stringify(this.config),
          });

          this.items = this.formatResult(data);
        } catch (error) {
          console.error(
            "axios, POST: /finance/consolidation",
            this.config,
            error.response
          );

          this.errorMessage = ucfirst(error.response.data.detail);
        }

        this.loading = false;
      }

      this.$emit("reloaded");
    },
  },
  computed: {
    exportDisabled() {
      return this.loading || this.items.length === 0;
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    ["download.percentCompleted"]() {
      if (this.download.percentCompleted >= 100) {
        setTimeout(() => {
          this.download = download_initial_state();
        }, 500);
      }
    },
  },
};
</script>

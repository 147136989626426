<template>
  <v-card :outlined="outlined" :class="classNames">
    <v-card-title v-if="title">
      {{ title }}
      <dag-info :dags="dags" />
    </v-card-title>
    <v-card-subtitle v-if="subTitle">{{ subTitle }}</v-card-subtitle>
    <v-card-text>
      <loader v-if="loading" />
      <v-container v-else-if="labels.length === 0" class="text-center">
        {{ noDataText }}
      </v-container>
      <vue-apex-charts
        v-show="!loading && labels.length > 0"
        type="donut"
        :height="height"
        :options="chartOptions"
        :series="series"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import { chartColors, noDataText } from "@/utils/constants";

import DagInfo from "@/components/common/menus/DagInfo";
import Loader from "@/components/common/Loader";

export default {
  name: "PieChart",
  components: {
    DagInfo,
    Loader,
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    classNames: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "355",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    series: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    formatter: {
      type: Function,
      default: (val) => val,
    },
    colors: {
      type: Array,
      default: () => chartColors,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      noDataText,
      chartOptions: {
        legend: {
          show: true,
          position: "bottom",
          fontSize: "14px",
          fontFamily: '"Inter", sans-serif',
        },
        colors: this.colors,
        dataLabels: {
          enabled: true,
        },
        series: [],
        labels: [],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: "2rem",
                  fontFamily: '"Inter", sans-serif',
                },
                value: {
                  fontSize: "1rem",
                  fontFamily: '"Inter", sans-serif',
                  formatter: this.formatter,
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: "1.5rem",
                      },
                      value: {
                        fontSize: "1rem",
                      },
                      total: {
                        fontSize: "1.5rem",
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    updateOptions() {
      this.chartOptions = {
        ...this.chartOptions,
        series: this.series,
        labels: this.labels,
      };
    },
  },
  watch: {
    series() {
      this.updateOptions();
    },
    labels() {
      this.updateOptions();
    },
  },
};
</script>

<template>
  <div class="pa-6 ma-4">
    <div>
      <p class="text-2xl mb-6">
        CA journalier par partenaire
        <dag-info
          :dags="[
            'partner_get_and_store_daily_dag',
            'partner_get_and_store_daily_ifempty_dag',
          ]"
        />
      </p>
      <v-expansion-panels class="mb-2" id="expandableFilters">
        <v-expansion-panel>
          <v-expansion-panel-header> </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="ml-3">
              <v-col cols="6" sm="4" md="3">
                <partner-group-filter store="premium" />
              </v-col>
              <v-col cols="6" sm="4" md="6">
                <partner-view-filter store="premium" />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card class="mb-0 pt-2">
        <v-row class="mt-3 mb-0 d-flex">
          <v-col cols="5" class="mb-0 pb-0">
            <!-- <date-filter
                  store="premium"
                  storeGetter="getMonthDate"
                  storeUpdater="updateMonthDate"
                  format="month"
                  :isRange="false"
                /> -->
            <date-range-filter
              @valueChanged="setDates"
              @frequencyChanged="setFrequency"
              periodType="past-and-today"
              format="date"
              :frequencies="['daily', 'weekly', 'monthly']"
              :frequency="this.frequency"
              range="CURRENT_MONTH"
              :onCreatedEmitDates="true"
              :outlined="true"
              :allowNoLimit="false"
              wrapperClass="date-range-filter"
              :showOpenDialogBtn="false"
              class="mb-0 pb-0"
              :showBtnList="['PREVIOUS_MONTH', 'CURRENT_MONTH']"
            ></date-range-filter>
          </v-col>
          <v-col cols="2">
            <site-group-filter store="premium" :dense="true" label="Univers" />
          </v-col>
          <v-col cols="3">
            <site-filter store="premium" :dense="true" />
          </v-col>
          <v-col cols="2">
            <v-btn @click="confirmApplyFilters" outlined color="primary">
              APPLIQUER
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-row v-show="componentKey > 0">
      <v-col cols="12">
        <ca-by-partner-view-one-month-separated-chart
          v-if="confirmedFilters.monthDate"
          :key="componentKey"
          :month="confirmedFilters.monthDate"
          :partnersViews="confirmedFilters.partnerViews"
          :sites="confirmedFilters.sites"
        ></ca-by-partner-view-one-month-separated-chart>
        <ca-by-partner-view-date-range-separated-chart
          v-else
          :key="componentKey"
          :dates="confirmedFilters.dates"
          :frequency="confirmedFilters.frequency"
        ></ca-by-partner-view-date-range-separated-chart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DagInfo from "@/components/common/menus/DagInfo";
import PartnerGroupFilter from "@/components/common/filters/PartnerViewGroupFilter";
import PartnerViewFilter from "@/components/common/filters/PartnerViewFilter.vue";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";
import DateRangeFilter from "@/components/common/filters/DateRangeFilter.vue";
import CaByPartnerViewOneMonthSeparatedChart from "@/components/premium/ca-by-partner/cards/CaByPartnerViewOneMonthSeparatedChart";
import CaByPartnerViewDateRangeSeparatedChart from "@/components/premium/ca-by-partner/cards/CaByPartnerViewDateRangeSeparatedChart";
import store from "@/store";
import {
  getDateStr,
  getDateDetails,
  getStartOfMonth,
  getEndOfMonth,
  daysNumberBetween,
} from "@/utils/dates";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "CaByPartnerView",
  components: {
    CaByPartnerViewDateRangeSeparatedChart,
    CaByPartnerViewOneMonthSeparatedChart,
    DagInfo,
    DateRangeFilter,
    PartnerGroupFilter,
    PartnerViewFilter,
    SiteFilter,
    SiteGroupFilter,
  },
  mounted() {
    saveRmraView(this.$route);
  },
  data() {
    return {
      componentKey: 0,
      confirmedFilters: {
        monthDate: null,
        dates: this.dates,
        frequency: this.frequency,
        // sites: null,
        // partnerViews: null,
      },
      // sites: [], // for tests
      dailyWarningTreshold: 180,
      applyedFilters: {},
      componentKey: 0,
      usePrettyNumbers: true,
      loading: false,
      frequency: "daily",
      dates: [],
      monthDate: null,
    };
  },
  async created() {
    if (this.$route.query.siteGroups) {
      const siteGroups = this.$route.query.siteGroups.split(",");
      await this.$store.dispatch("premium/updateSiteGroups", siteGroups);
    }
    if (this.$route.query.partnerViews) {
      const partnerViews = this.$route.query.partnerViews.split(",");
      await this.$store.dispatch("premium/updatePartnerViews", partnerViews);
    }
  },
  methods: {
    setDates(dates) {
      console.log("CaAndAudienceBySite DateRangeFilter $emit setDates", dates);
      this.dates = dates;
      this.monthDate = null;

      const [date1, date2] = this.dates;
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);

      // Step 1: Get month details
      const date1Details = getDateDetails(new Date(date1));
      const date2Details = getDateDetails(new Date(date2));
      const currentMonth = getDateDetails(today).month;
      const currentYear = getDateDetails(today).year;

      // Step 2: Check if both dates are in the same month
      if (
        date1Details.month === date2Details.month &&
        date1Details.year === date2Details.year
      ) {
        // Step 3: Get the first and last day of the month
        const startOfMonth = getStartOfMonth(date1);
        const endOfMonth = getEndOfMonth(date1);

        // Step 4: Check the conditions
        if (
          // Condition 1: date1 is the first day and date2 is the last day of the same month
          (date1 === startOfMonth && date2 === endOfMonth) ||
          // Condition 2: date1 is the first day and date2 is today or yesterday of the current month
          (date1 === startOfMonth &&
            date1Details.month === currentMonth &&
            date1Details.year === currentYear &&
            (date2 === getDateStr(today) || date2 === getDateStr(yesterday)))
        ) {
          console.log("CaByPartnerView setDates is One month");
          this.monthDate = `${date1Details.year}-${date1Details.month}`; // Set monthDate
        } else {
          console.log("CaByPartnerView setDates is NOT One month 2");
        }
      } else {
        console.log("CaByPartnerView setDates is NOT One month 1");
      }
      console.log(
        "CaByPartnerView setDates this.monthDate is done:",
        this.monthDate
      );
    },
    setFrequency(frequencyObj) {
      console.log("setFrequency(frequencyObj)", frequencyObj);
      this.frequency = frequencyObj.name;
    },
    async confirmApplyFilters() {
      console.log("confirmApplyFilters");
      console.log("this.sites", this.sites);

      // check  sites
      // if (this.sites.length < 1) {
      //   await store.dispatch("app/setSnackBar", {
      //     show: true,
      //     text: `Veuillez sélectionner au moins un site.`,
      //     color: "warning",
      //     timeout: 2000,
      //   });
      //   return;
      // }
      // check frequency and number of days
      if (this.dates.length !== 2) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Veuillez sélectionner une période entre 2 dates.`,
          color: "warning",
          timeout: 2000,
        });
        return;
      }
      const daysNumber = daysNumberBetween(this.dates[0], this.dates[1]);
      console.log("daysNumber", daysNumber);
      console.log("this.frequency", this.frequency);
      if (this.frequency == "daily" && daysNumber > this.dailyWarningTreshold) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `La période sélectionnée excède ${this.dailyWarningTreshold} jours alors
            la périodicité passe automatiquement de "daily" à "weekly" afin d'assurer un affichage correct du graphique.`,
          color: "info",
          timeout: 5000,
        });
        console.warn(
          `in daily ${daysNumber} jours > dailyWarningTreshold ${this.dailyWarningTreshold} then changed to "weekly"`
        );
        this.frequency = "weekly";
        this.applyFilters();
        return;
      }
      // await store.dispatch("app/setDialog", {
      //   show: true,
      //   type: "confirm",
      //   width: 800,
      //   title: "Appliquer",
      //   text: `Vous souhaitez afficher ${daysNumber} jours en pèriodicité "daily".
      //         Cela risque d'être très long.
      //         Il est préférable d'annuler et sélectionner une périodicité "Weekly" ou "monthly".
      //         ou bien un intervalle inférieur à ${this.dailyWarningTreshold} jours.`,
      //   code: null,
      //   callbackFunction: this.applyFilters,
      //   //callbackFunction: () => { this.applyFilters(); },
      // });
      this.applyFilters();
    },
    applyFilters() {
      console.log("applyFilters");
      this.confirmedFilters.monthDate = this.monthDate;
      setTimeout(() => {
        // bug if not in settimeout ...
        this.confirmedFilters = {
          dates: this.dates,
          frequency: this.frequency,
          monthDate: this.monthDate,
          sites: this.sites,
          partnerViews: this.partnerViews,
        };
        console.log(
          "applyFilters this.confirmedFilters",
          this.confirmedFilters
        );
        this.componentKey += 1;
      }, 100);
    },
    resetFilters() {
      this.$store.dispatch("premium/updateSiteGroups", []);
      this.$store.dispatch("premium/updatePartnerViewGroups", [9]);
    },
  },
  computed: {
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    // monthDate() {
    //   return this.$store.getters["premium/getMonthDate"];
    // },
    partnerViews() {
      return this.$store.getters["premium/getPartnerViews"];
    },
  },
};
</script>
<style>
.v-input__control {
  padding-bottom: 0 !important;
}
.app-content-container.mx-auto.h-full.app-content-container-boxed {
  max-width: 100%;
}
</style>

const rmrUiToken = "52894305a7a944f6890c616e0a9c721a";
const isProd = window.location.hostname.includes("reworldmediafactory");
const rmrAnalyticsPath = isProd
  ? "https://analytics.rmr.reworldmediafactory.com/event"
  : "http://localhost:3000/event";

const saveRmraEvent = (eventType, eventCategory) => {
  const abilities = JSON.parse(localStorage.getItem("userAbility"));
  if (
    abilities &&
    abilities.some((ability) => ability.subject === "no_logging")
  ) {
    return;
  }

  fetch(rmrAnalyticsPath, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: rmrUiToken,
      eventType: eventType,
      eventCategory: eventCategory,
    }),
  });
};

export const saveRmraView = (route) => {
  if (route.meta.rmrAnalytics && route.meta.rmrAnalytics.track) {
    saveRmraEvent(
      route.meta.rmrAnalytics.eventType,
      route.meta.rmrAnalytics.eventCategory
    );
  }
};

export const saveRmraCustomEvent = (route, eventCategory) => {
  if (route.meta.rmrAnalytics && route.meta.rmrAnalytics.track) {
    saveRmraEvent(route.meta.rmrAnalytics.eventType, eventCategory);
  }
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"pdfContainer",staticClass:"pdfContainer"},[(_vm.logo)?_c(_vm.logo,{tag:"component"}):_vm._e(),_c('p',{staticClass:"text-2xl mb-6"},[_vm._v(" Performance par article "),_c('dag-info',{attrs:{"dags":[
        'adn_daily_posts_sites_infos_dag',
        'ga_get_and_store_site_daily_dag',
        'partner_get_and_store_daily_dag',
        'partner_get_and_store_daily_ifempty_dag',
        'adn_get_and_store_posts_ga_daily_dag' ]}})],1),_c('v-card',{staticClass:"mb-6 pa-2",attrs:{"outlined":""}},[_c('v-row',{staticClass:"ma-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('filters')],1)],1)],1),(['audience'].includes(_vm.department))?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('top-stats-card')],1)],1):_vm._e(),(['audience'].includes(_vm.department))?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('top-articles-chart')],1)],1):_vm._e(),(['audience'].includes(_vm.department))?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ca-chart')],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ca-distribution')],1)],1):_vm._e(),(['audience'].includes(_vm.department))?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('stats-by-date-chart')],1),_c('v-col',{attrs:{"cols":"6"}},[_c('stats-by-sessions-chart')],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('perf-per-article-table',{attrs:{"department":_vm.department}})],1)],1),_c('dot-menu',{attrs:{"position":"page-top-right","isLoading":_vm.isLoading,"pdfButton":{
      getContainerRef: function () { return _vm.$refs.pdfContainer; },
      filename: 'perf_par_article.pdf',
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
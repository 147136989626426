<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="5">
        <date-filter store="linkMessApps"></date-filter>
      </v-col>
      <v-col cols="3">
        <v-select
          outlined
          v-model="periodType"
          :items="periodTypeList"
          :menu-props="{ maxHeight: '400', zIndex: 13 }"
          label="Pour la période"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col cols="2" class="d-flex">
        <v-spacer />
        <apply-filters :disabled="isLoading" outlined color="secondary" />
      </v-col>

      <v-col cols="6">
        <site-filter store="linkMessApps" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter";
import SiteFilter from "@/components/common/filters/SiteFilter";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    DateFilter,
    SiteFilter,
  },
  data() {
    return {
      periodTypeList: [
        { id: "articles_seen", name: "Articles vus" },
        { id: "articles_created", name: "Articles créés" },
      ],
    };
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  methods: {
    validatePeriodType(periodType) {
      const allowedValues = this.periodTypeList.map((entry) => entry.id);

      return allowedValues.includes(periodType) ? periodType : "articles_seen";
    },
  },
  computed: {
    periodType: {
      get() {
        const storedValue = this.$store.getters["linkMessApps/getPeriodType"];
        const value = this.validatePeriodType(storedValue);
        if (value !== storedValue) {
          this.$store.dispatch("linkMessApps/updatePeriodType", value);
        }

        return value;
      },
      set(e) {
        this.$store.dispatch(
          "linkMessApps/updatePeriodType",
          this.validatePeriodType(e)
        );
      },
    },
  },
};
</script>

<style></style>

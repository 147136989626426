<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  computed,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
} from "@vue/composition-api";
// eslint-disable-next-line import/no-unresolved
import useAppConfig from "@core/@app-config/useAppConfig";
import { useRouter } from "@core/utils";
import { useLayout } from "@core/layouts/composable/useLayout";

// Layouts
import LayoutContentVerticalNav from "@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue";
import LayoutContentHorizontalNav from "@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue";
import LayoutBlank from "@/layouts/variants/blank/LayoutBlank.vue";

// Dynamic vh
import useDynamicVh from "@core/utils/useDynamicVh";

import { log } from "@/utils/formatting";

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = proxy.$store;
    const { route } = useRouter();
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } =
      useAppConfig();

    const { handleBreakpointLayoutSwitch } = useLayout();
    handleBreakpointLayoutSwitch();

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === "blank") return "layout-blank";
      if (route.value.meta.layout === "content")
        return `layout-content-${appContentLayoutNav.value}-nav`;

      return null;
    });

    useDynamicVh();

    // Start SSE integration
    log("process.env", process.env);
    if (process.env?.VUE_APP_USE_SSE == 1) {
      log("Use SSE");
      const connectSSE = () => {
        const eventSource = new EventSource(
          `${process.env.VUE_APP_API_URL}events`
        );
        eventSource.onmessage = (event) => {
          const data = JSON.parse(event.data);
          log("SSE Message data:", data);
          if (data.message === "updated-last-meps") {
            eventSource.close();
            store.dispatch(
              "common/updateLastMepTime",
              new Date().toISOString().slice(0, -5)
            );
            setTimeout(() => {
              log("Reconnecting to SSE...");
              connectSSE();
            }, 60000); // reconnect after 60sec
          }
          // else if (data.message === "heartbeat") { log("heartbeat"); }
        };
        eventSource.onerror = (error) => {
          console.error("SSE Error:", error);
          eventSource.close();
          setTimeout(() => {
            log("Reconnecting to SSE...");
            connectSSE();
          }, 3000); // Reconnect after a delay of 3 seconds
        };
        return eventSource;
      };
      let eventSource;
      onMounted(() => {
        eventSource = connectSSE();
      });
      onBeforeUnmount(() => {
        if (eventSource) {
          eventSource.close();
        }
      });
    } else {
      log("Do not use SSE");
    }

    // End SSE integration

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    };
  },
  created() {
    this.$store.dispatch("common/collectAirflowDagList");
  },
};
</script>

<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">CHANGELOG</p>
    </div>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col v-show="canEdit" cols="3">
              <v-text-field
                v-model="searchUser"
                label="Rechercher un user"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                label="Rechercher un comment"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="data.items.length > 0">
            <v-data-table
              locale="fr-FR"
              :page="options.page"
              :pageCount="pagesNumber"
              :headers="headers.items"
              :items="data.items"
              v-model="selected.items"
              :sort-by="'time'"
              :sort-desc="true"
              :options.sync="options"
              @update:options="updateOptions"
              :server-items-length="data.total"
              :loading="loading"
              class="elevation-1"
              :footer-props="{
                'items-per-page-options': [10, 25, 50],
              }"
              :items-per-page="options.itemsPerPage"
              dense
            >
              <template v-if="canEdit" v-slot:item.comment="props">
                <v-edit-dialog
                  :return-value.sync="props.item.comment"
                  @save="saveComment(props.item)"
                  cancel="cancel"
                  open="open"
                  close="close"
                >
                  {{ props.item.comment }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.comment"
                      label="Edit"
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-if="canEdit" v-slot:item.secret="props">
                <v-edit-dialog
                  :return-value.sync="props.item.secret"
                  @save="saveSecret(props.item)"
                  cancel="cancel"
                  open="open"
                  close="close"
                >
                  {{ props.item.secret }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.secret"
                      label="Edit"
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
            </v-data-table>
          </v-row>
          <v-row v-else>
            <div v-if="loading">
              <v-progress-linear
                indeterminate
              ></v-progress-linear>
            </div>
            <div v-else>Pas de résultats</div>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {
  ref,
  reactive,
  onMounted,
  watch,
  getCurrentInstance,
} from "@vue/composition-api";
import useAxios from "@/hooks/useAxios";
import { mdiPencil } from "@mdi/js";
import { NOTIF_DISPLAY_DURATION } from "@/utils/constants";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "Changelog",
  setup() {
    const instance = getCurrentInstance();
    const route = instance.proxy.$route;
    console.log("Changelog route", route);
    const { axiosGet, axiosPut } = useAxios();

    const data = reactive({ total: 0, items: [] });
    const selected = reactive({ items: [] });
    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["time"],
      sortDesc: [true],
    });
    const pagesNumber = ref(0);
    const search = ref("");
    const searchUser = ref("");
    const loading = ref(false);
    const optionsUpdatedByHuman = ref(true);
    const canEdit = ref(false);

    const saveComment = async (item) => {
      try {
        const response = await axiosPut(`/meps/${item.id}`, item);
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Commentaire modifié`,
          color: "success",
          timeout: NOTIF_DISPLAY_DURATION,
        });
      } catch (error) {
        console.error(error);
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Une erreur est survenue.`,
          color: "error",
          timeout: NOTIF_DISPLAY_DURATION,
        });
        fetchData();
      }
    };
    const saveSecret = async (item) => {
      try {
        if(item.secret != "0" && item.secret != "1"){
          await store.dispatch("app/setSnackBar", {
            show: true,
            text: `Le champ secret ne peut-être que 0 ou 1`,
            color: "error",
            timeout: NOTIF_DISPLAY_DURATION,
          });
          fetchData();
          return
        }
        const response = await axiosPut(`/meps/${item.id}`, item);
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Commentaire modifié`,
          color: "success",
          timeout: NOTIF_DISPLAY_DURATION,
        });
      } catch (error) {
        console.error(error);
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Une erreur est survenue.`,
          color: "error",
          timeout: NOTIF_DISPLAY_DURATION,
        });
        fetchData();
      }
    };

    const fetchData = async () => {
      if (!optionsUpdatedByHuman.value) {
        // to avoid a instant double fetchData
        optionsUpdatedByHuman.value = true;
        return;
      }
      loading.value = true;
      const response = await axiosGet("/meps/", {
        search: search.value,
        search_user: searchUser.value,
        page: options.page,
        limit: options.itemsPerPage,
        ...(options.sortBy &&
        options.sortDesc &&
        options.sortBy.length > 0 &&
        options.sortDesc.length > 0
          ? {
              sort_by: options.sortBy[0],
              sort_desc: options.sortDesc[0],
            }
          : {}),
      });
      data.total = response.data.total;
      data.items = response.data.items;
      pagesNumber.value = data.total / options.itemsPerPage;
      loading.value = false;

      if (response.data.items && response.data.items.length > 0) {
        if ("secret" in response.data.items[0]) {
          canEdit.value = true;
        } else {
          // Remove 'User' and 'Secret' from headers
          canEdit.value = false;
          headers.items = headers.items.filter(
            (header) => header.value !== "user" && header.value !== "secret"
          );
        }
      }
    };

    const updateOptions = (newOptions) => {
      options.page = newOptions.page;
      options.itemsPerPage = newOptions.itemsPerPage;
      options.sortBy = newOptions.sortBy;
      options.sortDesc = newOptions.sortDesc;
      fetchData();
    };

    onMounted(async () => {
      await fetchData();
      saveRmraView(route);
    });

    // // Watch for pagination or itemsPerPage changes
    watch(
      () => search.value,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          fetchData();
        }
      }
    );

    watch(
      () => searchUser.value,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          fetchData();
        }
      }
    );

    /*
    id: int
    user: Optional[str] = ""
    app_id: int
    cat_ids: Optional[str] = ""
    comment: Optional[str] = ""
    sec   */
    const headers = reactive({
      items: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        {
          text: "User",
          value: "user",
        },
        {
          text: "App",
          value: "app_name",
        },
        {
          text: "Types",
          value: "cat_names",
        },
        {
          text: "Comment",
          value: "comment",
        },
        {
          text: "secret",
          value: "secret",
        },
        {
          text: "time",
          value: "time",
        },
        {
          value: "actions",
        },
      ],
    });

    return {
      data,
      options,
      updateOptions,
      search,
      searchUser,
      pagesNumber,
      headers,
      selected,
      loading,
      canEdit,
      saveComment,
      saveSecret,
      icons: {
        mdiPencil,
      },
    };
  },
};
</script>

<style scoped></style>

<template>
  <v-card :outlined="outlined" id="topIngredients">
    <v-card-title class="align-start">
      <v-row>
        <v-col cols="12">
          Top ingrédients
          <dag-info
            :dags="[
              'get_and_store_posts_marmiton_ga_daily_dag',
              'adn_daily_posts_sites_infos_dag',
            ]"
          />
        </v-col>
      </v-row>
      <dot-menu
        :isLoading="loading"
        @exportExcel="exportExcel"
        :excelButton="{
          excelData,
          filename: `top-ingredients-${this.startDate}_${this.endDate}.xlsx`,
          disabled: exportDisabled,
        }"
      />
    </v-card-title>
    <v-card-text class="align-start" v-if="useMarmitonFilters">
      <ingredient-filter
        class="mr-4"
        label="Ingrédients à exclure"
        storeGetter="getMarmitonTopIngredientsToExclude"
        storeUpdater="updateMarmitonTopIngredientsToExclude"
        :toExclude="true"
        anchor="topIngredients"
      />
    </v-card-text>
    <v-card-text>
      <v-data-table
        :disable-filtering="true"
        :disable-sort="true"
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :class="classname"
        :page="options.page"
        :pageCount="numberOfPages"
        :server-items-length="total"
        :items-per-page="options.itemsPerPage"
      >
      </v-data-table>
    </v-card-text>

    <progress-bar :download="download" />
  </v-card>
</template>

<style></style>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";
import { formatCurrency, formatNumber } from "@/utils/formatting";

import DagInfo from "@/components/common/menus/DagInfo";
import DotMenu from "@/components/common/menus/DotMenu";
import ProgressBar, {
  download_initial_state,
} from "@/components/common/ProgressBar";
import IngredientFilter from "@/components/regie/common/filters/IngredientFilter";
import { ingredientListFromAutoCompleteValues } from "@/components/regie/common/utils/ingredientGroup.js";
import { extrapolateDatasetWithConsentRatio } from "@/components/regie/marmiton/utils/unapplyConsentRatio";

const defaultOptions = {
  page: 1,
  itemsPerPage: 10,
};

export default {
  name: "TopIngredients",
  components: {
    DagInfo,
    DotMenu,
    IngredientFilter,
    ProgressBar,
  },
  props: {
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    department: {
      type: String,
      default: "regie",
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    classname: {
      type: String,
      default: "",
    },
  },
  data() {
    const canSeeAllData = this.$can("manage", "regie_with_ca");

    return {
      forceCall: false,
      canSeeAllData,
      items: [],
      loading: false,
      optionsInitialized: false,
      numberOfPages: 0,
      total: 0,
      options: { ...defaultOptions },
      excelData: new Blob([""], { type: "text/plain" }),
      download: download_initial_state(),
      headers: [
        {
          text: "Ingrédient",
          align: "start",
          value: "ingredient",
        },
        {
          text: "Nb recettes",
          value: "nb_occurrences",
        },
        {
          text: "Vues",
          value: "views",
        },
        ...(canSeeAllData
          ? [
              {
                text: "Sessions",
                value: "sessions",
              },
              {
                text: "CA",
                value: "ca",
              },
            ]
          : []),
      ],
      sortBy: canSeeAllData ? "ca" : "views",
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    resetData() {
      this.items = [];
      this.numberOfPages = 0;
      this.total = 0;
      this.options = { ...defaultOptions };
    },
    formatResult(data) {
      return data.items.map((item) => {
        item = extrapolateDatasetWithConsentRatio(item);

        return {
          ...item,
          ...(this.canSeeAllData && item?.ca !== undefined
            ? { ca: formatCurrency(item.ca) }
            : {}),
          views: formatNumber(item.views),
          ...(this.canSeeAllData
            ? {
                sessions: formatNumber(item.sessions),
              }
            : {}),
        };
      });
    },
    topIngredientsUrlParams(forExport = false) {
      return {
        start_date: this.startDate,
        end_date: this.endDate,
        ingredients_to_exclude: this.ingredientsToExclude,
        ...(forExport
          ? {}
          : {
              page: Math.max(this.options.page, 1),
              limit: this.options.itemsPerPage,
            }),
      };
    },
    checkDate() {
      if (
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10
      ) {
        alert("Sélectionnez une plage de date !");

        return false;
      }

      return true;
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if ((this.applyFilters || this.forceCall) && !this.loading) {
        if (!this.checkDate()) {
          return;
        }

        this.loading = true;
        const { data } = await this.axiosGet(
          "/regie/marmiton/top-ingredients",
          this.topIngredientsUrlParams()
        );

        this.items = this.formatResult(data);
        this.total = data.total;
        this.numberOfPages = data.total / this.options.itemsPerPage;
        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }

      this.forceCall = false;
    },
    async exportExcel() {
      if (!this.checkDate()) {
        return;
      }

      this.loading = true;
      this.download.running = true;
      const { data } = await this.axiosGet(
        "/regie/marmiton/top-ingredients/export_xlsx",
        this.topIngredientsUrlParams(true),
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            this.download.percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );

      this.excelData = data;
      this.loading = false;
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    ingredientsToExclude() {
      const ingredients = this.useMarmitonFilters
        ? this.$store.getters["marmiton/getMarmitonTopIngredientsToExclude"]
        : this.$store.getters["marmiton/getMarmitonIngredientsToExclude"];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
    useMarmitonFilters() {
      return ["marmiton"].includes(this.department);
    },
    exportDisabled() {
      return this.loading || this.items.length === 0;
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    options() {
      if (this.optionsInitialized) {
        // Not on initialization : wait for a real user change
        this.forceCall = true;
      }

      this.optionsInitialized = true;
      this.debouncedGetData();
    },
    ["download.percentCompleted"]() {
      if (this.download.percentCompleted >= 100) {
        setTimeout(() => {
          this.download = download_initial_state();
        }, 500);
      }
    },
  },
};
</script>

const gTrendsAppsRoute = [
  {
    path: "/gTrends/exploration",
    name: "gTrendsIndex",
    component: () => import("@/views/gtrends/Index"),
    meta: {
      layout: "content",
      resource: "gtrends",
      action: "manage",
      //   rmrAnalytics: {
      //     track: true,
      //     eventType: "programmatique-dashboard",
      //     eventCategory: "page-view",
      //   },
    },
  },
];

export default gTrendsAppsRoute;

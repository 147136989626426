const ctcRoutes = [
  {
    path: "/content-to-commerce/dashboard",
    name: "c2c-dashboard",
    component: () => import("@/views/c2c/Dashboard.vue"),
    meta: {
      layout: "content",
      resource: "ctc",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "ctc-dashboard",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/content-to-commerce/ga-url-list",
    name: "c2c-ga-url-list",
    component: () => import("@/views/c2c/GaUrlList.vue"),
    meta: {
      layout: "content",
      resource: "ctc",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "c2c-ga-url-list",
        eventCategory: "page-view",
      },
    },
  },
];

export default ctcRoutes;

import { mdiTrendingUp } from "@mdi/js";

const LinkMessagingApps = [
  {
    title: "Google Trends",
    icon: mdiTrendingUp,
    children: [
      {
        title: "Suivi des Tendances",
        to: "gTrendsIndex",
        resource: "gtrends",
        action: "manage",
      },
    ],
  },
];

export default LinkMessagingApps;

<template>
  <div>
    <h1 class="text-2xl mb-6">Outil de création de campagne</h1>

    <!-- GENERAL -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 font-weight-bold">
        Général
      </v-card-title>
      <v-row class="mb-3 mt-3 ml-6 mr-6">
        <v-text-field
          class="ml-3 mr-3"
          outlined
          v-model="url"
          label="URL de l'article"
        ></v-text-field>
        <v-select
          class="ml-3 mr-3"
          v-model="categorieSelected"
          :items="categorieOptions"
          label="Catégorie"
        ></v-select>
      </v-row>
      <v-row class="mb-3 mt-3 ml-6 mr-6">
        <v-col cols="12" md="6">
          <v-select
            v-model="deviceSelected"
            :items="deviceOptions"
            label="Device"
            multiple
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="platformSelected"
            :items="platformOptions"
            label="Platform"
            multiple
          ></v-select>
        </v-col>
      </v-row>
    </v-card>
    <!-- GENERAL -->

    <!-- TITLE GENERATION -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 font-weight-bold">
        Génération du titre
        <v-btn
          icon
          class="ml-6"
          color="primary"
          @click="titleGptDialog = !titleGptDialog"
        >
          <v-icon color="primary">{{ icons.mdiPencil }}</v-icon>
        </v-btn>
      </v-card-title>
      <div v-if="showWording">
        <v-row class="mb-3 mt-3 ml-6 mr-6">
          <v-text-field
            class="ml-3 mr-3"
            v-model="title"
            outlined
            label="Title"
          ></v-text-field>
          <v-checkbox v-model="addToTitleList.title"></v-checkbox>
        </v-row>

        <v-row
          v-for="(suggestion, key) in suggestionTitre"
          :key="key"
          class="mb-3 mt-3 ml-6 mr-6"
        >
          <v-text-field
            class="ml-3 mr-3"
            v-model="suggestion.value"
            outlined
            :label="key"
          ></v-text-field>
          <v-text-field
            class="ml-3 mr-3 small-text-field"
            outlined
            :value="suggestion.value.length"
            readonly
            label="Nombre de caractères"
          ></v-text-field>
          <v-checkbox v-model="addToTitleList[key]"></v-checkbox>
        </v-row>
      </div>
      <v-row class="mb-3 mt-3 ml-6 mr-6">
        <v-btn color="primary" @click="getTitleAndVariations"
          >Générer titre et variations</v-btn
        >
        <v-btn v-if="showWording" @click="getTitleVariationIfError"
          >Générer variations avec titre donné</v-btn
        >
      </v-row>
      <v-dialog v-model="titleGptDialog" max-width="700px">
        <v-card>
          <v-card-title class="text-h5 font-weight-bold"
            >Prompt GPT pour les titres</v-card-title
          >
          <v-card-text>
            <v-textarea
              class="ml-3 mr-3"
              v-model="prompt_config.title"
              outlined
              rows="3"
              auto-grow
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="closePromptDialog(false)">Annuler</v-btn>
            <v-btn color="primary" @click="closePromptDialog(true)"
              >Sauvegarder</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <!-- TITLE GENERATION -->

    <!-- DESCRIPTION GENERATION-->
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 font-weight-bold">
        Génération de la description
        <v-btn
          icon
          class="ml-6"
          color="primary"
          @click="DescriptionGptDialog = !DescriptionGptDialog"
        >
          <v-icon color="primary">{{ icons.mdiPencil }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-row
        class="mb-3 mt-3 ml-6 mr-6"
        v-if="showDescription"
        v-for="(text, key) in descriptions"
      >
        <v-textarea
          class="ml-3 mr-3"
          v-model="text.value"
          outlined
          :label="text.text"
        ></v-textarea>
        <v-text-field
          class="ml-3 mr-3 small-text-field"
          outlined
          :value="text.value.length"
          readonly
          label="Nombre de caractères"
        ></v-text-field>
        <v-checkbox v-model="text.selected"></v-checkbox>
      </v-row>
      <v-row class="mb-3 mt-3 ml-6 mr-6">
        <v-btn color="primary" class="mt-3" @click="getDescriptions"
          >Générer description et variations
        </v-btn>
        <v-btn
          class="mt-3"
          v-if="showDescription"
          @click="getDescriptionsVariationIfError"
          >Générer avec description donnée</v-btn
        >
        <v-select
          class="ml-auto mr-3"
          v-if="showDescription"
          v-model="descriptionSelected"
          :items="descriptions"
          item-text="text"
          item-value="value"
          label="sélectionner une description pour Taboola/Outbrain"
          style="max-width: 500px"
        ></v-select>
      </v-row>
      <v-dialog v-model="DescriptionGptDialog" max-width="700px">
        <v-card>
          <v-card-title class="text-h5 font-weight-bold"
            >Prompt GPT pour les descriptions</v-card-title
          >
          <v-card-text>
            <v-textarea
              class="ml-3 mr-3"
              v-model="prompt_config.description"
              outlined
              rows="5"
              auto-grow
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="closePromptDialog(false)">Annuler</v-btn>
            <v-btn color="primary" @click="closePromptDialog(true)"
              >Sauvegarder</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <!-- DESCRIPTION GENERATION-->

    <!-- VISUEL -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 font-weight-bold">Visuel</v-card-title>
      <v-card-subtitle class="ml-3"
        >Nb d'images sélectionnées :
        {{ imagesSelected.length }}</v-card-subtitle
      >

      <v-row>
        <v-col v-for="(image, index) in images" :key="index" cols="6">
          <v-row class="mb-3 mt-3 ml-6 mr-6" align="center">
            <v-checkbox v-model="imagesSelected" :value="image"></v-checkbox>
            <img :src="image" alt="src" style="width: 50%"
          /></v-row>
        </v-col>
      </v-row>
      <v-row class="mb-3 mt-3 ml-6 mr-6">
        <v-btn color="primary" @click="displayImages"
          >Afficher les images du BO</v-btn
        >
      </v-row>
      <v-row class="mb-3 mt-3 ml-6 mr-6" v-if="newImageUrlField">
        <v-text-field
          v-model="newImageUrl"
          placeholder="Ajouter une URL d'image et appuyer sur entrée"
          @keyup.enter="addImageFromUrl"
        ></v-text-field>
      </v-row>
    </v-card>
    <!-- VISUEL -->

    <!-- APERCU -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 font-weight-bold">Apercu</v-card-title>
      <v-row>
        <v-col
          v-for="(pair, index) in allCombinations"
          :key="index"
          cols="12"
          md="6"
        >
          <v-row class="mb-3 mt-3 ml-6 mr-6" align="center">
            <v-checkbox v-model="pair.isSelected"></v-checkbox>
            <img :src="pair.image" style="width: 50%" />
          </v-row>
          <p class="ml-6 mr-6">{{ pair.title }}</p>
        </v-col>
      </v-row>
      <v-row class="mb-3 mt-3 ml-6 mr-6">
        <v-btn color="primary" @click="generateAllCombinations"
          >Afficher toutes les combinaisons</v-btn
        >
      </v-row>
    </v-card>
    <!-- APERCU -->

    <!-- NOMENCLATURE -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 font-weight-bold">
        Nomenclature
      </v-card-title>
      <v-row class="mb-3 mt-3 ml-6 mr-6">
        <v-text-field
          class="ml-3 mr-3"
          outlined
          v-model="site"
          label="Site"
        ></v-text-field>
        <v-text-field
          class="ml-3 mr-3"
          outlined
          v-model="nomenclature"
          label="Nomenclature"
        ></v-text-field>
      </v-row>
      <v-row class="mb-3 mt-3 ml-6 mr-6">
        <v-btn color="primary" @click="getNomenclature"
          >Générer nomenclature</v-btn
        >
      </v-row>
    </v-card>
    <!-- NOMENCLATURE -->

    <!-- PARAMETRAGE PLATEFORMES -->
    <v-row class="mb-3 mt-3">
      <v-col
        v-for="platform in platformOptions"
        :key="platform"
        cols="12"
        md="4"
        v-if="platformSelected.includes(platform)"
      >
        <v-card class="pt-7 pb-3 mb-3">
          <v-card-title class="text-h5 ml-3 font-weight-bold">
            Params {{ platform }}
            <v-btn
              color="primary"
              icon
              class="ml-6"
              @click.native="
                dialogModel.dialog = !dialogModel.dialog;
                dialogModel.platform = platform;
              "
              ><v-icon color="primary">{{ icons.mdiPencil }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-autocomplete
            class="ml-3 mr-3"
            v-model="accountsSelected[platform]"
            :items="accountsOptions[platform]"
            :label="'Comptes ' + platform"
            :item-text="'name'"
            item-value="identifier"
          ></v-autocomplete>
          <v-select
            class="ml-3 mr-3"
            v-model="countriesSelected[platform]"
            :items="countriesOptions"
            :label="'Pays ' + platform"
            multiple
          ></v-select>
          <v-select
            v-if="platform === 'facebook'"
            class="ml-3 mr-3"
            v-model="FbPageIdSelected"
            :items="FbPageId"
            :item-text="'name'"
            item-value="id"
            :label="'Page ID ' + platform"
          ></v-select>
          <v-text-field
            v-for="(metric, metricKey) in platformParameter[platform]"
            :key="metricKey"
            class="ml-3 mr-3"
            outlined
            :value="metric['value']"
            v-model="metric['value']"
            :label="metricKey + ' ' + platform"
          ></v-text-field>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogModel.dialog" max-width="70vw">
      <v-card :dialogOpen="dialogModel.dialog">
        <v-card-title class="text-h5 font-weight-bold">
          Modifier les paramètres {{ dialogModel.platform }}
        </v-card-title>
        <v-card-subtitle>
          Attention, les changements opérés ici seront inscrit dans la base de
          données
        </v-card-subtitle>
        <v-card-text>
          <v-row
            v-for="(metric, metricKey) in platformParameter[
              dialogModel.platform
            ]"
            :key="metricKey"
          >
            <v-col cols="12" md="5">
              <v-text-field
                outlined
                :value="metric['field']"
                v-model="metric['field']"
                :label="metricKey + ' Key'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                outlined
                :value="metric['value']"
                v-model="metric['value']"
                :label="metricKey + ' Value'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="text-center mt-2">
              <v-btn icon @click="removeMetric(metricKey)">
                <v-icon color="error">{{ icons.mdiDelete }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveEditedAccountParams">Enregistrer</v-btn>
          <v-btn @click="closeEditAccountDialog">Annuler</v-btn>
          <v-btn @click.native="addMetricDialog = !addMetricDialog"
            >Ajouter un champ</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addMetricDialog" max-width="80vw">
      <v-card>
        <v-card-title class="text-h5 font-weight-bold"
          >Ajouter une metric à {{ dialogModel.platform }}</v-card-title
        >
        <v-card-text>
          <v-text-field
            v-model="newMetric.metricKey"
            outlined
            label="Clé de la metric"
          ></v-text-field>
          <v-text-field
            v-model="newMetric.metrics.value"
            outlined
            label="Valeur de la metric"
          ></v-text-field>
          <v-text-field
            v-model="newMetric.metrics.field"
            outlined
            label="Champ de la metric"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveNewMetric">Enregistrer</v-btn>
          <v-btn @click="closeAddMetricDialog">Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- PARAMETRAGE PLATEFORMES -->

    <!-- RECAP ET GENERATION -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 font-weight-bold">
        Recapitulatif et Génération
      </v-card-title>
      <v-row v-if="this.seeRecapAccounts">
        <v-col cols="12">
          <ul>
            <li><strong>Items:</strong> {{ this.selectedCombinations }}</li>
            <li><strong>URL:</strong>{{ this.url }}</li>
            <li><strong>Device:</strong>{{ this.deviceSelected }}</li>
            <li><strong>accounts:</strong>{{ this.accountsSelected }}</li>
            <li><strong>location:</strong>{{ this.countriesSelected }}</li>
            <li><strong>nomenclature:</strong>{{ this.nomenclature }}</li>
            <li><strong>description:</strong>{{ this.descriptionSelected }}</li>
            <li><strong>Fb desc selected</strong>{{ this.FbDescSelected }}</li>
            <li>
              <strong>Fb page Id selected</strong>{{ this.FbPageIdSelected }}
            </li>
          </ul>
        </v-col>
        <v-col
          class="ml-3 text-sm"
          v-for="(platform, platformName) in platformParameter"
          v-if="platformSelected.includes(platformName)"
          :key="platformName"
        >
          <h2 class="mb-3">{{ platformName }}</h2>
          <ul>
            <li v-for="(field, fieldName) in platform" :key="fieldName">
              <strong>{{ fieldName }}:</strong> {{ field.field }}->{{
                field.value
              }}
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-card-actions class="mt-3">
        <v-btn class="mt-3" @click.native="seeRecapAccounts = !seeRecapAccounts"
          >Voir/Cacher le récap</v-btn
        >
        <v-btn class="mt-3" color="primary" @click="generateCampaigns"
          >Générer la/les campagnes</v-btn
        >
        <v-btn class="mt-3" @click="refreshPage">Tout réinitilaiser</v-btn>
        <v-btn
          class="mt-3"
          @click.native="seeCampaignCreated = !seeCampaignCreated"
        >
          Voir l'objet campagne
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="seeTitleAndDescErrorInfos" max-width="70vw" scrollable>
      <v-card>
        <v-card-text>
          <pre>{{ titleAndDescErrorInfos }}</pre>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="seeCampaignCreated" max-width="70vw" scrollable>
      <v-card>
        <v-card-text>
          <pre>{{ JSON.stringify(campaigns_generated, null, 2) }}</pre>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- RECAP ET GENERATION -->

    <!-- Loading Spinner -->
    <v-dialog v-model="loading" max-width="100%">
      <v-card>
        <v-card-text>
          <v-row justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
          <v-row justify="center">
            <span>Loading...</span>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Loading Spinner -->
  </div>
</template>

<script>
import Vue from "vue";
import axios from "@axios";
import { mdiPencil, mdiDelete } from "@mdi/js";
import { saveRmraView, saveRmraCustomEvent } from "@/utils/rmr-analytics";

export default {
  name: "campain-main",
  mounted() {
    saveRmraView(this.$route);
  },
  data() {
    return {
      titleAndDescErrorInfos: "",
      seeTitleAndDescErrorInfos: false,
      descriptions: [
        { text: "description", value: "", selected: false },
        { text: "suggestion 1", value: "", selected: false },
        { text: "suggestion 2", value: "", selected: false },
        { text: "suggestion 3", value: "", selected: false },
        { text: "suggestion 4", value: "", selected: false },
      ],
      descriptionSelected: "",
      showDescription: false,
      loading: false,
      showWording: false,
      allCombinations: [],
      title_saved: [],
      imagesSelected: [],
      addToTitleList: {
        title: false,
        suggestion1: false,
        suggestion2: false,
      },
      suggestionTitre: {
        suggestion1: { value: "" },
        suggestion2: { value: "" },
        suggestion3: { value: "" },
        suggestion4: { value: "" },
        suggestion5: { value: "" },
        suggestion6: { value: "" },
      },
      seeCampaignCreated: false,
      savedParametersPlatform: {},
      seeRecapAccounts: false,
      addMetricDialog: false,
      newMetric: {
        metricKey: "",
        metrics: {
          value: "",
          field: "",
        },
      },

      dialogModel: {
        dialog: false,
        platform: "None",
      },
      icons: { mdiPencil, mdiDelete },
      url: "",
      categorieSelected: "",
      categorieOptions: [
        "actualité",
        "astro",
        "histoires",
        "insolite",
        "lifestyle",
        "people",
        "sport",
        "télé",
        "test",
        "quiz",
        "équilibre",
        "astuces_pratiques",
      ],
      suggestedTitle: [],
      accountsSelected: { taboola: [], outbrain: [], facebook: [] },
      countriesSelected: { taboola: [], outbrain: [], facebook: [] },
      deviceSelected: [],
      countriesOptions: [
        "France",
        "Switzerland",
        "Belgium",
        "United Kingdom",
        "United States",
        "Canada",
      ],
      deviceOptions: ["desktop", "tablet", "mobile"],
      platformOptions: ["outbrain", "taboola", "facebook"],
      platformSelected: [],
      accountsOptions: {},
      platformParameter: {},
      site: "",
      nomenclature: "",
      title: "",
      campaigns_generated: "No campaign generated yet",
      images: [],
      newImageUrl: "",
      newImageUrlField: false,
      FbPageId: [
        { name: "Sports.fr", id: "24113786723" },
        { name: "Closer France", id: "39352611906" },
        { name: "Closer Magazine", id: "158077590869684" },
        { name: "Marie France", id: "197677770244905" },
        { name: "Be", id: "283494941784" },
        { name: "Entre Nous", id: "107585941700003" },
        { name: "Vie Pratique", id: "1731061713788854" },
      ],
      FbPageIdSelected: "",
      titleGptDialog: false,
      DescriptionGptDialog: false,
      prompt_config: {
        title: "",
        description: "",
      },
    };
  },

  async created() {
    this.savedParametersPlatform = await this.getParamerPlatform();
    this.accountsOptions = await this.getSubAccounts();
    this.getPromptConfig();
  },
  methods: {
    async getPromptConfig() {
      const url = "/arbitrage_campaign/prompts";
      const prompts = await axios.get(url);
      this.prompt_config = prompts.data;
      // console.log("prompt_config", this.prompt_config);
    },

    async savePromptConfig() {
      await axios.put("/arbitrage_campaign/prompts", {
        prompt_config: this.prompt_config,
      });
    },

    closePromptDialog(saving) {
      if (saving == true) {
        this.savePromptConfig();
        // console.log("saving prompt config");
      }
      this.titleGptDialog = false;
      this.DescriptionGptDialog = false;
    },

    async getDescriptions() {
      await this.executeWithLoading(async () => {
        const url = "/arbitrage_campaign/descriptions";
        const queryParams = {
          url: this.url,
        };
        const description = await axios.get(url, {
          params: queryParams,
        });

        if (description.data.error) {
          this.titleAndDescErrorInfos = description.data.error;
          this.seeTitleAndDescErrorInfos = true;
        } else {
          if (description.data.description.length > 150) {
            alert(
              "Attention : La description fait plus de 150 caractères et ne peut donc être utilisée pour outbrain"
            );
          }
          this.descriptions[0].value = description.data.description;
          this.descriptions[1].value =
            description.data.suggested_descriptions.variantes[0];
          this.descriptions[2].value =
            description.data.suggested_descriptions.variantes[1];
          this.descriptions[3].value =
            description.data.suggested_descriptions.variantes[2];
          this.descriptions[4].value =
            description.data.suggested_descriptions.variantes[3];
        }
      });
      this.showDescription = true;
    },
    async refreshPage() {
      this.platformParameter = this.savedParametersPlatform;
      await axios.put("/arbitrage_campaign/platformParameter/update", {
        platformParameter: this.platformParameter,
      });
      try {
        window.location.reload();
      } catch (error) {
        console.error("Erreur lors du rafraîchissement de la page :", error);
      }
    },
    async executeWithLoading(asyncFunction) {
      this.loading = true;
      try {
        await asyncFunction();
      } catch (error) {
        console.error("Une erreur s'est produite :", error);
      } finally {
        this.loading = false;
      }
    },
    async generateAllCombinations() {
      this.allCombinations = [];
      for (const image of this.imagesSelected) {
        for (const title of this.title_saved) {
          this.allCombinations.push({
            image: image,
            title: title,
            isSelected: true,
          });
        }
      }
    },
    async generateCampaigns() {
      saveRmraCustomEvent(this.$route, "use");
      await this.executeWithLoading(async () => {
        const selectedCombinations = this.allCombinations.filter(
          (pair) => pair.isSelected
        );
        const generalInfos = {
          items: selectedCombinations,
          url: this.url,
          device: this.deviceSelected,
          platform: this.platformSelected,
          accounts: this.accountsSelected,
          site: this.site,
          nomenclature: this.nomenclature,
          description: this.descriptionSelected,
          location: this.countriesSelected,
          platformParameter: this.platformParameter,
          FbSpecific: {
            pageId: this.FbPageIdSelected,
            description: this.FbDescSelected,
          },
        };

        const campaigns = await axios.put(
          "arbitrage_campaign/generateCampaigns/",
          {
            campaign_parameters: generalInfos,
          }
        );
        this.campaigns_generated = campaigns.data.items;
        this.seeCampaignCreated = true;
      });
    },
    async removeMetric(metricKey) {
      const userConfirmed = window.confirm(
        "Cela affecte les 3 plateformes (taboola, outbrain et facebook). La suppression est immédiate?"
      );
      if (userConfirmed) {
        // TODO remplacer par boucle avec this.platformSelected
        delete this.platformParameter["taboola"][metricKey];
        delete this.platformParameter["facebook"][metricKey];
        delete this.platformParameter["outbrain"][metricKey];
        Vue.nextTick(() => {
          this.$forceUpdate();
        });
        await axios
          .delete(`arbitrage_campaign/platformParameter/delete/${metricKey}`)
          .then((response) => {
            console.log("Suppression confirmée:", response.data);
            this.$forceUpdate();
            console.log("delete", this.platformParameter);
          })
          .catch((error) => {
            console.error(
              "Erreur lors de la suppression de la métrique:",
              error
            );
          });
      } else {
        console.log("Suppression annulée par l'utilisateur");
      }
    },
    addMetric() {
      this.addMetricDialog = true;
    },
    saveNewMetric() {
      this.platformParameter[this.dialogModel.platform][
        this.newMetric.metricKey
      ] = {
        ...this.newMetric.metrics,
      };
      this.addMetricDialog = false;
    },
    closeAddMetricDialog() {
      this.addMetricDialog = false;
    },
    async saveEditedAccountParams() {
      this.dialogModel.dialog = false;
      await axios.put("/arbitrage_campaign/platformParameter/update", {
        platformParameter: this.platformParameter,
      });
      this.getParamerPlatform();
      Vue.nextTick(() => {
        this.$forceUpdate();
      });
    },
    closeEditAccountDialog() {
      this.dialogModel.dialog = false;
      this.getParamerPlatform();
    },
    async getSubAccounts() {
      const accounts = await axios.get("/arbitrage_campaign/subAccounts");
      this.accountsOptions = accounts.data;
      return this.accountsOptions;
    },
    async getParamerPlatform() {
      const items = await axios.get("/arbitrage_campaign/platformParameter");
      this.platformParameter = items.data.platformParameter;
      return this.platformParameter;
    },
    addImageFromUrl() {
      this.images.push(this.newImageUrl);
      this.newImageUrl = "";
    },
    async displayImages() {
      const url = "/arbitrage_campaign/images";
      const queryParams = {
        url: this.url,
      };
      const images = await axios.get(url, {
        params: queryParams,
      });

      if (images.data.error) {
        this.titleAndDescErrorInfos = images.data.error;
        this.seeTitleAndDescErrorInfos = true;
      } else {
        this.images = images.data.images;
        this.newImageUrlField = true;
      }
    },
    extractImgName(imageUrl) {
      const imgName = imageUrl.split("/").pop();
      return imgName;
    },
    async downloadImage(imageUrl) {
      this.extractImgName(imageUrl);
      const blob = await (await fetch(imageUrl)).blob();
      let formData = new FormData();
      formData.append("file", blob, this.extractImgName(imageUrl));
      const response = await axios.post(
        "/arbitrage_campaign/uploadImage",
        formData
      );
    },

    async getDescriptionsVariationIfError() {
      const url = "/arbitrage_campaign/descriptions/onlyDescriptions";
      const queryParams = {
        description: this.descriptions[0].value,
      };
      const descriptionVariation = await axios.get(url, {
        params: queryParams,
      });
      console.log("descriptionVariation", descriptionVariation.data);
      const suggestedDescriptions = descriptionVariation.data["variantes"];
      for (let i = 1; i <= 4; i++) {
        this.descriptions[i].value = suggestedDescriptions[i - 1];
      }
    },

    async getTitleVariationIfError() {
      const url = "/arbitrage_campaign/titles/onlyVariations";
      const queryParams = {
        title: this.title,
      };
      const titleVariation = await axios.get(url, {
        params: queryParams,
      });
      console.log("titleVariation", titleVariation.data);
      const suggestedTitles = titleVariation.data["accroches"];
      for (let i = 1; i <= 6; i++) {
        const suggestionKey = `suggestion${i}`;
        this.suggestionTitre[suggestionKey].value = suggestedTitles[i - 1];
      }
    },

    async getTitleAndVariations() {
      await this.executeWithLoading(async () => {
        const url = "/arbitrage_campaign/titles";
        const queryParams = {
          url: this.url,
        };
        const title = await axios.get(url, {
          params: queryParams,
        });
        if (title.data.error) {
          this.titleAndDescErrorInfos = title.data.error;
          this.seeTitleAndDescErrorInfos = true;
        } else {
          this.title = title.data.title;
          const suggestedTitles = title.data.suggested_titles["accroches"];
          for (let i = 1; i <= 6; i++) {
            const suggestionKey = `suggestion${i}`;
            this.suggestionTitre[suggestionKey].value = suggestedTitles[i - 1];
          }
        }
        this.showWording = true;
      });
    },
    async getNomenclature() {
      try {
        const urlObj = new URL(this.url);
        this.site = urlObj.hostname;
      } catch (error) {
        this.site = "URL invalide";
      }

      const nomenclature_elems = {
        title: this.title,
        category: this.categorieSelected,
        device: this.deviceSelected,
        site: this.site,
      };
      const nomenclature = await axios.put(
        "arbitrage_campaign/nomenclature",
        nomenclature_elems
      );
      this.nomenclature = nomenclature.data;
    },
  },
  computed: {
    selectedCombinations() {
      return this.allCombinations.filter(
        (combination) => combination.isSelected
      );
    },
    FbDescSelected() {
      return this.descriptions.filter((desc) => desc.selected);
    },
  },
  watch: {
    addToTitleList: {
      handler: function (newValues) {
        this.title_saved = [];
        if (newValues.title && this.title.trim() !== "") {
          this.title_saved.push(this.title);
        }
        for (const key in this.suggestionTitre) {
          if (newValues[key] && this.suggestionTitre[key].value.trim() !== "") {
            this.title_saved.push(this.suggestionTitre[key].value);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.small-text-field {
  width: 20px;
}
</style>

<template>
  <div>
    <v-card class="pa-4">
      <p class="ma-6 mb-9">
        L'url de la page sera automatiquement joint à ce message.<br />
        Vous pouvez ajouter ci-dessous plus d'informations sur le problème
        rencontré (facultatif)
      </p>
      <v-row class="mx-1 my-3">
        <v-card-text>
          <v-form ref="formBugReporting">
            <v-row v-if="!sent">
              <v-col cols="12" sm="12">
                <v-textarea
                  v-model="messageInput"
                  filled
                  label="Message"
                  auto-grow
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn
                  @click.native="send"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  class="me-3 mt-3"
                  >Envoyer</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-else class="text-center">
              <v-col
                ><div class="text-h6 success--text text-center">
                  Message envoyé
                </div></v-col
              >
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import { required, urlValidator } from "@core/utils/validation";

export default {
  name: "CreateBugReporting",
  props: {
    email: {
      type: String,
      required: false,
      default: "",
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      required,
      urlValidator,
      messageInput: "",
      sent: false,
      loading: false,
    };
  },
  created() {},
  onBeforeMount() {
    this.messageInput = "";
    this.sent = false;
  },
  methods: {
    async send() {
      // const isFormValid = this.$refs.formBugReporting.validate();
      // if (!isFormValid) return;
      this.loading = true;
      const params = {
        email: localStorage.getItem("userEmail"),
        url: document.location.href,
        message: this.messageInput,
      };
      const response = await axios.post("/notifications/bug-reporting", params);
      if (response.status === 201) {
        this.sent = true;
      } else {
        console.error("An error occurred.");
      }
      this.loading = false;
    },
  },
  watch: {
    // dialogOpen(visible) {
    //   if (visible) {
    //     this.name = "";
    //     this.domain = "";
    //     this.$refs.siteForm.resetValidation();
    //   }
    // },
  },
  computed: {
    // message() {
    //   ...
    // },
  },
};
</script>
<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-6"},[_c('v-card-title',{staticClass:"align-start"},[_c('span',{staticClass:"font-weight-semibold text-base"},[_vm._v("Statistiques")])]),(_vm.loading)?_c('loader'):_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","lg":"2","md":"4"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCurrencyEur)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v("CA est.")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.estimatedRevenues.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.estimatedRevenues.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"6","lg":"2","md":"4"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSpeedometer)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v("Total Links")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.totalLinks.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.totalLinks.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"6","lg":"2","md":"4"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMonitorEye)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v("Screen page vue")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.screenPageViews.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.screenPageViews.raw))])])],1)],1)]),_c('v-col',{attrs:{"cols":"6","lg":"2","md":"4"}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"size":"44","color":"primary","rounded":""}},[_c('v-icon',{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFormatRotate90)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v("Sessions")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-xl font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.totalSessions.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.totalSessions.raw))])])],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
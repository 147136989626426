var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Sites "),_c('dag-info',{attrs:{"dags":[
        'ga_get_and_store_site_daily_dag',
        'partner_get_and_store_daily_dag',
        'partner_get_and_store_daily_ifempty_dag' ]}})],1),(_vm.loading)?_c('loader'):_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":5},scopedSlots:_vm._u([{key:"item.revenue",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.revenue)))])]}},{key:"item.sessions",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.sessions)))])]}},{key:"item.rpm",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.rpm)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card outlined>
    <v-card-title>Répartition par âges</v-card-title>
    <v-card-text>
      <loader v-if="loading" />
      <v-container v-else-if="categories.length === 0" class="text-center">
        {{ noDataText }}
      </v-container>
      <generic-graph
        v-else
        :series="series"
        :color="chartOptions.colors"
        :chartOptions="chartOptions"
        :chartCategories="categories"
        xaxisType="category"
        :legend="false"
      ></generic-graph>
    </v-card-text>
  </v-card>
</template>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";
import { colors, noDataText } from "@/utils/constants";
import { formatPercent } from "@/utils/formatting";

import GenericGraph from "@/components/common/charts/GenericGraph";
import Loader from "@/components/common/Loader";

import { ingredientListFromAutoCompleteValues } from "@/components/regie/common/utils/ingredientGroup.js";

const emptySeries = [
  {
    name: "percent",
    data: [],
  },
];

const yAxis = (color = "") => ({
  min: 0,
  forceNiceScale: true,
  labels: {
    style: {
      fontSize: "14px",
      fontWeight: 400,
      ...(color ? { colors: color } : {}),
    },
  },
});

export default {
  name: "RepartitionByAgesChart",
  components: {
    GenericGraph,
    Loader,
  },
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  data() {
    return {
      loading: false,
      noDataText,
      series: [...emptySeries],
      categories: [],
      chartOptions: {
        chart: {
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        legend: {
          fontSize: "14px",
        },
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        colors: [colors.chartDefault],
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        yaxis: [
          {
            seriesName: "percent",
            title: {
              text: "Pourcentage",
            },
            ...yAxis(),
          },
        ],
        xaxis: {
          categories: [],
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
          style: {
            fontSize: "14px",
            fontWeight: 400,
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    resetData() {
      this.series = [...emptySeries];
      this.categories = [];
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.sites &&
        this.sites.length > 0 &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        const params = {
          start_date: this.startDate,
          end_date: this.endDate,
          sites_id: this.sites.join(","),
          keyword: this.searchText,
          ...(this.useMarmitonFilters
            ? {
                ...(this.ingredientsToInclude
                  ? { ingredients: this.ingredientsToInclude }
                  : {}),
                ...(this.flavour ? { flavour: this.flavour } : {}),
                ...(this.region ? { region: this.region } : {}),
                ...(this.ageSlot ? { ageSlot: this.ageSlot } : {}),
                ...(this.dish ? { dish: this.dish } : {}),
                ...(this.cost ? { cost: this.cost } : {}),
                ...(this.duration ? { duration: this.duration } : {}),
                ...(this.rating ? { rating: this.rating } : {}),
                ...(this.rates ? { rates: this.rates } : {}),
              }
            : {}),
        };

        this.loading = true;
        try {
          const { data } = await this.axiosGet(
            "/regie/visitor-info/by-age-slots",
            params
          );

          this.categories = Object.keys(data);

          this.series = [
            {
              name: "percent",
              data: Object.values(data).map((val) => formatPercent(val, 0)),
            },
          ];

          this.loading = false;
        } catch (err) {
          this.loading = false;
        }

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters[`${this.department}/getSites`];
    },
    startDate() {
      return this.$store.getters[`${this.department}/getDates`][0];
    },
    endDate() {
      return this.$store.getters[`${this.department}/getDates`][1];
    },
    searchText() {
      return this.$store.getters[`${this.department}/getContainsText`];
    },
    flavour() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getFlavour"]
        : "";
    },
    region() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRegion"]
        : "";
    },
    ageSlot() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getAgeSlot"]
        : "";
    },
    ingredientsToInclude() {
      const ingredients = this.useMarmitonFilters
        ? this.$store.getters["marmiton/getMarmitonIngredientsToInclude"]
        : [];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
    useMarmitonFilters() {
      return ["marmiton"].includes(this.department);
    },
    dish() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getDish"]
        : "";
    },
    cost() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getCost"]
        : "";
    },
    duration() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getDuration"]
        : 0;
    },
    rating() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRating"]
        : 0;
    },
    rates() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRates"]
        : 0;
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    department() {
      this.resetData();
    },
  },
};
</script>

<template>
  <div ref="pdfContainer" class="pdfContainer">
    <component v-if="logo" :is="logo"></component>
    <p class="text-2xl mb-6">
      Performance par article
      <dag-info
        :dags="[
          'adn_daily_posts_sites_infos_dag',
          'ga_get_and_store_site_daily_dag',
          'partner_get_and_store_daily_dag',
          'partner_get_and_store_daily_ifempty_dag',
          'adn_get_and_store_posts_ga_daily_dag',
        ]"
      />
    </p>
    <v-card class="mb-6 pa-2" outlined>
      <v-row class="ma-3">
        <v-col cols="12">
          <filters></filters>
        </v-col>
      </v-row>
    </v-card>

    <v-row v-if="['audience'].includes(department)">
      <v-col cols="12">
        <top-stats-card />
      </v-col>
    </v-row>

    <v-row v-if="['audience'].includes(department)">
      <v-col cols="12">
        <top-articles-chart />
      </v-col>
    </v-row>

    <v-row v-if="['audience'].includes(department)">
      <v-col cols="6">
        <ca-chart />
      </v-col>
      <v-col cols="6">
        <ca-distribution />
      </v-col>
    </v-row>

    <v-row v-if="['audience'].includes(department)">
      <v-col cols="6">
        <stats-by-date-chart />
      </v-col>
      <v-col cols="6">
        <stats-by-sessions-chart />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <perf-per-article-table :department="department" />
      </v-col>
    </v-row>

    <dot-menu
      position="page-top-right"
      :isLoading="isLoading"
      :pdfButton="{
        getContainerRef: () => $refs.pdfContainer,
        filename: 'perf_par_article.pdf',
      }"
    />
  </div>
</template>

<script>
import DagInfo from "@/components/common/menus/DagInfo";
import DotMenu from "@/components/common/menus/DotMenu";

import CaDistribution from "@/components/audience/perf-per-article/cards/CADistributionChart";
import CaChart from "@/components/audience/perf-per-article/cards/CAChart";
import Filters from "@/components/audience/perf-per-article/filters/Filters";
import PerfPerArticleTable from "@/components/audience/perf-per-article/tables/PerfPerArticle";
import StatsByDateChart from "@/components/audience/perf-per-article/cards/StatsByDateChart";
import StatsBySessionsChart from "@/components/audience/perf-per-article/cards/StatsBySessionsChart";
import TopArticlesChart from "@/components/audience/perf-per-article/cards/TopArticlesChart";
import TopStatsCard from "@/components/audience/perf-per-article/cards/TopStatsCard";

import useAxios from "@/hooks/useAxios";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "PerfPerArticle",
  components: {
    CaDistribution,
    CaChart,
    DagInfo,
    DotMenu,
    Filters,
    PerfPerArticleTable,
    StatsByDateChart,
    StatsBySessionsChart,
    TopArticlesChart,
    TopStatsCard,
  },
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["audience", "regie"].includes(value);
      },
    },
    logo: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {};
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  mounted() {
    saveRmraView(this.$route);
  },
};
</script>

<style scoped>
.pdfContainer {
  position: relative;
}
</style>

<template>
  <div>
    <v-card class="my-6 pa-2">
      <v-row class="ma-3">
        <v-col cols="4">
          <!-- filtrer le partenaire -->
          <v-autocomplete
            outlined
            v-model="partnerSelected"
            clearable
            :items="partnerList"
            label="Sélectionner un partenaire"
            item-text="name"
            item-value="id"
            class="select-partner shrink"
            @change="selectPartner"
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            outlined
            v-model="selectedSiteId"
            :items="sitesList"
            :menu-props="{ maxHeight: '400', zIndex: 13 }"
            label="Sélectionner les sites"
            item-text="name"
            item-value="id"
            @change="debouncedGetPartnerInfos"
          />
        </v-col>
        <v-col cols="3">
          <date-picker-single-day
            identifier="dayFilter"
            dateInit="null"
            :key="datePickerSingleDayKey"
            @onSelect="setSelectedDay"
            customClass="ml-4"
            outlined="outlined"
            hint=""
          />
        </v-col>
        <v-col cols="2">
          <v-btn
            @click="resetFilters"
            class="pt-7 pb-6 mt-0 my-1"
            outlined
            color="secondary"
          >
            Réinitialiser
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-3">
        <v-col cols="4">
          <!-- filtrer le partenaire -->
          <v-autocomplete
            outlined
            v-model="dimensionTypeSelected"
            clearable
            :items="dimensionTypeList"
            label="Sélectionner un type de dimension"
            item-text="name"
            item-value="id"
            class="select-partner shrink"
            @change="selectDimensionType"
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            outlined
            v-model="dimensionSelected"
            :items="dimensionList"
            :menu-props="{ maxHeight: '400', zIndex: 13 }"
            label="Sélectionner une dimension"
            item-text="name"
            item-value="id"
            @change="debouncedGetPartnerInfos"
          />
        </v-col>
        <v-col cols="2">
          <v-btn
            @click="debouncedGetPartnerInfos"
            class="pt-7 pb-6 mt-0 my-1"
            color="primary"
          >
            Valider
          </v-btn>
        </v-col>
        <v-col cols="3">
          <kpi-dark-blue title="CA total" :items="kpiDarkBlueItems" />
        </v-col>
      </v-row>
      <v-row class="mx-3">
        <v-col>
          <v-data-table
            ref="table"
            :page="options.page"
            :pageCount="numberOfPages"
            :headers="headers"
            :items="items"
            :sort-by="null"
            :sort-desc="true"
            :options.sync="options"
            :server-items-length="options.total"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [10, 25, 50],
            }"
            :items-per-page="options.itemsPerPage"
            loading-text="Chargement"
            no-data-text="Pas de données"
            no-results-text="Pas de résultats"
            class="elevation-1"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import debounce from "lodash/debounce";

import KpiDarkBlue from "@/components/common/boxes/KpiDarkBlue";
import DatePickerSingleDay from "@/components/common/filters/DatePickerSingleDay";

export default {
  name: "PartnerData",
  components: {
    DatePickerSingleDay,
    KpiDarkBlue,
  },
  async created() {
    this.partnerList = await this.$store.dispatch("premium/getPartnersList");
    this.sitesList = await this.$store.dispatch("common/getSiteList");
  },
  data() {
    return {
      kpiDarkBlueItems: {
        // ca: { label: "CA", type: "CurrencyEur", value: 10123.666 },
      },
      dimensionTypeSelected: null,
      dimensionTypeList: [],
      dimensionSelected: null,
      dimensionList: [],
      numberOfPages: 0,
      items: [],
      sitesList: [],
      selectedSiteId: null,
      selectedDateStr: null,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      partnerSelected: null,
      partnerList: [],
      searchStr: "",
      loading: false,
      headers: [],
      datePickerSingleDayKey: 0,
    };
  },
  methods: {
    selectPartner() {
      this.getDimentionTypeList();
      this.debouncedGetPartnerInfos();
    },
    async getDimentionTypeList() {
      if (!this.partnerSelected) {
        this.dimensionTypeSelected = null;
        this.dimensionTypeList = [];
        return;
      }
      console.log(
        "getDimentionTypeList this.partnerSelected",
        this.partnerSelected
      );
      const {
        data: { items },
      } = await axios.get("/partner-dimension-types/", {
        params: { partner_id: this.partnerSelected },
      });
      this.dimensionTypeList = items;
      console.log("getDimentionTypeList", items);
    },
    selectDimensionType() {
      this.getDimentionList();
      this.debouncedGetPartnerInfos();
    },
    async getDimentionList() {
      if (!this.dimensionTypeSelected) {
        this.dimensionSelected = null;
        this.dimensionList = [];
        return;
      }
      console.log(
        "getDimentionList this.dimensionTypeSelected",
        this.dimensionTypeSelected
      );
      const {
        data: { items },
      } = await axios.get("/partner-dimensions/", {
        params: { dimension_type_id: this.dimensionTypeSelected, limit: 9999 },
      });
      this.dimensionList = items;
      console.log("getDimentionList", items);
    },

    debouncedGetPartnerInfos: debounce(async function () {
      await this.getPartnerInfos();
    }, 500),
    resetFilters() {
      this.selectedSiteId = null;
      this.datePickerSingleDayKey += 1;
      this.selectedDateStr = null;
      this.debouncedGetPartnerInfos();
    },
    setSelectedDay(value) {
      this.selectedDateStr = value;
      this.debouncedGetPartnerInfos();
    },
    async getPartnerInfos() {
      this.loading = true;
      if (!this.partnerSelected) {
        this.loading = false;
        return;
      }
      const partner = this.partnerList.find(
        (partner) => partner.id === this.partnerSelected
      );
      console.log(partner);

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const queryParams = {
        site_id: this.selectedSiteId,
        date_str: this.selectedDateStr,
        page: page - 1,
        limit: itemsPerPage,
        ...(sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0
          ? {
              sort_by: sortBy[0],
              sort_desc: sortDesc[0],
            }
          : {}),
      };
      if (this.dimensionTypeSelected && this.dimensionSelected) {
        const dimensionType = this.dimensionTypeList.find(
          (x) => x.id === this.dimensionTypeSelected
        );
        console.log("getPartnerInfos dimensionType", dimensionType);
        const dimensionTypeCode = dimensionType["name"].toLowerCase();
        queryParams["dimension_type"] = dimensionTypeCode;
        queryParams["dimension_id"] = this.dimensionSelected;
      }
      console.log("getPartnerInfos queryParams", queryParams);

      const { data } = await axios.get(`/partner-infos/${partner["code"]}`, {
        params: queryParams,
      });
      console.log("data", data);
      this.loading = false;
      this.kpiDarkBlueItems = {
        ca: { label: "CA", type: "CurrencyEur", value: data.ca_total },
      };
      this.items = data.items;
      this.headers = data.headers;
      this.options.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;
    },
  },

  watch: {
    // options: {handler(){this.updateData();}, deep:true,},
    // individual properties to avoid refreshing when options.total updates
    "options.itemsPerPage"() {
      this.debouncedGetPartnerInfos();
    },
    "options.page"() {
      this.debouncedGetPartnerInfos();
    },
    "options.sortBy"() {
      this.debouncedGetPartnerInfos();
    },
    "options.sortDesc"() {
      this.debouncedGetPartnerInfos();
    },
  },
};
</script>
<style>
.app-content-container.mx-auto.h-full.app-content-container-boxed {
  max-width: 100%;
}
</style>

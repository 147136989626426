<template>
  <div>
    <v-container class="text-center">
      <v-progress-circular color="primary" indeterminate />
    </v-container>
    <v-container class="text-center" v-if="showText">
      {{ text }}
    </v-container>
  </div>
</template>

<script>
import {
  DATA_LOADING_ALTERNATIVE_TEXTS,
  dataLoadingText,
} from "@/utils/constants";

const emptyValue = {
  value: 0,
  raw: 0,
};

export default {
  name: "Loader",
  props: {
    showText: {
      type: Boolean,
      default: true,
    },
    useAlternativeTexts: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      text: null,
      isAlternativeText: false,
      alternativeText: null,
      timer: null,
    };
  },
  mounted() {
    if (this.timer) {
      // in case component is call with v-if
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.resetText(); // and start timer if needed, through watcher
  },
  methods: {
    resetText() {
      this.text = dataLoadingText;
      this.isAlternativeText = false;
    },
    updateText() {
      if (this.isAlternativeText) {
        this.resetText();

        return;
      }

      const index = this.alternativeText
        ? DATA_LOADING_ALTERNATIVE_TEXTS.indexOf(this.alternativeText)
        : -1;
      if (index >= 0 && index < DATA_LOADING_ALTERNATIVE_TEXTS.length - 1) {
        this.text = this.alternativeText =
          DATA_LOADING_ALTERNATIVE_TEXTS[index + 1];
        this.isAlternativeText = true;

        return;
      }

      this.text = this.alternativeText = DATA_LOADING_ALTERNATIVE_TEXTS[0];
      this.isAlternativeText = true;
    },
  },
  watch: {
    text() {
      if (!this.useAlternativeTexts || !this.showText) {
        return;
      }

      this.timer = setTimeout(() => {
        this.updateText();
      }, 3500);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <h1 class="text-2xl mb-6">Google Trends Exploration</h1>
    <trendsTable></trendsTable>
  </div>
</template>
<script>
import trendsTable from "@/components/gtrends/tables/trendsTable.vue";
export default {
  name: "gTrendsIndex",
  components: { trendsTable },
};
</script>
<style></style>

<template>
  <div>
    <p class="text-2xl mb-6">Les données CWV consolidées quotidiennement</p>
    <Filters
      :dev-filter-visible="devFilterVisible"
      :range-date="true"
    ></Filters>
    <cwv-daily-debug-table :dev-filter-visible="devFilterVisible" />
  </div>
</template>

<script>
import { mdiPlusBox } from "@mdi/js";
import Filters from "@/components/web-perf/cwv/filters/Filters.vue";
import { Konami, KonamiManager } from "@/utils/konami";
import CwvDailyDebugTable from "@/components/web-perf/cwv/cwv-daily-debug/tables/CwvDailyDebugTable.vue";

export default {
  name: "CwvDailyData",
  components: {
    CwvDailyDebugTable,
    Filters,
  },
  created() {
    this.konamiManager = new KonamiManager(this, [
      new Konami([68, 69, 86], () => {
        this.devFilterVisible = !this.devFilterVisible;
      }),
    ]);
  },
  data() {
    return {
      devFilterVisible: false,
      icons: {
        mdiPlusBox,
      },
    };
  },
  methods: {},
  watch: {},
};
</script>
<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-row mb-6"},[_c('p',{staticClass:"text-2xl mb-6"},[_vm._v(" Daily CA "),_c('dag-info',{attrs:{"dags":[
          'ga_get_and_store_site_daily_dag',
          'partner_get_and_store_daily_dag',
          'partner_get_and_store_daily_ifempty_dag',
          'wyzistat_get_and_store_data_daily_dag' ]}})],1)]),_c('v-card',{staticClass:"mb-6 pa-2"},[_c('v-card-text',[_c('div',{staticClass:"d-flex flex-row mb-6"},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.setTotal}},[_vm._v("Total")]),_c('v-text-field',{staticClass:"ml-2",attrs:{"label":"Rechercher un site","dense":""},on:{"input":_vm.search},model:{value:(_vm.searchStr),callback:function ($$v) {_vm.searchStr=$$v},expression:"searchStr"}}),_c('v-text-field',{staticClass:"ml-2",attrs:{"label":"Rechercher une colonne","dense":""},on:{"input":_vm.search},model:{value:(_vm.searchColStr),callback:function ($$v) {_vm.searchColStr=$$v},expression:"searchColStr"}}),_c('v-spacer'),_c('date-picker-single-day',{attrs:{"identifier":"dayFilter","dateInit":"yesterday","dense":"dense","customClass":"ml-4","hint":""},on:{"onSelect":_vm.setSelectedDay}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDevMode),expression:"showDevMode"}],staticClass:"mx-1",attrs:{"outlined":"","dense":""},on:{"click":function($event){return _vm.getData()}}},[_vm._v(" Reload ")]),_c('dot-menu',{attrs:{"excelButton":{
            excelData: _vm.excelData,
            filename: ("Prog-GSS-export-" + (this.dateSelected) + ".xlsx"),
            disabled: false,
          }},on:{"exportExcel":_vm.exportExcel}})],1),(_vm.filteredData.length !== 0)?_c('div',[_c('v-simple-table',{attrs:{"dense":"","fixed-header":"","height":"500"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.filteredData[0]),function(value,key,index){return _c('th',{key:index,staticClass:"text-left",class:("field_" + (key.replace(/\s+/g, '_').toLowerCase()))},[_vm._v(" "+_vm._s(key)+" ")])}),0)]),_c('tbody',_vm._l((_vm.filteredData),function(item,i){return _c('tr',{key:i},_vm._l((item),function(value,key,index){return _c('td',{key:index,staticClass:"cell",class:("value_" + (key.replace(/\s+/g, '_').toLowerCase()))},[_c('span',{domProps:{"innerHTML":_vm._s(value)}})])}),0)}),0)]},proxy:true}],null,false,33431991)})],1):_c('div',[_vm._v(" "+_vm._s(_vm.noDataMessage)+" ")])])],1),_c('v-card',{staticClass:"export-month"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-right description",attrs:{"cols":"6"}},[_vm._v(" Exporter les jours sur un mois entier ")]),_c('v-col',{staticClass:"text-right month-picker",attrs:{"cols":"2"}},[_c('date-picker-single-month',{attrs:{"identifier":"monthFilter","dateInit":"current","dense":"dense","customClass":"ml-4","hint":""},on:{"onSelect":_vm.setSelectedExportMonth}})],1),_c('v-col',{staticClass:"text-right group-wrappers",attrs:{"cols":"2"}},[_c('v-switch',{attrs:{"label":"Grouper GAM Wrappers","dense":"dense"},model:{value:(_vm.groupWrappers),callback:function ($$v) {_vm.groupWrappers=$$v},expression:"groupWrappers"}})],1),_c('v-col',{staticClass:"submit",attrs:{"cols":"2"}},[_c('export-excel-v-button',{attrs:{"excelData":_vm.excelMonth.data,"filename":_vm.excelMonth.filename,"loading":_vm.monthBtnLoader},on:{"exportExcel":_vm.exportMonthExcel}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }